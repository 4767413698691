import TripItemIcon from "../../../../components/TripItemIcon";
import Card from "../../../../components/Card";
import DateTimeFormat from "../../../../components/DateTimeFormat";
import { useTranslation } from "react-i18next";

const TripSummary = ({ ride, transportation }: any) => {
  const { t } = useTranslation();
  return (
    <Card>
      <div className="flex justify-between items-center">
        <div className="text-text text-left">
          <p className="text-base text-text">{t("pickup-at")}</p>
          <DateTimeFormat
            styling={"text-xl font-extrabold"}
            timestamp={ride.start_time}
            variant={"datetime"}
          />
        </div>
        <div>
          {transportation !== null && <TripItemIcon type={transportation} />}
        </div>
      </div>
    </Card>
  );
};

export default TripSummary;
