import { ThemeProvider } from "@mui/material/styles";
import { Checkbox, FormControlLabel } from "@mui/material";
import theme from "../../theme";

interface ICheckboxProps {
  label: string;
  onChange: any;
}

const Index = ({ label, onChange }: ICheckboxProps) => {
  return (
    <ThemeProvider theme={theme}>
      <FormControlLabel
        control={<Checkbox onChange={onChange} />}
        label={label}
        className="text-left text-xs"
      />
    </ThemeProvider>
  );
};

export default Index;
