import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api";
import { getErrorMessage } from "../../utilities/helpers";

interface cancelRideInterface {
  trip_id: string;
  reason: string;
}

interface cancelTripInterface {
  trip_id: string;
  reason_id: string;
}

export const cancelRide = createAsyncThunk(
  "trip/cancelTrip",
  async ({ trip_id, reason }: cancelRideInterface, { rejectWithValue }) => {
    try {
      return await apiService.cancelRide(trip_id, reason);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

//cancel both on roundtrip
export const cancelTrip = createAsyncThunk(
  "trip/cancelTrip",
  async ({ trip_id, reason_id }: cancelTripInterface, { rejectWithValue }) => {
    try {
      const response = await apiService.cancelTrip(trip_id, reason_id);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchTrip = createAsyncThunk(
  "trip/fetchTrip",
  async (trip_id: string, { rejectWithValue }) => {
    try {
      return await apiService.getTrip(trip_id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

interface SliceState {
  data: any;
  rideDispatch: any;
  loading: "idle" | "loading" | "finished";
  error: String | undefined | null;
}

const initialState: SliceState = {
  data: null,
  rideDispatch: null,
  loading: "idle",
  error: null,
};

const tripSlice = createSlice({
  name: "trip/trip",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(cancelRide.pending, () => {
        //state.loading = "loading";
      })
      .addCase(cancelRide.rejected, (state, action) => {
        //state.loading = "finished";
        state.error = getErrorMessage(action.error);
      })
      .addCase(cancelRide.fulfilled, () => {
        //state.loading = "finished";
        //state.data = action.payload ? action.payload : null;
      })
      .addCase(fetchTrip.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchTrip.rejected, (state, action) => {
        state.loading = "finished";
        state.error = getErrorMessage(action.error);
      })
      .addCase(fetchTrip.fulfilled, (state, action) => {
        state.loading = "finished";
        state.data = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const selectTrip = ({ trip }: any) => trip.trip;
export const selectTripRides = ({ trip }: any) => trip.trip.rides;

// export const tripActions = tripSlice.actions;
export default tripSlice.reducer;
