import enums from "../../constants/enums";
import { ReactComponent as LyftIcon } from "../../assets/svg/lyft.svg";
import { ReactComponent as UberIcon } from "../../assets/svg/uber.svg";
import { ReactComponent as RideShareIcon } from "../../assets/svg/rideshare.svg";
import { ReactComponent as WheelchairVanIcon } from "../../assets/svg/wheelchairvan.svg";
import { ReactComponent as MedicalSedanIcon } from "../../assets/svg/medicalsedan.svg";
import { ReactComponent as TaxiIcon } from "../../assets/svg/taxi.svg";

interface ITripItemIconProps {
  type:
    | keyof typeof enums.TransportationType
    | keyof typeof enums.RideShare
    | string;
}

const TripItemIcon = ({ type }: ITripItemIconProps) => {
  const setIcon = () => {
    if (type === enums.RideShare.lyft) {
      return <LyftIcon className="w-16" />;
    }
    if (type === enums.RideShare.uber) {
      return <UberIcon className="w-16" />;
    }
    if (type === enums.TransportationType.ride_share) {
      return <RideShareIcon className="w-16" />;
    }
    if (type === enums.TransportationType.taxi) {
      return <TaxiIcon className="w-16" />;
    }
    if (type === enums.TransportationType.wheelchair_van) {
      return <WheelchairVanIcon className="w-16" />;
    }
    if (type === enums.TransportationType.medical_sedan) {
      return <MedicalSedanIcon className="w-16" />;
    }
  };
  return <div>{setIcon()}</div>;
};

export default TripItemIcon;
