import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api";
import { getErrorMessage } from "../../utilities/helpers";

interface ITransportationTypeRequest {
  hospital_id: string;
  zipCode?: string;
}

interface SliceState {
  transportation: {
    data: Array<string>;
    loading: "idle" | "loading" | "finished";
    error: String | null;
  };
  vehicle: {
    data: Array<string>;
    loading: "idle" | "loading" | "finished";
    error: String | null;
  };
}

const initialState: SliceState = {
  transportation: {
    data: [],
    loading: "idle",
    error: null,
  },
  vehicle: {
    data: [],
    loading: "idle",
    error: null,
  },
};

export const fetchTransportationTypes = createAsyncThunk(
  "booking/transportation/fetchTransportationTypes",
  async (
    { hospital_id, zipCode }: ITransportationTypeRequest,
    { rejectWithValue },
  ) => {
    try {
      return await apiService.getHospitalTransportationTypes(
        hospital_id,
        zipCode,
      );
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchVehicleTypes = createAsyncThunk(
  "booking/transportation/fetchVehicleTypes",
  async (hospital_id: string, { rejectWithValue }) => {
    try {
      return await apiService.getHospitalVehicleTypes(hospital_id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const transportationSlice = createSlice({
  name: "booking/transportation",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTransportationTypes.pending, (state) => {
        state.transportation.loading = "loading";
      })
      .addCase(fetchTransportationTypes.rejected, (state, action) => {
        state.transportation.loading = "finished";
        state.transportation.error = getErrorMessage(action.error);
      })
      .addCase(fetchTransportationTypes.fulfilled, (state, action) => {
        state.transportation.loading = "finished";
        state.transportation.data = action.payload;
      })

      .addCase(fetchVehicleTypes.pending, (state) => {
        state.vehicle.loading = "loading";
      })
      .addCase(fetchVehicleTypes.rejected, (state, action) => {
        state.vehicle.loading = "finished";
        state.vehicle.error = getErrorMessage(action.error);
      })
      .addCase(fetchVehicleTypes.fulfilled, (state, action) => {
        state.vehicle.loading = "finished";
        state.vehicle.data = action.payload;
      });
  },
});

export const selectTransportationTypes = ({ booking }: any) =>
  booking.transportation.transportation;
export const selectVehicleTypes = ({ booking }: any) =>
  booking.transportation.vehicle;
export default transportationSlice.reducer;
