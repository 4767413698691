import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import { AppDispatch } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { User, useGetUserQuery } from "../../store/account/userSlice";
import apiService from "../../services/api";

import i18n from "i18next";

import { selectToken, setCredentials } from "../../store/authSlice";
import { Platform } from "react-native";
import { useAuth0 } from "../../os/auth0";

import { useLogger } from "../Logger";

type AuthContextData = {
  loading: boolean;
  signIn(): Promise<void>;
  signUp(): Promise<void>;
  signOut(): void;
  /**
   * Error object set if Auth0 or Roundtrip's API returns an error.
   */
  authError?: Error;
  /**
   * `true` after the user has successfully logged in through Auth0.
   */
  isAuth0Authenticated: boolean;
  /**
   * The user object returned from Roundtrip's API
   */
  currentUser: User;
  /**
   * `true` after `isAuth0Authenticated` is `true` and user onboarding is completed.
   *
   * There exists a time where `isAuth0Authenticated` is `true` and this is `false`.
   * This _should_ only happen when the user authenticated via `create account` and are
   * in the onboarding process.
   */
  isAuthenticated: boolean;
};

type ErrorHandler = (callback: () => Promise<void>) => () => Promise<void>;

type HAS_VALID = (minTtl?: number) => Promise<boolean>;

interface NATIVE_CREDENTIALS {
  accessToken: string;
  expiresAt: number;
  idToken: string;
  refreshToken?: string;
  scope?: string;
  tokenType: string;
  [key: string]: any;
}

type WEB_TOKEN = {
  id_token: string;
  access_token: string;
  expires_in: number;
  scope?: string | undefined;
};

type GET_CREDENTIALS = (
  scope?: string,
  minTtl?: number,
  parameters?: Record<string, any>,
  forceRefresh?: boolean,
) => Promise<undefined | NATIVE_CREDENTIALS>;

type Auth0User = {
  name?: string;
  given_name?: string;
  family_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username?: string;
  profile?: string;
  picture?: string;
  website?: string;
  email?: string;
  email_verified?: boolean;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  address?: string;
  updated_at?: string;
  sub?: string;
  [key: string]: any;
};

interface WEB_NATIVE_AUTH0 {
  getCredentials: GET_CREDENTIALS;
  hasValidCredentials: HAS_VALID;
  user: Auth0User;
  getAccessTokenSilently: () => Promise<WEB_TOKEN | undefined>;
  isLoading: boolean;
  error?: Error | { code: string };
  isAuthenticated?: boolean;
}

interface Props {
  audience: string;
  children: React.ReactNode;
}

const USER_CANCELLED = "USER_CANCELLED";
const USER_CANCELLED_ANDROID = "a0.session.user_cancelled";
const language = i18n.language;
const scope =
  "openid profile email offline_access read:hospitals create:locations read:locations read:patients read:rides read:timezones read:transportation_companies read:transportation_types read:trips read:users update:users read:vehicle_types update:ride_status create:hospital_identifiers read:hospital_identifiers update:hospital_identifiers delete:hospital_identifiers create:user_registrations read:payer_types read:benefits";

let count = 1;

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider = ({ children, audience }: Props) => {
  const { debug, warn } = useLogger();
  debug(`AuthProvider - RENDER: ${count}`);

  count += 1;

  const [authError, setAuthError] = useState<Error | undefined>();
  const [auth0TokenPending, setAuth0TokenPending] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const token = useSelector(selectToken);

  const auth0 = useAuth0() as unknown as WEB_NATIVE_AUTH0;
  debug("AuthProvider - Auth0 Data:", auth0);
  debug("AuthProvider - Auth0 User:", auth0.user);
  debug("AuthProvider - Auth0 User Exists:", { exists: Boolean(auth0.user) }); // DD will sometimes remove data (like user data). This is to at least show a user object exists if that happens

  const {
    data: user,
    error: userError,
    isLoading: userLoading,
  } = useGetUserQuery(`${auth0.user?.sub}`, {
    skip: auth0.isLoading || !token,
  });
  debug("AuthProvider - User record returned from Roundtrip API:", user);

  const reset = useCallback(() => {
    // TODO: Invalidate user obj?
    apiService.initialized = false;
    setAuth0TokenPending(false);
    setAuthError(undefined);
    dispatch(setCredentials({ token: "" }));
    dispatch({ type: "RESET" }); // TODO: MAYBE?
  }, [dispatch]);

  const signOut = useCallback(async () => {
    debug("AuthProvider - Signing out user");

    reset();
    dispatch({ type: "RESET" });

    if (Platform.OS === "web") {
      await (auth0 as any).logout();
      return;
    }

    await (auth0 as any).clearCredentials();

    if (Platform.OS === "android") {
      await (auth0 as any).clearSession();
    }
  }, [auth0, debug, dispatch, reset]);

  const signIn = useCallback(() => {
    debug("AuthProvider - Signing user in");

    const opts = {
      scope,
      audience,
      ui_locales: language,
      language: language,
    };

    reset();

    if (Platform.OS === "web") {
      return (auth0 as any).loginWithRedirect(opts);
    }

    return (auth0 as any).authorize(opts, { ephemeralSession: true });
  }, [audience, auth0, debug, reset]);

  const signUp = useCallback(async () => {
    const opts = {
      scope,
      audience,
      ui_locales: language,
      language: language,
    };

    reset();

    if (Platform.OS === "web") {
      await (auth0 as any).loginWithRedirect({
        ...opts,
        prompt: "consent",
        screen_hint: "signup",
      });
      return;
    }

    await (auth0 as any).authorize(
      {
        ...opts,
        additionalParameters: { prompt: "consent", screen_hint: "signup" },
      },
      { ephemeralSession: true },
    );
  }, [audience, auth0, reset]);

  useEffect(() => {
    // These errors do not throw an error and instead are returned from their respective hooks.
    if (userError) {
      warn("Error fetching RT User Record: ", userError);
      setAuthError(userError as Error);
    }

    if (auth0.error) {
      if (Platform.OS === "android") {
        if ((auth0.error as { code: string }).code === USER_CANCELLED_ANDROID) {
          return;
        }
      } else {
        if ((auth0.error as { code: string }).code === USER_CANCELLED) {
          return;
        }
      }

      warn("Error with Auth0: ", auth0.error);
      setAuthError(auth0.error as Error);
    }
  }, [userError, auth0.error, warn]);

  const getToken = async () => {
    let jwt: string | undefined;
    try {
      if (Platform.OS === "web") {
        const tokenRes = await auth0.getAccessTokenSilently();
        jwt = tokenRes as unknown as string;
      } else {
        const credentialsValid = await auth0.hasValidCredentials();
        // TODO: Set err if false?

        if (credentialsValid) {
          const creds = await auth0.getCredentials(scope);
          debug("AuthProvider - Auth0 user credentials:", creds);
          jwt = creds?.accessToken;
        } else {
          debug("AuthProvider - User credentials are invalid");
        }
      }

      // TODO: An error here causes an infinite loop. Add more resilient error handling.

      if (jwt) {
        dispatch(setCredentials({ token: jwt as unknown as string }));
      }

      setAuth0TokenPending(false);
    } catch (e) {
      setAuthError(e as Error);
      debug((e as Error)?.message);
      setAuth0TokenPending(false);
    }
  };

  const errorHandler: ErrorHandler = (callback) => async () => {
    try {
      await callback();
    } catch (e) {
      setAuthError(e as Error);
    }
  };

  let loading = auth0.isLoading || userLoading || auth0TokenPending;
  if (auth0.user && !token && !auth0TokenPending && !authError) {
    debug("AuthProvider - Fetching Auth0 token");
    setAuth0TokenPending(true);
    getToken();
    loading = true;
  }

  if (token && !apiService.initialized) {
    apiService.init(token, signOut);
  }

  const final: AuthContextData = useMemo(
    () => ({
      isAuth0Authenticated: Boolean(auth0.user),
      currentUser: user || ({} as User),
      authError,
      isAuthenticated: Boolean(user),
      loading,
      signIn: errorHandler(signIn),
      signOut: errorHandler(signOut),
      signUp: errorHandler(signUp),
    }),
    [auth0.user, authError, loading, signIn, signOut, signUp, user],
  );

  debug("AuthProvider - Final Auth Context Data:", final);

  return <AuthContext.Provider value={final}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export { AuthContext, AuthProvider, useAuth };
