import Card from "../Card";
import { ReactComponent as SquareIcon } from "../../assets/icons/circle-filled.svg";
import { ReactComponent as PolygonIcon } from "../../assets/icons/polygon-filled.svg";

interface INewTripSummaryProps {
  pickupLocation: any;
  dropoffLocation: any;
}

const NewTripSummary = ({
  pickupLocation,
  dropoffLocation,
}: INewTripSummaryProps) => {
  return (
    <Card>
      <div className="flex">
        <div className="flex-initial flex-col justify-between">
          <SquareIcon className="-ml-[1px] fill-primary" />

          <div className="flex h-full">
            <div className="px-2 mt-1 mb-11">
              <div className="self-stretch border-r-2 border-primary border-dashed h-full" />
            </div>
          </div>
          <PolygonIcon className="-m-1 -mt-11 fill-inactive" />
        </div>

        <div className="flex flex-1 flex-col ">
          <div className="flex flex-row justify-between">
            <p className="mx-5 text-left text-base text-subtitle font-extrabold">
              {pickupLocation.data.name}
            </p>
          </div>

          <div className="py-4" />

          <div className="flex flex-row justify-between">
            <p className="mx-5 text-left text-base text-subtitle">
              {dropoffLocation.data.name}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default NewTripSummary;
