import axios, { AxiosInstance } from "axios";
import configService from "../config";
import { getErrorMessage, reportError } from "../../utilities/helpers";
import { ILocation } from "../../interfaces/Location";
import { IUserRegistration } from "../../interfaces/User";
import { IPatientUpdate } from "../../interfaces/Patient";
import {
  ITripDistanceParams,
  ITripEstimateDistanceParams,
  ITripIntent,
} from "../../interfaces/Trip";

class APIService {
  public initialized: boolean = false;

  private http: AxiosInstance | undefined = undefined;
  private accessToken: string | undefined = undefined;

  init = (token: string, authError: () => Promise<void>) => {
    this.http = axios.create({
      baseURL: configService.get("API_URL"),
      headers: {
        Accept: "application/json",
      },
    });

    this.http.interceptors.response.use(
      (response) => response,
      (error) => {
        // Return if the request is canceled
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }

        // If the server sends a 401, refresh the page. This will cause the user to
        // be sent to the sign in page. When they've logged in, the back end will
        // redirect them to the current URL.
        if (error.response.status === 401) {
          console.warn("401 Status Code");
          authError(); // TODO: Return after .then?
          return Promise.reject(error);
        }

        // If the server sends a 500, send the user to our 500 error page.
        if (error.response.status === 500) {
          // window.location.href = InternalServerErrorPath;
          // TODO: Load error boundary?
        }

        if (error.response.status === 422) {
          console.warn("422 Status Code");
        }

        reportError({ message: getErrorMessage(error) });

        return Promise.reject(error);
      },
    );

    if (token) {
      this.accessToken = token;
      this.http.defaults.headers.common.Authorization = token;
    }

    this.initialized = true;
  };

  //Trip
  createTripIntent = async (intent: ITripIntent): Promise<ITripIntent> => {
    if (this.http) {
      const response = await this.http.post<{ trip_intent: ITripIntent }>(
        "/trip_intents",
        intent,
      );
      return response?.data.trip_intent;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  updateTripIntent = async (intent: ITripIntent) => {
    if (this.http) {
      const response = await this.http.patch(
        `/trip_intents/${intent.id}`,
        intent,
      );
      return response?.data?.trip_intent;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  createTrip = async (trip_intent_id: string) => {
    if (this.http) {
      const response = await this.http.post("/trips", {
        trip: {
          trip_intent_id,
        },
      });
      return response?.data.trip;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  updateTrip = async (trip_id: string, trip_intent_id: string) => {
    if (this.http) {
      const response = await this.http.patch(`/trips/${trip_id}`, {
        trip: {
          trip_id,
          trip_intent_id,
        },
      });
      return response?.data.trip;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  getTrip = async (id: string) => {
    if (this.http) {
      const response = await this.http.get(`/trip_list/${id}`);
      return response?.data.trip;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  getTrips = async (page?: number, count?: number, status?: string) => {
    if (this.http) {
      const url = `/trip_list?${page && "page=" + page + "&"}${
        count && "items=" + count + "&"
      }${status && "status=" + status}`;
      const response = await this.http.get(url);
      return response?.data.trips;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  getTripDispatch = async (id: string) => {
    const response = await this.http?.get(
      `/rides/${id}/ride_attempts?ride_attempt_status_id=${2}`,
    );
    return response?.data?.ride_attempts;
  };

  getTripDispatchDetails = async (ride_attempt_id: string) => {
    const response = await this.http?.get(
      `/ride_attempt_details?ride_attempt_id=${ride_attempt_id}`,
    );
    return response?.data ? response.data : null;
  };

  getTripDetails = async (id: string) => {
    if (this.http) {
      const response = await this.http.get(`/trips/${id}`);
      return response?.data.trip;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  getTransportationCompany = async (id: number) => {
    const response = await this.http?.get(`/transportation_companies/${id}`);
    return response?.data.transportation_company;
  };

  getTripDistance = async (locations: ITripDistanceParams) => {
    const response = await this.http?.post("/trip_distances", {
      trip_distance: {
        origin: {
          lat: locations.pickup.latitude,
          lng: locations.pickup.longitude,
        },
        destination: {
          lat: locations.dropoff.latitude,
          lng: locations.dropoff.longitude,
        },
      },
    });
    return response?.data.trip_distance;
  };

  cancelTrip = async (tripID: string, cancellationID: string) => {
    const response = await this.http?.patch(`/trip_status/${tripID}`, {
      trip_status: {
        status: "cancel",
        cancelation_reason: cancellationID,
        send_notifications: true,
      },
    });
    return response?.data;
  };

  cancelRide = async (id: string, reason: string) => {
    const body = {
      ride_status: {
        status: "cancel",
        cancelation_reason: reason,
        send_notifications: true,
      },
    };
    const response = await this.http?.patch(`/ride_status/${id}`, body);
    return response?.data;
  };

  getPatientBenefits = async (patientID: string) => {
    if (this.http) {
      const response = await this.http.get(`/patients/${patientID}/benefits/`);
      return response?.data.benefits;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  getHospital = async (hospitalID: string) => {
    if (this.http) {
      const response = await this.http.get(`/hospitals/${hospitalID}`);
      return response?.data.hospital;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  getHospitalPayer = async (hospitalID: string) => {
    if (this.http) {
      const response = await this.http.get(
        `/hospitals/${hospitalID}/payer_types`,
      );
      return response?.data.payer_types[0]
        ? response?.data.payer_types[0]
        : null;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  getHospitalTransportationTypes = async (
    hospitalID: string,
    zipCode?: string,
  ) => {
    if (this.http) {
      const uri = zipCode
        ? `/hospitals/${hospitalID}/transportation_types?zipcode=${zipCode}`
        : `/hospitals/${hospitalID}/transportation_types`;
      const response = await this.http.get(uri);
      return response?.data.transportation_types;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  getHospitalVehicleTypes = async (hospitalID: string) => {
    if (this.http) {
      const response = await this.http.get(
        `/hospitals/${hospitalID}/vehicle_types`,
      );
      return response?.data.vehicle_types;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  getHospitalReasonTypes = async (hospitalID: string) => {
    if (this.http) {
      const response = await this.http.get(
        `/hospitals/${hospitalID}/reason_types`,
      );
      return response?.data.reason_types;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  //Locations
  getRecentLocations = async (patient_id: number) => {
    const response = await this.http?.get(
      `/locations?patient_id=${patient_id}`,
    );
    return response?.data.locations;
  };

  getLocation = async (id: number) => {
    const response = await this.http?.get(`/locations/${id}`);
    return response?.data.location;
  };

  createLocation = async (payload: ILocation) => {
    const response = await this.http?.post("/locations", {
      location: payload,
    });
    return response?.data.location;
  };

  //User
  getUser = async (idp_id: string) => {
    if (this.http) {
      const response = await this.http.get(`/users?idp_id=${idp_id}`);
      if (response.data.users) {
        return response.data.users[0] ? response.data.users[0] : null;
      }
    } else {
      throw new Error("API Not Initialized");
    }
  };
  deleteUser = async (userID: string) => {
    if (this.http) {
      const response = await this.http.delete(`users/${userID}`);
      return response?.data.user;
    } else {
      throw new Error("API Not Initialized");
    }
  };
  registerUser = async (u: IUserRegistration) => {
    if (this.http) {
      const registrationData = {
        user_registration: {
          first_name: u.firstName,
          last_name: u.lastName,
          identifier_value: u.memberId,
        },
      };
      const response = await this.http.post(
        "/user_registrations",
        registrationData,
      );
      return response?.data.user_registration;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  //Patient
  getPatient = async (user_id: string) => {
    if (this.http) {
      const response = await this.http.get(`/patients?user_id=${user_id}`);
      if (response.data.patients.length > 2) {
        throw new Error("Multiple Patients Found");
      }
      if (response.data.patients) {
        return response.data.patients.length === 0
          ? null
          : response.data.patients[0];
      } else {
        throw new Error("Unexpected Error");
      }
    } else {
      throw new Error("API Not Initialized");
    }
  };
  updatePatient = async (patient: IPatientUpdate) => {
    if (this.http) {
      const patientData = {
        patient: patient,
      };

      const response = await this.http.patch(
        `patients/${patient.id}`,
        patientData,
      );
      return response?.data.patient;
    } else {
      throw new Error("API Not Initialized");
    }
  };
  createSetupIntent = async () => {
    if (this.http) {
      const response = await this.http.post("/setup_intents");
      return response?.data?.setup_intent;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  createPaymentIntent = async (tripId: string, paymentMethodId: string) => {
    if (this.http) {
      const response = await this.http.post("/payment_intents", {
        trip_id: tripId,
        payment_method_id: paymentMethodId,
      });
      return response?.data?.payment_intent;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  createTripEstimateFare = async (trip_intent_id: string) => {
    if (this.http) {
      const response = await this.http.post("/trip_estimates", {
        trip_estimate: {
          trip_intent_id: trip_intent_id,
        },
      });
      return response?.data?.trip_estimates;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  createTripEstimateDistance = async (
    durationParams: ITripEstimateDistanceParams,
  ) => {
    if (this.http) {
      const response = await this.http.post("/trip_distances", {
        trip_distance: {
          start_time: durationParams.start_time,
          origin: {
            lat: durationParams.origin.latitude,
            lng: durationParams.origin.longitude,
          },
          destination: {
            lat: durationParams.destination.latitude,
            lng: durationParams.destination.longitude,
          },
        },
      });
      return response?.data?.trip_distance;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  getPaymentCards = async (patient_id: string) => {
    if (this.http) {
      const response = await this.http.get(
        `patients/${patient_id}/payment_methods`,
      );
      return response?.data.payment_methods;
    } else {
      throw new Error("API Not Initialized");
    }
  };

  deletePaymentCard = async (
    patient_id: string,
    customer_id: string,
    card_id: string,
  ) => {
    if (this.http) {
      const response = await this.http.delete(
        `patients/${patient_id}/payment_methods/${customer_id}`,
        {
          data: {
            card_id,
          },
        },
      );
      return response?.data.payment_methods;
    } else {
      throw new Error("API Not Initialized");
    }
  };
}

const instance = new APIService();
export default instance;
