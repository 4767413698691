import enums from "../../constants/enums";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface IStatusTagProps {
  status: keyof typeof enums.RideStatus | string;
}

const Tag = ({ status }: IStatusTagProps) => {
  const { t } = useTranslation();

  //text colour
  const labelText = () => {
    if (status === enums.RideStatus.claimed) {
      return t(enums.RideStatus.confirmed);
    } else {
      return t(status);
    }
  };

  //text colour
  const textColor = () => {
    if (
      status === enums.RideStatus.claimed ||
      status === enums.RideStatus.at_pickup ||
      status === enums.RideStatus.at_dropoff ||
      status === enums.RideStatus.to_dropoff ||
      status === enums.RideStatus.to_pickup ||
      status === enums.RideStatus.completed
    ) {
      return "text-success";
    } else if (
      status === enums.RideStatus.canceled ||
      status === enums.RideStatus.incomplete
    ) {
      return "text-error";
    } else {
      return "text-secondary";
    }
  };

  //background colour
  const backgroundColor = () => {
    if (
      status === enums.RideStatus.claimed ||
      status === enums.RideStatus.at_pickup ||
      status === enums.RideStatus.at_dropoff ||
      status === enums.RideStatus.to_dropoff ||
      status === enums.RideStatus.to_pickup ||
      status === enums.RideStatus.completed
    ) {
      return "bg-positive/10";
    } else if (
      status === enums.RideStatus.canceled ||
      status === enums.RideStatus.incomplete
    ) {
      return "bg-negative/10";
    } else {
      return "bg-disabled/10";
    }
  };

  return (
    <div
      className={clsx([
        `${backgroundColor()} flex-row items-center rounded w-auto pr-2`,
        "py-1 px-2",
      ])}>
      <p
        className={`${textColor()} text-center font-extrabold text-sm uppercase`}>
        {labelText()}
      </p>
    </div>
  );
};

export default Tag;
