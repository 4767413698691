import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IHospital } from "../../interfaces/Hospital";
import apiService from "../../services/api";
import { getErrorMessage } from "../../utilities/helpers";

export const fetchHospital = createAsyncThunk(
  "account/fetchHospital",
  async (hospital_id: string, { rejectWithValue }) => {
    try {
      return await apiService.getHospital(hospital_id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

interface SliceState {
  loading: "idle" | "loading" | "finished";
  data: IHospital | null;
  error: String | null;
}

const initialState: SliceState = {
  loading: "idle",
  data: null,
  error: null,
};

const hospitalSlice = createSlice({
  name: "account/hospital",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchHospital.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchHospital.rejected, (state, action) => {
        state.loading = "finished";
        state.error = getErrorMessage(action.error);
      })
      .addCase(fetchHospital.fulfilled, (state, action) => {
        state.loading = "finished";
        state.data = action.payload ? action.payload : null;
      });
  },
});

export const selectHospital = ({ account }: any) => account.hospital;
export const hospitalActions = hospitalSlice.actions;
export default hospitalSlice.reducer;
