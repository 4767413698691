import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api";
import { getErrorMessage } from "../../utilities/helpers";

export const fetchTripDispatch = createAsyncThunk(
  "trip/fetchTripDispatch",
  async (ride_id: string, { rejectWithValue }) => {
    try {
      return await apiService.getTripDispatch(ride_id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchTripDispatchDetails = createAsyncThunk(
  "trip/fetchTripDispatchDetails",
  async (ride_attempt_id: string, { rejectWithValue }) => {
    try {
      return await apiService.getTripDispatchDetails(ride_attempt_id);
    } catch (error: any) {
      reportError({ message: getErrorMessage(error) });
      return rejectWithValue(error.response.data);
    }
  },
);

interface SliceState {
  ride_attempt: {
    data: any;
    loading: "idle" | "loading" | "finished";
    error: String | undefined | null;
  };
  ride_attempt_details: {
    data: any;
    loading: "idle" | "loading" | "finished";
    error: String | undefined | null;
  };
}

const initialState: SliceState = {
  ride_attempt: {
    data: null,
    loading: "idle",
    error: null,
  },
  ride_attempt_details: {
    data: null,
    loading: "idle",
    error: null,
  },
};

const tripDispatchSlice = createSlice({
  name: "trip/tripDispatch",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTripDispatch.pending, (state) => {
        state.ride_attempt.loading = "loading";
      })
      .addCase(fetchTripDispatch.rejected, (state, action) => {
        state.ride_attempt.loading = "finished";
        state.ride_attempt.error = getErrorMessage(action.error);
      })
      .addCase(fetchTripDispatch.fulfilled, (state, action) => {
        state.ride_attempt.loading = "finished";
        state.ride_attempt.data = action.payload;
      })
      .addCase(fetchTripDispatchDetails.pending, (state) => {
        state.ride_attempt_details.loading = "loading";
      })
      .addCase(fetchTripDispatchDetails.rejected, (state, action) => {
        state.ride_attempt_details.loading = "finished";
        state.ride_attempt_details.error = getErrorMessage(action.error);
      })
      .addCase(fetchTripDispatchDetails.fulfilled, (state, action) => {
        state.ride_attempt_details.loading = "finished";
        state.ride_attempt_details.data = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const selectTripDispatch = ({ trip }: any) =>
  trip.tripDispatch.ride_attempt.data;
export const selectTripDispatchDetails = ({ trip }: any) =>
  trip.tripDispatch.ride_attempt_details.data;

// export const tripActions = tripDispatchSlice.actions;
export default tripDispatchSlice.reducer;
