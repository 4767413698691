import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api";
import { getErrorMessage } from "../../utilities/helpers";
import { IReason } from "../../interfaces/Reason";

interface SliceState {
  data: Array<IReason>;
  loading: "idle" | "loading" | "finished";
  error: String | null;
}

const initialState: SliceState = {
  data: [],
  loading: "idle",
  error: null,
};

export const fetchReasonTypes = createAsyncThunk(
  "booking/reason/fetchReasonTypes",
  async (hospital_id: string, { rejectWithValue }) => {
    try {
      return await apiService.getHospitalReasonTypes(hospital_id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const reasonSlice = createSlice({
  name: "booking/reason",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchReasonTypes.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchReasonTypes.rejected, (state, action) => {
        state.loading = "finished";
        state.error = getErrorMessage(action.error);
      })
      .addCase(fetchReasonTypes.fulfilled, (state, action) => {
        state.loading = "finished";
        state.data = action.payload;
      });
  },
});

export const reasonTypes = ({ booking }: any) => booking.reason;
export default reasonSlice.reducer;
