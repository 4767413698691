import { User } from "../../store/account/userSlice";
import configService from "../config";

declare let pendo: any;

class PendoService {
  initPendo = (user: User, patient: any, hospital: any) => {
    const pendoApiKey = configService.get("PENDO_API_KEY");

    if (pendoApiKey) {
      pendo.events.ready(function () {
        setTimeout(function () {
          //hide the resource center button
          var containers = document.getElementsByClassName("_pendo-badge");
          if (containers.length > 0) {
            containers[0].classList.add("_pendo-button-hide");
          }
        }, 1000);
      });

      pendo.initialize({
        visitor: {
          id: patient.data.id,
          first_name: patient.data.first_name,
          last_name: patient.data.last_name,
          email: user.email,
          user_role: "B2B2C",
          active: true,
        },
        account: {
          id: `hospital_${patient.data.hospital_id}`,
          company_name: hospital.data.name,
          industry: "Hospital",
        },
      });
    }
  };
}
const instance = new PendoService();
export default instance;
