import Button, { ButtonProps } from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";
import Spinner from "../Spinner";

interface IButtonProps extends ButtonProps {
  loading?: Boolean;
  icon?: React.ReactNode;
}

const Index = (props: IButtonProps) => {
  const { icon, loading } = props;
  let p = { ...props };
  delete p.loading;

  return (
    <ThemeProvider theme={theme}>
      <Button size="large" {...p}>
        {!icon && loading ? (
          <div className="w-6">
            <Spinner />
          </div>
        ) : (
          props.children
        )}

        {icon && (
          <div className="absolute right-1.5 bottom-1.5 top-1.5  text-2xl bg-white/25 rounded">
            {loading ? (
              <div className="w-8">
                <Spinner />
              </div>
            ) : (
              icon
            )}
          </div>
        )}
      </Button>
    </ThemeProvider>
  );
};

export default Index;
