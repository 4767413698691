import clsx from "clsx";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { Datepicker, locale } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { useTranslation } from "react-i18next";

interface ITimeSelectorItemProps {
  onChange: (e?: any) => void;
  onBlur?: (e?: any) => void;
  value: string;
  selected?: boolean;
  reset?: boolean; //reset input if necessary
  minTime?: Moment | undefined;
  returnDate?: boolean;
  error?: boolean;
  small?: boolean;
}

const TimeSelectorItem = React.forwardRef(
  (
    {
      onChange = () => {},
      onBlur = () => {},
      value,
      selected,
      reset,
      minTime,
      returnDate, // eslint-disable-line @typescript-eslint/no-unused-vars
      error,
      small,
    }: ITimeSelectorItemProps,
    ref, // eslint-disable-line @typescript-eslint/no-unused-vars
  ) => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const [val, setVal] = useState(value ? moment(value, "HH:mm") : undefined);

    //if originDate changes (outbound date) - reset date input to avoid conflicts
    useEffect(() => {
      if (reset) {
        onChange();
        setVal(undefined);
        onBlur();
      }
    }, [reset]); //eslint-disable-line

    const handleChange = (newValue: any) => {
      const minimumTime = !minTime
        ? undefined
        : moment(minTime.format("HH:mm"), "HH:mm");

      if (
        newValue !== null &&
        (!minimumTime ||
          (minimumTime &&
            moment(minimumTime).isBefore(
              moment(moment(newValue.value).format("HH:mm"), "HH:mm"),
            )))
      ) {
        setVal(moment(newValue.value));
        onChange(moment(newValue.value).format("HH:mm"));
        onBlur(moment(newValue.value).format("HH:mm"));
      }
    };

    return (
      <div
        className={clsx([
          "border-2 rounded-md relative p-2 md:p-4 cursor-pointer h-full",
          selected && "border-primary",
          error && "border-error",
          !selected && !error && "border-surface",
        ])}
        onClick={() => (isOpen === false ? setIsOpen(true) : undefined)}>
        <div className="opacity-0 h-0">
          <Datepicker
            controls={["time"]}
            theme="ios"
            themeVariant="light"
            stepMinute={5}
            isOpen={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            onChange={isOpen ? handleChange : undefined} //only change when user changes
            minTime={
              minTime
                ? moment(minTime).add(5, "minutes").format("HH:mm")
                : undefined
            }
            locale={locale[i18n.language]}
            responsive={{
              xsmall: {
                controls: ["time"],
                display: "bottom",
                touchUi: true,
              },
              small: {
                controls: ["time"],
                display: "anchored",
                touchUi: true,
              },
            }}
          />
        </div>
        <div className="h-full grid place-items-center">
          {val ? (
            <>
              <p
                className={
                  small ? "text-lg font-extrabold" : "text-2xl font-extrabold"
                }>
                {moment(val).format("hh:mm A")}
              </p>
              <p className="text-primary font-medium text-sm flex items-center justify-center mt-1">
                {t("change-time")} <i className="icon-chev-right text-lg" />
              </p>
            </>
          ) : (
            <>
              <i className="text-4xl icon-time" />
              <p className="text-secondary text-sm">{t("select-time")}</p>
            </>
          )}
        </div>
      </div>
    );
  },
);

export default TimeSelectorItem;
