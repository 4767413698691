import { useSelector } from "react-redux";
import { Visa, Mastercard } from "react-pay-icons";
import RideBenefits from "../../../../../components/RideBenefits";
import { selectBenefits } from "../../../../../store/account/benefitsSlice";
import { selectPayer } from "../../../../../store/account/payerSlice";
import { selectHospital } from "../../../../../store/account/hospitalSlice";
import { useTranslation } from "react-i18next";

interface IPaymentMethod {
  usePaymentCard: boolean;
}

const PaymentMethod = ({ usePaymentCard }: IPaymentMethod) => {
  const benefits = useSelector(selectBenefits);
  const payer = useSelector(selectPayer);
  const hospital = useSelector(selectHospital);
  const { t } = useTranslation();

  return (
    <div className="w-full mx-auto py-4">
      <div className="text-text text-lg text-left">
        <b>{t("payment-method")}</b>
      </div>

      {usePaymentCard && (
        <div className="pt-4">
          <div className="bg-white p-4 flex rounded-md text-left border-2 transition-all">
            <p className="text-lg my-auto font-extrabold text-primary">
              {t("card")}
            </p>
            <div className="ml-auto my-auto flex">
              <Mastercard style={{ margin: 5, width: 50 }} />
              <Visa style={{ margin: 5, width: 50 }} />
            </div>
          </div>
        </div>
      )}

      {!usePaymentCard && (
        <div className="mx-auto pt-4 pb-2 container max-w-2xl">
          <RideBenefits payer={payer} benefits={benefits} hospital={hospital} />
        </div>
      )}
    </div>
  );
};

export default PaymentMethod;
