import clsx from "clsx";

const Card = ({ children, className }: any) => {
  return (
    <div
      className={clsx([
        "bg-white p-4 rounded-md shadow-md",
        className && className,
      ])}>
      {children}
    </div>
  );
};

export default Card;
