import React, { SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../../components/Modal";
import { ITripIntent } from "../../../../../interfaces/Trip";
import { TripReason } from "../../../../../interfaces/TripReason";
import { AppDispatch } from "../../../../../store";
import { selectPatient } from "../../../../../store/account/userSlice";
import {
  selectPickupLocations,
  selectDropoffLocations,
} from "../../../../../store/booking/locationSlice";
import { createTripIntent } from "../../../../../store/booking/tripSlice";
import { selectPayer } from "../../../../../store/account/payerSlice";
import { alphabeticalSort } from "../../../../../utilities/helpers";
import { useTranslation } from "react-i18next";

interface ITripReasons {
  modalHandler: React.Dispatch<SetStateAction<boolean>>;
  reasons: any;
  rideClicked: string;
  tid: any;
}

const TripReasons = ({
  modalHandler,
  reasons,
  rideClicked,
  tid,
}: ITripReasons) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  //selection
  const { data } = useSelector(selectPatient);
  const pickupLocation = useSelector(selectPickupLocations);
  const dropoffLocation = useSelector(selectDropoffLocations);
  const payer = useSelector(selectPayer);

  //handler for reason selection
  const clickHandler = async (reason_id: number) => {
    //create trip intent
    // eslint-disable-next-line @typescript-eslint/no-shadow
    let t: ITripIntent = {
      hospital_id: data.hospital_id,
      patient_id: data.id,
      payer_type_id: payer.data.id,
      dropoff_location_id: dropoffLocation.data.id,
      pickup_location_id: pickupLocation.data.id,
      //transportation type is null if wheelchair is selected as only vehicle need type is required
      transportation_type: rideClicked === "wheelchair" ? null : rideClicked,
      vehicle_need_type:
        rideClicked === "wheelchair" ? "wheelchair" : "ambulatory",
      trip_reason_id: reason_id,
    };

    //create trip intent
    await creatingTripIntent(t);

    tid ? navigate(`../ride-setup/${tid}`) : navigate("../ride-setup");
  };

  //dispatches update trip intent
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const creatingTripIntent = async (t: ITripIntent) => {
    dispatch(createTripIntent(t));
  };

  const sortedReasonItems = [...reasons.data].sort(alphabeticalSort);

  const reasonItems = sortedReasonItems.map((reason: TripReason) => (
    <div key={reason.id}>
      <button
        className="w-72 text-left pt-3"
        onClick={() => clickHandler(reason.id)}>
        {reason.name}
      </button>
      <div className="py-4">
        <div className="h-0.5 bg-background" />
      </div>
    </div>
  ));

  return (
    <Modal
      onHideModal={() => {
        modalHandler(false);
      }}>
      <p className="text-center text-lg font-extrabold pt-2">
        {t("select-trip-reason")}
      </p>

      <div className="text-base text-text overflow-scroll max-h-96 -mr-4">
        {reasonItems}
      </div>
    </Modal>
  );
};

export default TripReasons;
