import { IHospital } from "../../../../../interfaces/Hospital";
import benefitService from "../../../../../services/benefit";
import { useTranslation } from "react-i18next";

interface IContactLinkProps {
  hospital: IHospital;
}

const ContactUsFooter = ({ hospital }: IContactLinkProps) => {
  const { t } = useTranslation();
  const contactNumber = benefitService.get(hospital?.name).contactNumber;

  return (
    <a
      href={`tel:${contactNumber}`}
      className="flex justify-between border border-surface items-center rounded-md py-7 px-4 bg-white">
      <div className="text-center flex-1">
        <p className="text-lg font-extrabold text-text">
          {t("call-contactNumber", { contactNumber })}
        </p>
      </div>
      <div className="bg-primary/5 rounded-full flex items-center justify-center font-mono">
        <i className="icon-phone text-5xl text-primary" />
      </div>
    </a>
  );
};

export default ContactUsFooter;
