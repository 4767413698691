//dependencies imports
import { useState } from "react";
import clsx from "clsx";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//svg imports
import { ReactComponent as RtHealthLogo } from "../../assets/svg/roundtrip-health-logo.svg";

//component imports
import NavLinks from "./components/NavLinks";
import Modal from "../Modal";
import Button from "../Button";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";

const Navigation = ({ onboarding }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  //state for controlling burger menu in mobile view
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  //state for controlling modal
  const [modalIsShown, setModalIsShown] = useState(false);

  //logout auth0
  const { logout } = useAuth0();

  //styling used to show burger menu
  const showMenuNav =
    "flex absolute w-full h-screen top-0 left-0 bg-white z-20 flex-col justify-evenly items-center";

  const handleLogout = async () => {
    logout({ returnTo: window.location.origin });
    dispatch({ type: "RESET" });
  };

  return (
    <>
      <div className="bg-white drop-shadow-md sticky top-0 w-full z-20">
        <header className="w-full px-4 py-4 flex justify-between items-center drop-shadow-none">
          <div
            className="md:flex md:flex-1 cursor-pointer"
            onClick={() => {
              navigate("/");
            }}>
            <RtHealthLogo className="w-32 fill-primary text-primary" />
          </div>

          <nav className="md:flex md:flex-1 justify-end">
            {/* mobile view section */}
            <section
              id="mobile-view"
              className={onboarding ? clsx([`${"hidden"}`]) : "flex md:hidden"}>
              {/* burger menu icon */}
              <div
                className="space-y-1 flex flex-col items-end"
                onClick={() => setIsMobileNavOpen((prev) => !prev)}>
                <div className="block h-0.5 w-6 bg-text" />
                <div className="block h-0.5 w-5 bg-text" />
                <div className="block h-0.5 w-6 bg-text" />
              </div>

              {isMobileNavOpen && (
                <div className={clsx([`${showMenuNav}`])}>
                  {/* mobile burger menu */}

                  {/* close icon */}
                  <div
                    className="absolute top-0 right-0 py-2 px-1"
                    onClick={() => setIsMobileNavOpen(false)}>
                    <i className="icon-close text-5xl" />
                  </div>

                  {/* navigation links */}
                  <NavLinks
                    styling="flex flex-col items-center justify-around h-3/4"
                    onShowModal={() => {
                      setModalIsShown(true);
                    }}
                    hideBurgerMenu={() => {
                      setIsMobileNavOpen(false);
                    }}
                  />
                </div>
              )}
            </section>

            {/* desktop links view */}
            <NavLinks
              styling={
                onboarding
                  ? clsx([`${"space-x-8 md:flex"}`])
                  : "hidden space-x-8 md:flex"
              }
              onboarding={onboarding}
              onShowModal={() => {
                setModalIsShown(true);
              }}
            />
          </nav>
        </header>
      </div>

      <div>
        {modalIsShown && (
          <Modal
            onHideModal={() => {
              setModalIsShown(false);
            }}>
            <i className={"icon-warning flex justify-center text-8xl -my-4"} />
            <p className="text-center text-xl font-extrabold pt-2">
              {t("log-out")}
            </p>
            <p className="text-center text-sm text-secondary pt-1">
              {t("confirm-log-out")}
            </p>
            <div className="my-2 pt-6 flex justify-center">
              <Button
                className="w-72"
                color="primary"
                variant="contained"
                onClick={handleLogout}>
                {t("log-out")}
              </Button>
            </div>
            <div className="my-2 flex justify-center">
              <Button
                className="w-72"
                color="info"
                variant="contained"
                onClick={() => setModalIsShown(false)}>
                {t("cancel")}
              </Button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Navigation;
