import { useTranslation } from "react-i18next";

interface ISearchNotFoundProps {
  searchTerm: string;
}

const SearchNotFound = ({ searchTerm }: ISearchNotFoundProps) => {
  const { t } = useTranslation();
  return (
    <div className="py-4">
      <div className="w-14 py-2 mx-auto bg-surface rounded-full flex justify-center">
        <i className="icon-search text-4xl" />
      </div>
      <p className="pt-2 text-lg text-text">
        {t("couldnt-find-searchterm", { searchTerm })}
      </p>
      <p className="text-base text-secondary">{t("try-search-again")}</p>
    </div>
  );
};

export default SearchNotFound;
