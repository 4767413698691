import clsx from "clsx";
import NonClickableCard from "../NonClickable";
import { IPaymentItemProps } from "../PaymentCardList";
import ExpiredUI from "../UI";

interface IClickableCardProps {
  card: IPaymentItemProps;
  selected: boolean;
  onClick?: any;
  expired: boolean;
}

const ClickableCard = ({
  card,
  selected,
  onClick,
  expired,
}: IClickableCardProps) => {
  const highlightedCard = () => {
    if (selected) {
      return "border-primary";
    }
    return;
  };

  const outlineCard = () => {
    if (!selected) {
      return "border-surface";
    }
    return;
  };

  return (
    <>
      <div
        className={clsx([
          `${highlightedCard()} ${outlineCard()} cursor-pointer bg-white p-4 rounded-md ${
            expired && "rounded-b-none"
          } text-left border-2 transition-all flex`,
        ])}
        onClick={!expired ? onClick : undefined}>
        <div className="w-full">
          <NonClickableCard card={card} expired={expired} clickable />
        </div>
      </div>
      {/* IF EXPIRED */}
      {expired && (
        <ExpiredUI exp_month={card.exp_month} exp_year={card.exp_year} />
      )}
    </>
  );
};

export default ClickableCard;
