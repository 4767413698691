import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api";
import { getErrorMessage } from "../../utilities/helpers";
import { ITripIntent } from "../../interfaces/Trip";

interface SliceState {
  data: ITripIntent | null;
  loading: "idle" | "loading" | "finished";
  error: string | null;
}

const initialState: SliceState = {
  data: null,
  loading: "idle",
  error: null,
};

export const createTripIntent = createAsyncThunk(
  "booking/trip/createTripIntent",
  async (t: ITripIntent, { rejectWithValue }) => {
    try {
      return await apiService.createTripIntent(t);
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.errors?.join(", ") || "An unknown error occurred";
      console.warn("Validation Failed for CreateTripIntent: " + errorMessage);
      return rejectWithValue(errorMessage);
    }
  },
);

export const updateTripIntent = createAsyncThunk(
  "booking/trip/updateTripIntent",
  async (t: ITripIntent, { rejectWithValue }) => {
    try {
      return await apiService.updateTripIntent(t);
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.errors?.join(", ") || "An unknown error occurred";
      console.warn("Validation Failed for CreateTripIntent: " + errorMessage);
      return rejectWithValue(errorMessage);
    }
  },
);

export const createTrip = createAsyncThunk(
  "booking/trip/createTrip",
  async (trip_intent_id: string, { rejectWithValue }) => {
    try {
      return await apiService.createTrip(trip_intent_id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const tripIntentSlice = createSlice({
  name: "booking/trip",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createTripIntent.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(createTripIntent.rejected, (state, action) => {
        if (typeof action.payload === "string") {
          state.error = action.payload;
        } else {
          state.error = getErrorMessage(action.error);
        }
        state.loading = "finished";
      })
      .addCase(createTripIntent.fulfilled, (state, action) => {
        state.loading = "finished";
        state.data = action.payload;
      })

      .addCase(updateTripIntent.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(updateTripIntent.rejected, (state, action) => {
        if (typeof action.payload === "string") {
          state.error = action.payload;
        } else {
          state.error = getErrorMessage(action.error);
        }
        state.loading = "finished";
      })
      .addCase(updateTripIntent.fulfilled, (state, action) => {
        state.loading = "finished";
        state.data = action.payload;
      })

      .addCase(createTrip.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(createTrip.rejected, (state, action) => {
        state.loading = "finished";
        state.error = getErrorMessage(action.error);
      })
      .addCase(createTrip.fulfilled, (state, action) => {
        state.loading = "finished";
        state.data = action.payload;
      });
  },
});

export const selectTripIntent = ({ booking }: any) => booking.trip;
export default tripIntentSlice.reducer;
