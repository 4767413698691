import {
  InputAdornment,
  MenuItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import { Datepicker, locale } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";
import DateSelectionItem from "./DateSelectionItem";

//schema
const schema = yup
  .object({
    startDate: yup.string().required(),
    repeatEvery: yup.number().required(),
    finishDate: yup.string().required(),
  })
  .required();

const RepeatingTrip = ({
  setModalIsShown,
  setDetails,
  details,
  minDate,
  setRepeatingTripsFilled,
  autoFillStartDate,
}: any) => {
  const { t, i18n } = useTranslation();

  const [selectedDate, setSelectedDate] = useState({
    Monday: details.selectDays?.selectedDate?.Monday ? true : false,
    Tuesday: details.selectDays?.selectedDate?.Tuesday ? true : false,
    Wednesday: details.selectDays?.selectedDate?.Wednesday ? true : false,
    Thursday: details.selectDays?.selectedDate?.Thursday ? true : false,
    Friday: details.selectDays?.selectedDate?.Friday ? true : false,
    Saturday: details.selectDays?.selectedDate?.Saturday ? true : false,
    Sunday: details.selectDays?.selectedDate?.Sunday ? true : false,
  });

  //state for opening date pickers
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);

  //props.details?.startDate ? props.details.startDate : props.minimumDate ? moment(props.minimumDate).format('YYYY-MM-DD') : null

  const {
    control,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: details.startDate
        ? moment(details.startDate).format("YYYY-MM-DD")
        : autoFillStartDate
        ? moment(minDate).format("YYYY-MM-DD")
        : undefined,
      repeatEvery: details.repeatEvery ? details.repeatEvery : undefined,
      occurance: details.occurance ? details.occurance : "week(s)",
      finishDate: details.endDate ? details.endDate : undefined,
    },
  });

  const w = watch();

  const AddRepeatingTripHandler = () => {
    const d = getValues();

    //remove falsy elements
    Object.keys(selectedDate).forEach((key) => {
      if (!selectedDate[key as keyof typeof selectedDate]) {
        delete selectedDate[key as keyof typeof selectedDate];
      }
    });

    setDetails({
      startDate: moment(d.startDate).format(),
      repeatEvery: d.repeatEvery,
      occurance: d.occurance,
      selectDays: d.occurance === "week(s)" ? { selectedDate } : null,
      endDate: moment(d.finishDate).format(),
    });

    setRepeatingTripsFilled(true);
    setModalIsShown(false);
  };

  return (
    <Modal
      onHideModal={() => {
        setModalIsShown(false);
      }}>
      <p className="text-center text-text text-lg font-extrabold pt-2">
        {t("add-repeating-trip")}
      </p>
      <p className="text-center text-base text-secondary pt-1">
        {t("start-filling-information-for-repeating-current-trip")}
      </p>
      <p className="text-left text-text text-lg font-extrabold pt-2">
        {t("start-date")}
      </p>

      <div className="pt-2">
        <Controller
          name="startDate"
          control={control}
          render={({ field }) => (
            <Datepicker
              {...field}
              controls={["date"]}
              theme="ios"
              themeVariant="light"
              isOpen={isStartDateOpen}
              onOpen={() => setIsStartDateOpen(true)}
              onClose={() => setIsStartDateOpen(false)}
              onChange={(e) => {
                field.onChange(moment(e.value).format("YYYY-MM-DD"));
              }}
              min={moment(minDate).format("YYYY-MM-DD")}
              locale={locale[i18n.language]}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              inputComponent={React.forwardRef((params: any, ref) => {
                return (
                  <TextField
                    onClick={() => setIsStartDateOpen(true)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i className="icon-calendar text-5xl" />
                        </InputAdornment>
                      ),
                    }}
                    {...params}
                    fullWidth
                  />
                );
              })}
              responsive={{
                xsmall: {
                  controls: ["date"],
                  display: "bottom",
                  touchUi: true,
                },
                small: {
                  controls: ["date"],
                  display: "center",
                  touchUi: true,
                },
              }}
            />
          )}
        />
      </div>

      <div className="pt-2">
        <p className="text-left text-text text-lg font-extrabold pb-2">
          {t("repeat-every")}
        </p>
        <div className="flex">
          <Controller
            name="repeatEvery"
            control={control}
            render={({ field }) => (
              <OutlinedInput
                error={errors.repeatEvery ? true : false}
                fullWidth
                type={"number"}
                onInput={(e) => {
                  (e.target as HTMLInputElement).value = Math.max(
                    0,
                    parseInt((e.target as HTMLInputElement).value),
                  )
                    .toString()
                    .slice(0, 2);
                }}
                {...field}
              />
            )}
          />

          <div className="pl-1">
            <Controller
              name="occurance"
              control={control}
              render={({ field }) => (
                <TextField fullWidth select defaultValue="week(s)" {...field}>
                  {["day(s)", "week(s)"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {t(option)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </div>
        </div>
      </div>

      {w.occurance === "week(s)" && (
        <>
          <p className="text-left text-text text-lg font-extrabold pt-2">
            {t("select-days")}
          </p>

          <div className="flex py-1">
            {/* map out selectable days */}
            {[
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ].map((date: string) => {
              return (
                <div key={date} className="p-0.5 grow flex justify-center">
                  <DateSelectionItem
                    selected={
                      selectedDate[date as keyof typeof selectedDate] === true
                    }
                    label={t(date)[0]} //obtain first letter of date
                    onClick={() => {
                      setSelectedDate((prevState) => {
                        return {
                          ...selectedDate,
                          [date]: !prevState[date as keyof typeof prevState],
                        };
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}

      <p className="text-left text-text text-lg font-extrabold pt-2">
        {t("ends-on")}
      </p>

      <div className="pt-2">
        <Controller
          name="finishDate"
          control={control}
          render={({ field }) => (
            <Datepicker
              {...field}
              controls={["date"]}
              theme="ios"
              themeVariant="light"
              isOpen={isEndDateOpen}
              onOpen={() => setIsEndDateOpen(true)}
              onClose={() => setIsEndDateOpen(false)}
              onChange={(e) => {
                field.onChange(moment(e.value).format("YYYY-MM-DD"));
              }}
              min={
                w.startDate
                  ? moment(w.startDate).add(1, "day").format("YYYY-MM-DD")
                  : moment(minDate).format("YYYY-MM-DD")
              }
              locale={locale[i18n.language]}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              inputComponent={React.forwardRef((params: any, ref) => {
                return (
                  <TextField
                    onClick={() => setIsEndDateOpen(true)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i className="icon-calendar text-5xl" />
                        </InputAdornment>
                      ),
                    }}
                    {...params}
                    fullWidth
                  />
                );
              })}
              responsive={{
                xsmall: {
                  controls: ["date"],
                  display: "bottom",
                  touchUi: true,
                },
                small: {
                  controls: ["date"],
                  display: "center",
                  touchUi: true,
                },
              }}
            />
          )}
        />
      </div>

      <div className="my-2 pt-6 flex justify-center">
        <Button
          className="w-72"
          color="primary"
          variant="contained"
          disabled={
            !isValid ||
            (w.occurance === "week(s)"
              ? !Object.values(selectedDate).includes(true)
              : false)
          }
          onClick={AddRepeatingTripHandler}>
          {t("save")}
        </Button>
      </div>
    </Modal>
  );
};

export default RepeatingTrip;
