import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage } from "../../utilities/helpers";

import apiService from "../../services/api";

export const createSetupIntent = createAsyncThunk(
  "booking/payments/createSetupIntent",
  async (id: number, { rejectWithValue }) => {
    // TODO: Why can't I remove id?
    try {
      return await apiService.createSetupIntent();
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

interface SliceState {
  loading: "idle" | "loading" | "finished";
  data: any | null;
  error: String | null;
}

const initialState: SliceState = {
  loading: "idle",
  data: null,
  error: null,
};

export const paymentIntentSlice = createSlice({
  name: "booking/paymentIntent",
  initialState,

  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(createSetupIntent.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(createSetupIntent.rejected, (state, action) => {
        state.loading = "finished";
        state.error = getErrorMessage(action.error);
      })
      .addCase(createSetupIntent.fulfilled, (state, action) => {
        state.loading = "finished";
        state.data = action.payload;
      });
  },
});

export const paymentIntentActions = paymentIntentSlice.actions;
export default paymentIntentSlice.reducer;
