//components
import Container from "../../../components/Container";
import Spinner from "../../../components/Spinner";
import PaymentCardList from "../../../components/PaymentCardList";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import StripeCheckout from "../../../components/StripeCheckout";

//store
import { selectPatient } from "../../../store/account/userSlice";
import { fetchPayer, selectPayer } from "../../../store/account/payerSlice";
import { AppDispatch } from "../../../store";
import {
  fetchPaymentCards,
  selectPayment,
} from "../../../store/account/paymentSlice";

//dependencies
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SavedPaymentCardsScreen = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { data } = useSelector(selectPatient);
  const payer = useSelector(selectPayer);
  const payment = useSelector(selectPayment);
  const patient = useSelector(selectPatient);
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  //disable show modal when new payment card is added
  useEffect(() => {
    setShowModal(false);
  }, [payment]);

  //fetch payment cards
  useEffect(() => {
    if (patient.data) {
      dispatch(fetchPaymentCards(patient.data.id));
    }
    return () => {};
  }, [dispatch, patient.data]);

  useEffect(() => {
    if (data?.hospital_id !== null) {
      dispatch(fetchPayer(data?.hospital_id));
    }
  }, [data?.hospital_id, dispatch]);

  if (payer.loading !== "finished" || payment.loading !== "finished") {
    return (
      <div className="flex flex-1 items-center">
        <div className="w-12 my-6 mx-auto">
          <Spinner />
        </div>
      </div>
    );
  } else {
    return (
      <>
        {payer.data.category === "patient" && (
          <Container>
            <div className="flex flex-col flex-1 pt-4 md:w-3/4 md:mx-auto">
              <div className="flex-1">
                {payment?.data && payment?.data.length > 0 ? (
                  payment.data.map((p: any) => {
                    return <PaymentCardList key={p.id} card={p.card} />;
                  })
                ) : (
                  <p className="font-extrabold text-md text-center text-subtitle pt-8 w-full">
                    {t("you-havent-added-any-cards")}
                  </p>
                )}
              </div>

              <div className="py-6">
                <Button
                  className="w-80"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setShowModal(true);
                  }}>
                  {t("add-new-card")}
                </Button>
              </div>
            </div>
          </Container>
        )}
        {payer.data.category !== "patient" && (
          <Container>
            <Navigate to="../" replace />
          </Container>
        )}
        {showModal && (
          <Modal onHideModal={() => setShowModal(false)}>
            <div className="w-72 md:w-96">
              <StripeCheckout />
            </div>
          </Modal>
        )}
      </>
    );
  }
};

export default SavedPaymentCardsScreen;
