import { FormGroup } from "@mui/material";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Switcher from "../../../../../components/Switch";

interface IAdditionalNeeds {
  transportationType: "wheelchair" | "medical_sedan";
  control: Control<
    {
      tripType: string;
      selectedDate: string;
      selectedTime: string;
      selectedDateReturn: string;
      selectedTimeReturn: string;
      driverInstructions: any;
      weight: any;
      provideWheelchair: boolean;
      bringOwnOxygen: boolean;
      provideCarSeat: boolean;
      anyStairs: boolean;
      stairs: any;
    },
    any
  >;
}

const AdditionalNeeds = ({ transportationType, control }: IAdditionalNeeds) => {
  const { t } = useTranslation();

  if (transportationType === "wheelchair") {
    return (
      <div className="text-left">
        <b className="text-text text-lg">{t("additional-needs")}</b>

        <FormGroup className="-ml-4 mr-2">
          <Controller
            name="provideWheelchair"
            control={control}
            render={({ field }) => (
              <Switcher
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(e.target.checked)
                }
                checked={field.value}
                label={t("i-will-need-a-wheelchair-provided")}
              />
            )}
          />

          <Controller
            name="bringOwnOxygen"
            control={control}
            render={({ field }) => (
              <Switcher
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(e.target.checked)
                }
                checked={field.value}
                label={t("i-will-bring-my-own-oxygen")}
              />
            )}
          />

          <Controller
            name="anyStairs"
            control={control}
            render={({ field }) => (
              <Switcher
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(e.target.checked)
                }
                checked={field.value}
                label={t("my-trip-includes-stairs")}
              />
            )}
          />
        </FormGroup>
      </div>
    );
  } else {
    return (
      <div className="text-left">
        <b className="text-text text-lg">{t("additional-needs")}</b>

        <FormGroup className="-ml-4 mr-2">
          <Controller
            name="provideCarSeat"
            control={control}
            render={({ field }) => (
              <Switcher
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  field.onChange(e.target.checked)
                }
                checked={field.value}
                name={"provideCarSeat"}
                label={t("i-will-need-a-car-seat-provided")}
              />
            )}
          />
        </FormGroup>
      </div>
    );
  }
};

export default AdditionalNeeds;
