import configService from "../config";

declare let FS: any;

class FullStoryService {
  initFullStory = () => {
    const fullStoryID = configService.get("FULL_STORY_ID");

    if (fullStoryID) {
      // Set userVars for FullStory
      FS.setUserVars({ role: "B2B2C" });
    }
  };
}
const instance = new FullStoryService();
export default instance;
