import clsx from "clsx";
import { FormControlLabel, Radio } from "@mui/material";

enum BenefitType {
  healthPlan = "health-plan",
  acs = "acs-road-to-recovery",
  paratransit = "paratransit",
  other = "other",
}

interface IClickableCardProps {
  onClick?: any;
  selected: boolean;
  value: BenefitType;
  label: string;
}

const ClickableCard = ({
  onClick,
  selected,
  value,
  label,
}: IClickableCardProps) => {
  const highlightedCard = () => {
    if (selected) {
      return "shadow-[0px_0px_32px_rgba(0,0,66,0.15)] border-transparent";
    }
    return;
  };

  const outlineCard = () => {
    if (!selected) {
      return "border-surface";
    }
    return;
  };

  return (
    <div
      className={clsx([
        `${highlightedCard()} ${outlineCard()} bg-white p-4 rounded-md text-left border-2 transition-all`,
      ])}
      onClick={onClick}>
      <FormControlLabel
        value={value}
        control={<Radio />}
        label={<p className="font-extrabold font-base">{label}</p>}
      />
    </div>
  );
};

export default ClickableCard;
