import Lottie from "lottie-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Success from "../../../../assets/lottie/success.json";
import Button from "../../../../components/Button";
import Spinner from "../../../../components/Spinner";
import { AppDispatch } from "../../../../store";
import { resetEditTrip } from "../../../../store/booking/editSlice";
import { selectTripIntent } from "../../../../store/booking/tripSlice";
import { fetchTrip, selectTrip } from "../../../../store/trip/tripSlice";
import { refreshUpcomingTrips } from "../../../../store/trips/upcomingSlice";

const SuccessScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  //selecting
  const trip = useSelector(selectTripIntent);
  const select_trip = useSelector(selectTrip);

  //edit trip
  const routeParams = useParams();
  const { tid } = routeParams;

  //fetch trip details
  useEffect(() => {
    if (trip?.data?.id && !tid) {
      dispatch(fetchTrip(trip.data.id));
    }
  }, [dispatch, trip?.data?.id, tid]);

  //refresh dashboard for edit trip
  useEffect(() => {
    if (tid) {
      dispatch(fetchTrip(tid));
      dispatch(refreshUpcomingTrips(1));
    }
  }, [dispatch, tid]);

  if (trip.loading !== "finished" || select_trip.loading !== "finished") {
    return (
      <div className="flex flex-1 items-center">
        <div className="w-12 my-6 mx-auto">
          <Spinner />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="my-auto">
          <div className="w-16 mx-auto">
            <Lottie
              animationData={Success}
              loop={false}
              width={32}
              height={32}
            />
          </div>

          <h1 className="text-2xl text-text font-extrabold py-4">
            {tid
              ? t("thank-you-your-trip-has-been-updated")
              : t("thank-you-your-trip-has-been-booked")}
          </h1>
          <p className="text-base text-secondary">
            {tid
              ? t("updated-check-email-confirmation")
              : t("booked-check-email-confirmation")}
          </p>
        </div>

        <div className="bg-gradient-to-t from-background via-background to-background/50 fixed bottom-0 left-0 right-0 p-6">
          <div className="">
            <Button
              className="w-80"
              color="primary"
              variant="contained"
              onClick={() => {
                dispatch({ type: "booking/reset" });
                if (tid) {
                  dispatch(resetEditTrip());
                }
                navigate(
                  `/trip-details/${select_trip.data.id}/${select_trip.data.rides[0].id}`,
                );
              }}>
              {t("view-trip-details")}
            </Button>
          </div>

          <div className="pt-4">
            <Button
              className="w-80"
              color="secondary"
              variant="contained"
              onClick={() => {
                dispatch({ type: "booking/reset" });
                if (tid) {
                  dispatch(resetEditTrip());
                }
                navigate("/");
              }}>
              {t("back-to-dashboard")}
            </Button>
          </div>
        </div>
      </>
    );
  }
};

export default SuccessScreen;
