import clsx from "clsx";

interface IDateItemProps {
  onClick?: any;
  selected: boolean;
  label: string;
}

const DateSelectionItem = ({ onClick, selected, label }: IDateItemProps) => {
  const highlightedDate = () => {
    if (selected) {
      return "bg-primary text-white";
    }
    return;
  };

  const outlineDate = () => {
    if (!selected) {
      return "bg-background text-secondary";
    }
    return;
  };

  return (
    <div
      className={clsx([
        `${highlightedDate()} ${outlineDate()} grid place-items-center cursor-pointer uppercase text-base font-extrabold w-10 h-10 rounded-full transition-all`,
      ])}
      onClick={onClick}>
      {label}
    </div>
  );
};

export default DateSelectionItem;
