import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api";
import { getErrorMessage } from "../../utilities/helpers";

export const fetchTransportationCompany = createAsyncThunk(
  "trip/fetchTransportationCompany",
  async (company_id: number, { rejectWithValue }) => {
    try {
      return await apiService.getTransportationCompany(company_id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

interface SliceState {
  data: any;
  loading: "idle" | "loading" | "finished";
  error: String | undefined | null;
}

const initialState: SliceState = {
  data: null,
  loading: "idle",
  error: null,
};

const transportationCompanySlice = createSlice({
  name: "trip/transportationCompany",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTransportationCompany.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchTransportationCompany.rejected, (state, action) => {
        state.loading = "finished";
        state.error = getErrorMessage(action.error);
      })
      .addCase(fetchTransportationCompany.fulfilled, (state, action) => {
        state.loading = "finished";
        state.data = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const selectTransportationCompany = ({ trip }: any) =>
  trip.transportationCompany;

// export const tripActions = tripDispatchSlice.actions;
export default transportationCompanySlice.reducer;
