import Lottie from "lottie-react";
import success from "../../assets/lottie/success.json";
import info from "../../assets/lottie/alert.json";
import error from "../../assets/lottie/error.json";
import { ReactNode } from "react";

enum IconTypes {
  success = "success", // eslint-disable-line @typescript-eslint/no-shadow
  info = "info", // eslint-disable-line @typescript-eslint/no-shadow
  error = "error", // eslint-disable-line @typescript-eslint/no-shadow
}

interface IMessagePromptProps {
  iconType: "success" | "info" | "error";
  children: ReactNode;
}

const MessagePrompt = ({ iconType, children }: IMessagePromptProps) => {
  return (
    <div className="flex-row mx-auto">
      <div className="flex justify-center">
        <div className="w-20">
          {iconType === IconTypes.success && (
            <Lottie
              animationData={success}
              loop={false}
              width={10}
              height={10}
            />
          )}

          {iconType === IconTypes.info && (
            <Lottie animationData={info} loop={false} width={10} height={10} />
          )}

          {iconType === IconTypes.error && (
            <Lottie animationData={error} loop={false} width={10} height={10} />
          )}
        </div>
      </div>

      <div>{children}</div>
    </div>
  );
};

export default MessagePrompt;
