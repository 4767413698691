import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Error from "../../../../assets/lottie/error.json";
import Button from "../../../../components/Button";
import { useLogger } from "../../../../contexts/Logger";

const ErrorScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  //edit trip
  const routeParams = useParams();
  const { tid } = routeParams;
  const { debug } = useLogger();
  if (!tid) {
    debug("PaymentError (Web): ", routeParams);
  }

  return (
    <>
      <div className="my-auto">
        <div className="w-16 mx-auto">
          <Lottie animationData={Error} loop={false} width={32} height={32} />
        </div>

        <h1 className="text-2xl text-text font-extrabold py-4">
          {tid ? t("failed-to-edit-booking") : t("payment-failed")}
        </h1>
        <p className="text-base text-secondary">
          {tid
            ? t("there-was-an-error-editing-your-trip")
            : t("there-was-an-error-booking-your-trip")}
        </p>
      </div>

      <div className="bg-gradient-to-t from-background via-background to-background/50 fixed bottom-0 left-0 right-0 p-6">
        <div>
          <Button
            className="w-80"
            color="primary"
            variant="contained"
            onClick={() => {
              navigate(-1);
            }}>
            {t("try-again")}
          </Button>
        </div>

        <div className="pt-4">
          <Button
            className="w-80"
            color="secondary"
            variant="contained"
            onClick={() => {
              navigate("/");
            }}>
            {t("cancel")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ErrorScreen;
