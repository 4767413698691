class ConfigService {
  private configs: any;

  init = (conf: any) => {
    let stringify = JSON.stringify(conf).replaceAll("REACT_APP_", "");
    this.configs = JSON.parse(stringify);
  };

  get = (key: string) => {
    if (!this.configs) {
      // TODO: This should end up in datadog since dd hooks into console messages but this isn't initalized within the provider.
      console.warn("UNABLE TO FIND .env VARIABLE WHILE FINDING", key);
      return null;
    }
    return this.configs[key] ? this.configs[key] : null;
  };
}

const instance = new ConfigService();
export default instance;
