import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api";
import { getErrorMessage } from "../../utilities/helpers";

export const fetchPaymentCards = createAsyncThunk(
  "account/fetchPaymentCards",
  async (patient_id: string, { rejectWithValue }) => {
    try {
      return await apiService.getPaymentCards(patient_id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deletePaymentCard = createAsyncThunk(
  "account/deletePaymentCard",
  async (stripeCardData: StripeCard, { rejectWithValue }) => {
    try {
      const { patient_id, customer, id } = stripeCardData;
      return await apiService.deletePaymentCard(patient_id, customer, id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

interface StripeCard {
  customer: string;
  id: string;
  patient_id: string;
}

interface SliceState {
  loading: "idle" | "loading" | "finished";
  data: any | null;
  error: string | null;
}

const initialState: SliceState = {
  loading: "idle",
  data: null,
  error: null,
};

const paymentSlice = createSlice({
  name: "account/payment",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPaymentCards.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchPaymentCards.rejected, (state, action) => {
        state.loading = "finished";
        state.error = getErrorMessage(action.error);
      })
      .addCase(fetchPaymentCards.fulfilled, (state, action) => {
        state.loading = "finished";
        state.data = action.payload ? action.payload : null;
      })
      .addCase(deletePaymentCard.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(deletePaymentCard.rejected, (state, action) => {
        state.loading = "finished";
        state.error = getErrorMessage(action.error);
      })
      .addCase(deletePaymentCard.fulfilled, (state, action) => {
        state.loading = "finished";
        state.data = action.payload ? action.payload : null;
      });
  },
});
export const selectPayment = ({ account }: any) => account.payment;
export const paymentActions = paymentSlice.actions;
export default paymentSlice.reducer;
