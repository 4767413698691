import DefaultLogo from "../../assets/hospitals/default.png";
import ACSLogo from "../../assets/hospitals/acs.png";
import HealthFirstLogo from "../../assets/hospitals/health-first-health-plan.png";
import MassAdvantageLogo from "../../assets/hospitals/mass-advantage.png";
import ChesterfieldLogo from "../../assets/hospitals/chesterfield.png";
import enums from "../../constants/enums";

class BenefitService {
  get = (provider: any) => {
    switch (true) {
      case Object.values(enums.HealthFirst).includes(provider):
        return {
          displayName: "Health First Health Plans",
          contactNumber: "+1 800-716-7737",
          tos: "/terms-of-use/",
          logo: HealthFirstLogo,
        };

      case Object.values(enums.MassAdvantage).includes(provider):
        return {
          displayName: "Mass Advantage",
          contactNumber: "+1 844-483-1444",
          tos: "/terms-of-use/",
          logo: MassAdvantageLogo,
        };

      case Object.values(enums.ACS).includes(provider):
        return {
          displayName: "American Cancer Society",
          contactNumber: "+1-888-227-6333",
          tos: "/terms-of-use/acs",
          logo: ACSLogo,
        };

      case Object.values(enums.Chesterfield).includes(provider):
        return {
          displayName: "Chesterfield County",
          contactNumber: "+1 877-396-8080",
          tos: "/terms-of-use/",
          logo: ChesterfieldLogo,
        };

      case Object.values(enums.discount).includes(provider):
        return {
          displayName: Object.values(enums.discount).includes(provider)
            ? provider.toString()
            : "GRTC", //maybe should be something else?
          contactNumber: "+1 877-396-8080", //would this number change for different discount numbers?
          tos: "/terms-of-use/",
          logo: DefaultLogo,
        };

      case Object.values(enums.AllyAlign).includes(provider):
      default:
        return {
          displayName: "AllyAlign Health",
          contactNumber: "+1 877-715-9287",
          tos: "/terms-of-use/",
          logo: DefaultLogo,
        };
    }
  };
}

const instance = new BenefitService();
export default instance;
