import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import theme from "../../../../theme";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    reason: yup.string().required(),
  })
  .required();

interface ICancelModal {
  setModalIsShown: any;
  onConfirm: Function;
  loading: any;
  rid: any;
  tid: any;
  isRoundTrip?: boolean;
  setIsCancelingBoth?: any;
}

const CancelModel = ({
  setModalIsShown,
  onConfirm,
  loading,
  rid,
  tid,
  isRoundTrip,
  setIsCancelingBoth,
}: ICancelModal) => {
  const { t } = useTranslation();
  const [roundTripModalSelected, setRoundTripModalSelected] =
    useState(isRoundTrip);
  const [cancelBothLegs, setCancelBothLegs] = useState(false);

  const {
    control,
    getValues,
    formState: { isValid },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      reason: "",
    },
  });

  const cancelTripHandler = () => {
    const d = getValues();
    onConfirm(d.reason, tid, rid);
  };

  const cancelBothLegsButtonHandler = () => {
    setRoundTripModalSelected(false); //changes state for modal
    setCancelBothLegs(true);
    setIsCancelingBoth(true); //sets state in trip details to true to dispatch to correct api
  };

  //resets states if in roundtrip only and 'go back' button is pressed
  const goBackButtonHandler = () => {
    setRoundTripModalSelected(true);
    setCancelBothLegs(false);
    setIsCancelingBoth(false);
  };

  return (
    <Modal
      onHideModal={() => {
        setModalIsShown(false);
      }}>
      {roundTripModalSelected && (
        <>
          <p className="text-center text-lg text-text font-extrabold pt-2 pb-5">
            {t("cancel-trip")}
          </p>
          <div className="my-4 flex justify-center">
            <Button
              className="grow min-w-[18rem]"
              color="primary"
              variant="contained"
              onClick={cancelBothLegsButtonHandler}>
              {t("cancel-both-legs-of-trip")}
            </Button>
          </div>
          <div className="my-4 flex justify-center">
            <Button
              className="grow min-w-[18rem]"
              color="primary"
              variant="outlined"
              onClick={() => setRoundTripModalSelected(false)}>
              {t("cancel-this-ride")}
            </Button>
          </div>
          <div className="my-4 flex justify-center">
            <Button
              className="grow min-w-[18rem]"
              color="info"
              variant="contained"
              onClick={() => setModalIsShown(false)}>
              {t("nevermind")}
            </Button>
          </div>
        </>
      )}
      {!roundTripModalSelected && (
        <>
          <i className={"icon-info flex justify-center text-8xl -my-4"} />
          <p className="text-center text-lg text-text font-extrabold pt-2">
            {cancelBothLegs ? t("cancel-trip") : t("cancel-ride")}
          </p>
          <p className="text-center text-base text-secondary pt-1">
            {cancelBothLegs
              ? t("please-provide-reason-for-canceling-this-trip")
              : t("please-provide-reason-for-canceling-this-ride")}
          </p>

          <div className="my-6 text-left">
            <Controller
              name="reason"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <ThemeProvider theme={theme}>
                  <FormControl fullWidth>
                    <RadioGroup
                      {...field}
                      onChange={(_event, value) => field.onChange(value)}
                      value={field.value}>
                      <div className="py-2">
                        <FormControlLabel
                          value="no_longer_needed"
                          control={<Radio />}
                          label={
                            <p className="font-base">{t("no-longer-needed")}</p>
                          }
                        />
                      </div>

                      <div className="h-0.5 bg-background -mr-4" />

                      <div className="py-2">
                        <FormControlLabel
                          value="booking_error"
                          control={<Radio />}
                          label={
                            <p className="font-base">{t("booking-error")}</p>
                          }
                        />
                      </div>

                      <div className="h-0.5 bg-background -mr-4" />

                      <div className="py-2">
                        <FormControlLabel
                          value="appointment_changed_or_canceled"
                          control={<Radio />}
                          label={
                            <p className="font-base">
                              {t("appointment-changed-or-canceled")}
                            </p>
                          }
                        />
                      </div>

                      <div className="h-0.5 bg-background -mr-4" />

                      <div className="py-2">
                        <FormControlLabel
                          value="accidental_booking"
                          control={<Radio />}
                          label={
                            <p className="font-base">
                              {t("accidental-booking")}
                            </p>
                          }
                        />
                      </div>

                      <div className="h-0.5 bg-background -mr-4" />

                      <div className="py-2">
                        <FormControlLabel
                          value="duplicate_booking"
                          control={<Radio />}
                          label={
                            <p className="font-base">
                              {t("duplicate-booking")}
                            </p>
                          }
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </ThemeProvider>
              )}
            />
          </div>

          <div className="my-2  flex justify-center">
            {onConfirm && (
              <Button
                className="w-72"
                color="warning"
                loading={loading}
                disabled={!isValid}
                variant="contained"
                onClick={cancelTripHandler}>
                {cancelBothLegs ? t("cancel-trip") : t("cancel-ride")}
              </Button>
            )}
          </div>
          <div className="my-2 flex justify-center">
            <Button
              className="w-72"
              color="info"
              variant="contained"
              onClick={() =>
                isRoundTrip ? goBackButtonHandler() : setModalIsShown(false)
              }>
              {t("go-back")}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default CancelModel;
