import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import account from "./account";
import booking from "./booking";
import trips from "./trips";
import ui from "./ui";
import trip from "./trip";
import { isDev } from "../utilities/isDev";
import { middlewares } from "./middlewares";
import { userApi } from "./account/userSlice";
import auth from "./authSlice";

const store = configureStore({
  reducer: {
    ui,
    account,
    trips,
    booking,
    trip,
    auth,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewares).concat(userApi.middleware),
  devTools: isDev,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

export default store;
