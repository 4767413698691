import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useEffect,
  useCallback,
} from "react";
import { AlertColor } from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import {
  notificationPop,
  selectNotification,
  notificationPush,
} from "../../store/ui/notificationSlice";
import { INotification } from "../../interfaces/Notification";

type NotificationContextData = {
  notify(n: INotification): void;
  unnotify(): void;
  notification: INotification;
  open: boolean;
  title: string;
  message: string;
  typeColour: AlertColor;
};

const NotificationContext = createContext<NotificationContextData>(
  {} as NotificationContextData,
);

const NotificationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  //states that controls the snack bar notification
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [typeColour, setTypeColour] = useState<AlertColor>("info");

  const notification = useSelector(selectNotification);

  const dispatch = useDispatch<AppDispatch>();

  const notify = useCallback(
    (n: INotification) => {
      dispatch(notificationPush(n));
    },
    [dispatch],
  );

  //handle close (pop from array and close notification)
  const unnotify = useCallback(() => {
    dispatch(notificationPop());
    setOpen(false);
    setTypeColour("info");
  }, [dispatch]);

  //this is where we show the notification to mui snackbar (first element in array)
  const showNotificaton = useCallback(
    (n: INotification) => {
      setTitle(n.title);
      setMessage(n.message);
      setTypeColour(n.type ? n.type : "info");
      setOpen(true);
    },
        [unnotify] // eslint-disable-line
  );

  useEffect(() => {
    if (notification) {
      showNotificaton(notification);
    }
  }, [notification, showNotificaton]);

  return (
    <NotificationContext.Provider
      value={{
        open,
        title,
        message,
        typeColour,
        notify,
        unnotify,
        notification,
      }}>
      {children}
    </NotificationContext.Provider>
  );
};

function useNotification(): NotificationContextData {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useAuth must be used within an NotificationProvider");
  }
  return context;
}

export { NotificationContext, NotificationProvider, useNotification };
