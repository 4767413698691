import { ReactComponent as RtHealthLogo } from "../../assets/svg/roundtrip-health-logo.svg";
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";

const Loading = () => {
  return (
    <Container>
      <div className="grid h-screen my-3 place-items-center">
        <div className="w-52">
          <RtHealthLogo />
          <div className="w-12 my-6 mx-auto">
            <Spinner />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Loading;
