import ReactDOM from "react-dom";

interface IModalProps {
  onHideModal: any;
  children: React.ReactNode;
}

const portalElement = document.getElementById("modal") as HTMLDivElement;

//background of overlay
const Backdrop = ({ onHideModal }: any) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-screen z-40 backdrop-blur-sm bg-black/40"
      onClick={onHideModal}
    />
  );
};

//modal overlay itself
const ModalOverlay = ({ children }: any) => {
  return (
    <div className="fixed justify-center bg-white p-4 z-50 rounded-xl shadow-sm top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div>{children}</div>
    </div>
  );
};

//display modal overlay and backdrop
const Modal = ({ onHideModal, children }: IModalProps) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onHideModal={onHideModal} />,
        portalElement,
      )}
      {ReactDOM.createPortal(
        <ModalOverlay>{children}</ModalOverlay>,
        portalElement,
      )}
    </>
  );
};

export default Modal;
