import tripReducer from "./tripSlice";
import tripDispatchReducer from "./tripDispatchSlice";
import transportationCompanyReducer from "./tripTransportationCompanySlice";
import { combineReducers } from "@reduxjs/toolkit";

const reducer = combineReducers({
  trip: tripReducer,
  tripDispatch: tripDispatchReducer,
  transportationCompany: transportationCompanyReducer,
});

const trip = (state: any, action: any) => {
  if (action.type === "RESET") {
    state = undefined;
  }
  return reducer(state, action);
};

export default trip;
