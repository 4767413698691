//import dependencies
import React, { useCallback, useEffect, useState } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone";
import { MuiTelInput } from "mui-tel-input";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Datepicker, locale } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { useTranslation } from "react-i18next";
import "moment/locale/es";

//import components
import Input from "../../../components/Input/InputComponent";
import Button from "../../../components/Button";
import Container from "../../../components/Container";
import Spinner from "../../../components/Spinner";

//theme
import theme from "../../../theme";

//store
import {
  selectPatient,
  updatePatient,
  useUpdateUserMutation,
} from "../../../store/account/userSlice";
import {
  fetchVehicleTypes,
  selectVehicleTypes,
} from "../../../store/booking/transportationSlice";
import { notificationPush } from "../../../store/ui/notificationSlice";

//interfaces
import { IPatientUpdate } from "../../../interfaces/Patient";
import { useNavigate } from "react-router-dom";

import { ReactComponent as DeleteProfileIcon } from "../../../assets/icons/delete-profile.svg";
import { useAuth } from "../../../contexts/Auth";

const schema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    dob: yup
      .date()
      .required()
      .max(new Date())
      .default(() => {
        return new Date();
      }),
    weight: yup
      .number()
      .nullable()
      .notRequired()
      .transform(function (value) {
        if (!value) {
          return null;
        }
        return value;
      }),
    email: yup.string().email().required(),
    phone: yup.string().phone("US").required(), //only VALID US numbers are accepted
    preferences: yup.string().required(),
    language_preference: yup.string().required(),
  })
  .required();

const EditProfileScreen = () => {
  //selecting
  const { data, loading } = useSelector(selectPatient);
  const hospital_id = data.hospital_id;
  const vehicleTypes = useSelector(selectVehicleTypes); //ambulatory or wheelchair
  const { currentUser, loading: userLoading } = useAuth();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const contact_preferences = Object.keys(data.preferences).filter(
    (key) => data.preferences[key],
  );

  useEffect(() => {
    dispatch(fetchVehicleTypes(hospital_id));
  }, [dispatch, hospital_id]);

  const {
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: currentUser.first_name !== null ? currentUser.first_name : "",
      lastName: currentUser.last_name !== null ? currentUser.last_name : "",
      dob: data.date_of_birth !== null ? data.date_of_birth : "",
      weight: data.weight !== null ? data.weight : null,
      email: currentUser.email !== null ? currentUser.email : "",
      phone: currentUser.phone !== null ? currentUser.phone : "",
      preferences: contact_preferences[0],
      language_preference: i18n.language ? i18n.language : "en",
    },
  });

  //states to check if user profile updated successfully
  const [
    updateUser,
    {
      error: updateUserError,
      isLoading: updateUserLoading,
      isSuccess: updateUserSuccess,
    },
  ] = useUpdateUserMutation();
  const [updatePatientSuccess, setUpdatePatientSuccess] = useState(false);

  //state for opening date pickers
  const [isDobOpen, setIsDobOpen] = useState(false);

  //useEffect to check if updated successfully, success notification is displayed
  useEffect(() => {
    if (updateUserSuccess && updatePatientSuccess) {
      dispatch(
        notificationPush({
          title: t("success"),
          message: t("profile-updated-successfully"),
          type: "success",
        }),
      );

      setUpdatePatientSuccess(false);
    }
  }, [updateUserSuccess, updatePatientSuccess, dispatch, t]);

  //when use presses continue button
  const onSubmit = async () => {
    const d = getValues();

    let p: IPatientUpdate = {
      id: data.id,
      first_name: d.firstName,
      last_name: d.lastName,
      date_of_birth: moment(d.dob).format("DD/MM/YYYY"),
      primary_phone: d.phone.replace("+1", ""),
      preferences: {
        sms_notifications: d.preferences === "sms_notifications",
        voice_notifications: d.preferences === "voice_notifications",
      },
      language_preference: d.language_preference,
    };

    if (vehicleTypes.data.includes("wheelchair") && d.weight) {
      p.weight = d.weight;
    }

    if (i18n.language !== d.language_preference) {
      i18n.changeLanguage(d.language_preference);
      moment.locale(i18n.language);
    }

    updateUser({
      id: currentUser.id,
      first_name: d.firstName,
      last_name: d.lastName,
      email: d.email,
      phone: d.phone.replace("+1", ""),
      preferences: {
        email_notifications: d.preferences === "email_notifications",
        sms_notifications: d.preferences === "sms_notifications",
        voice_notifications: d.preferences === "voice_notifications",
      },
    });

    await updatePatientProfile(p);
  };

  const notifyError = useCallback(
    (error: unknown) => {
      console.error("Error updating resource: " + error);
      dispatch(
        notificationPush({
          title: t("error"),
          message: t("error-please-try-again-later"),
          type: "error",
        }),
      );
    },
    [dispatch, t],
  );

  useEffect(() => {
    if (updateUserError) {
      notifyError(updateUserError);
    }
  }, [notifyError, updateUserError]);

  const updatePatientProfile = async (p: IPatientUpdate) => {
    dispatch(updatePatient(p))
      .unwrap()
      .then(() => {
        setUpdatePatientSuccess(true);
      })
      .catch(notifyError);
  };

  if (vehicleTypes.loading !== "finished") {
    return (
      <div className="flex flex-1 items-center">
        <div className="w-12 my-6 mx-auto">
          <Spinner />
        </div>
      </div>
    );
  } else {
    return (
      <Container>
        <div className="pt-6">
          <Card
            elevation={0}
            className="p-6 w-full md:w-3/4 md:border mx-auto text-left">
            <b className="text-base text-text">{t("personal-information")}</b>
            <div className="pt-3">
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <Input
                    error={errors.firstName ? true : false}
                    label={t("first-name")}
                    size="small"
                    {...field}
                  />
                )}
              />
            </div>

            <div className="pt-3">
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <Input
                    error={errors.lastName ? true : false}
                    label={t("last-name")}
                    size="small"
                    {...field}
                  />
                )}
              />
            </div>

            <div className="pt-3">
              <Controller
                name="dob"
                control={control}
                render={({ field }) => (
                  <Datepicker
                    {...field}
                    controls={["date"]}
                    theme="ios"
                    themeVariant="light"
                    isOpen={isDobOpen}
                    onOpen={() => setIsDobOpen(true)}
                    onClose={() => setIsDobOpen(false)}
                    onChange={(e: any) => {
                      field.onChange(moment(e.value).format("YYYY-MM-DD"));
                    }}
                    max={moment().format("YYYY-MM-DD")}
                    min={"1900-01-01"}
                    locale={locale[i18n.language]}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    inputComponent={React.forwardRef((params: any, ref) => {
                      return (
                        <Input
                          onClick={() => setIsDobOpen(true)}
                          label={t("dob")}
                          size="small"
                          sx={{ backgroundColor: "#FFFFFF" }}
                          {...params}
                          fullWidth
                          error={errors.dob ? true : false}
                        />
                      );
                    })}
                    responsive={{
                      xsmall: {
                        controls: ["date"],
                        display: "bottom",
                        touchUi: true,
                      },
                      small: {
                        controls: ["date"],
                        display: "center",
                        touchUi: true,
                      },
                    }}
                  />
                )}
              />
            </div>

            {/* ensure eligible for wheel chair van */}
            {vehicleTypes.data.includes("wheelchair") && (
              <>
                <div className="pt-3">
                  <Controller
                    name="weight"
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={t("weight")}
                        size="small"
                        type={"number"}
                        InputProps={{
                          inputProps: { min: 0 },
                          endAdornment: (
                            <InputAdornment position="end">lbs</InputAdornment>
                          ),
                        }}
                        {...field}
                      />
                    )}
                  />
                </div>

                <div className="flex">
                  <i className="icon-info text-2xl text-text" />
                  <p className="text-xs text-text my-auto">
                    {t("weight-needed-wheelchair")}
                  </p>
                </div>
              </>
            )}
          </Card>
        </div>

        <div className="h-1 bg-background md:hidden" />

        <div className="pt-6">
          <Card
            elevation={0}
            className="p-6 w-full md:w-3/4 md:border mx-auto text-left">
            <b className="text-base text-text">{t("contact-information")}</b>
            <div className="pt-3">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    error={errors.email ? true : false}
                    label={t("e-mail")}
                    size="small"
                    {...field}
                  />
                )}
              />
            </div>

            <div className="pt-6">
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <MuiTelInput
                    className="w-full"
                    label={t("phone-number")}
                    onlyCountries={["US"]}
                    error={errors.phone ? true : false}
                    size="small"
                    defaultCountry="US"
                    sx={{ backgroundColor: "#FFFFFF" }}
                    {...field}
                  />
                )}
              />
            </div>

            <div className="my-6 text-left">
              <Controller
                name="preferences"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <ThemeProvider theme={theme}>
                    <FormControl>
                      <b className="text-base text-text">
                        {t("i-prefer-to-be-contacted-by")}:
                      </b>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        {...field}
                        onChange={(value) => field.onChange(value)}
                        value={field.value}>
                        <FormControlLabel
                          value="sms_notifications"
                          control={<Radio />}
                          label={
                            <p className="font-base">{t("text-messages")}</p>
                          }
                        />
                        <FormControlLabel
                          value="voice_notifications"
                          control={<Radio />}
                          label={<p className="font-base">{t("voice-call")}</p>}
                        />
                      </RadioGroup>
                    </FormControl>
                  </ThemeProvider>
                )}
              />
            </div>
          </Card>
        </div>

        <div className="pt-6">
          <Card
            elevation={0}
            className="p-6 w-full md:w-3/4 md:border mx-auto text-left">
            <b className="text-base text-text">{t("language-preferences")}</b>
            <Controller
              name="language_preference"
              control={control}
              render={({ field }) => (
                <div className="pt-2">
                  <Select fullWidth size={"small"} {...field}>
                    {Object.keys(i18n.services.resourceStore.data).map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {t(option)}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </div>
              )}
            />
            <p className="text-sm text-text pt-2">
              {t("select-your-preferred-language")}
            </p>
          </Card>
        </div>

        <div className="pt-6">
          <Card
            elevation={0}
            className="p-6 w-full md:w-3/4 md:border mx-auto text-left">
            <div
              className="flex hover:text-primary active:text-primary text-text"
              onClick={() => navigate("../delete-account")}>
              <DeleteProfileIcon className="icon-question text-6xl" />
              <div className="flex flex-1 py-3">
                <div className="pl-3 my-auto">
                  <p className="text-base">{t("delete-account")}</p>
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className="py-6">
          <Button
            className="w-80 "
            color="primary"
            variant="contained"
            disabled={
              userLoading ||
              loading === "loading" ||
              !isValid ||
              updateUserLoading
            }
            loading={userLoading || loading === "loading" || updateUserLoading}
            onClick={onSubmit}>
            {t("save")}
          </Button>
        </div>
      </Container>
    );
  }
};

export default EditProfileScreen;
