import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api";
import { ILocation } from "../../interfaces/Location";
import { getErrorMessage } from "../../utilities/helpers";
import { ITripDistanceParams } from "../../interfaces/Trip";

interface SliceState {
  pickup: {
    data: ILocation | null;
    loading: "idle" | "loading" | "finished";
    error: string | null;
  };
  dropoff: {
    data: ILocation | null;
    loading: "idle" | "loading" | "finished";
    error: string | null;
  };
  search: {
    data: Array<any>;
    loading: "idle" | "loading" | "finished";
    error: string | null;
  };
  recent: {
    data: Array<ILocation>;
    loading: "idle" | "loading" | "finished";
    error: string | null;
  };
  distance: {
    data: number | null;
    loading: "idle" | "loading" | "finished";
    error: string | null;
  };
}

const initialState: SliceState = {
  pickup: {
    data: null,
    loading: "idle",
    error: null,
  },
  dropoff: {
    data: null,
    loading: "idle",
    error: null,
  },
  recent: {
    data: [],
    loading: "idle",
    error: null,
  },
  search: {
    data: [],
    loading: "idle",
    error: null,
  },
  distance: {
    data: null,
    loading: "idle",
    error: null,
  },
};

export const validateFetchTripDistancePayload = ({
  pickup,
  dropoff,
}: ITripDistanceParams) =>
  pickup?.latitude &&
  pickup?.longitude &&
  dropoff?.latitude &&
  dropoff?.longitude;

export const fetchTripDistance = createAsyncThunk(
  "booking/locations/fetchTripDistance",
  async (locations: ITripDistanceParams, { rejectWithValue }) => {
    try {
      return await apiService.getTripDistance(locations);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchRecentLocations = createAsyncThunk(
  "booking/locations/fetchRecentLocations",
  async (patient_id: number, { rejectWithValue }) => {
    try {
      return await apiService.getRecentLocations(patient_id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createDropoffLocation = createAsyncThunk(
  "booking/locations/createDropoffLocation",
  async (location: ILocation, { rejectWithValue }) => {
    try {
      return await apiService.createLocation(location);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createPickupLocation = createAsyncThunk(
  "booking/locations/createPickupLocation",
  async (location: ILocation, { rejectWithValue }) => {
    try {
      return await apiService.createLocation(location);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const locationSlice = createSlice({
  name: "booking/location",
  initialState,

  reducers: {
    setDropoffLocation: (state, action: PayloadAction<ILocation>) => {
      state.dropoff.loading = "finished";
      state.dropoff.data = action.payload;
    },
    clearDropoffLocation: (state) => {
      state.dropoff.data = null;
    },
    selectPickupLocation: (state, action: PayloadAction<ILocation>) => {
      state.pickup.loading = "finished";
      state.pickup.data = action.payload;
    },
    clearPickupLocation: (state) => {
      state.pickup.data = null;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(createDropoffLocation.pending, (state) => {
        state.dropoff.loading = "loading";
      })
      .addCase(createDropoffLocation.rejected, (state, action) => {
        state.dropoff.loading = "finished";
        state.dropoff.error = getErrorMessage(action.error);
      })
      .addCase(createDropoffLocation.fulfilled, (state, action) => {
        state.dropoff.loading = "finished";
        state.dropoff.data = action.payload;
      })

      .addCase(createPickupLocation.pending, (state) => {
        state.pickup.loading = "loading";
      })
      .addCase(createPickupLocation.rejected, (state, action) => {
        state.pickup.loading = "finished";
        state.pickup.error = getErrorMessage(action.error);
      })
      .addCase(createPickupLocation.fulfilled, (state, action) => {
        state.pickup.loading = "finished";
        state.pickup.data = action.payload;
      })

      .addCase(fetchRecentLocations.pending, (state) => {
        state.recent.loading = "loading";
      })
      .addCase(fetchRecentLocations.rejected, (state, action) => {
        state.recent.loading = "finished";
        state.recent.error = getErrorMessage(action.error);
      })
      .addCase(fetchRecentLocations.fulfilled, (state, action) => {
        state.recent.loading = "finished";
        state.recent.data = action.payload;
      })

      .addCase(fetchTripDistance.pending, (state) => {
        state.distance.loading = "loading";
      })
      .addCase(fetchTripDistance.rejected, (state, action) => {
        state.distance.loading = "finished";
        state.distance.error = getErrorMessage(action.error);
      })
      .addCase(fetchTripDistance.fulfilled, (state, action) => {
        state.distance.loading = "finished";
        state.distance.data = action.payload;
      });
  },
});

export const selectSearchLocations = ({ booking }: any) =>
  booking.location.search;
export const selectRecentLocations = ({ booking }: any) =>
  booking.location.recent;
export const selectPickupLocations = ({ booking }: any) =>
  booking.location.pickup;
export const selectDropoffLocations = ({ booking }: any) =>
  booking.location.dropoff;
export const selectLocationsDistance = ({ booking }: any) =>
  booking.location.distance;
export const {
  clearDropoffLocation,
  clearPickupLocation,
  setDropoffLocation,
  selectPickupLocation,
} = locationSlice.actions;
export default locationSlice.reducer;
