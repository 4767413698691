import { ReactComponent as StarIcon } from "../../../../assets/icons/star.svg";
import { ReactComponent as CarIcon } from "../../../../assets/icons/car.svg";
import colors from "../../../../constants/colors";
import { IRideDispatch } from "../../../../interfaces/RideDispatch";
import { useTranslation } from "react-i18next";

interface RideDispatchProps {
  data: IRideDispatch;
}

const RideDispatch = ({ data }: RideDispatchProps) => {
  const { t } = useTranslation();
  return (
    <div className="text-left">
      <p className="text-secondary text-sm font-extrabold uppercase">
        {t("driver-details")}
      </p>
      <div className="flex pt-2">
        {data.driver_image_url ? (
          <img className="w-16 h-16" src={data.driver_image_url} alt="driver" />
        ) : (
          <div className="w-auto px-2 py-1 h-16 bg-background border border-background rounded justify-center my-auto">
            <i className="icon-profile text-5xl" />
          </div>
        )}

        <div className="flex flex-1 py-3">
          <div className="text-base pl-3">
            <p className="font-extrabold text-text my-1">{data.driver_name}</p>
            {data.driver_rating && (
              <div className="flex">
                <StarIcon fill={"#EAC465"} width={20} height={20} />
                <p className="text-secondary">{data.driver_rating}</p>
              </div>
            )}
          </div>

          {data.driver_phone_number && (
            <div className="flex my-auto ml-auto items-center text-primary bg-primary/5 rounded-md pr-4">
              <i className="icon-phone text-5xl -mt-1 -mb-1" />
              <a
                href={`tel:${data.driver_phone_number}`}
                className="text-sm font-extrabold uppercase">
                {t("call")}
              </a>
            </div>
          )}
        </div>
      </div>

      <p className="pt-3 text-secondary text-sm font-extrabold uppercase">
        {t("vehicle-details")}
      </p>

      <div className="flex pt-2">
        {data.vehicle_image_url ? (
          <img
            className="w-16 h-16"
            src={data.vehicle_image_url}
            alt="vehicle"
          />
        ) : (
          <div className="w-auto px-2 py-1 h-16 bg-background border border-background rounded justify-center my-auto">
            <CarIcon fill={colors.text} width={48} height={48} />
          </div>
        )}

        <div className="flex flex-1 py-3">
          <div className="pl-3">
            <p className="text-base font-extrabold my-1">
              {data.vehicle_color} {data.vehicle_make} {data.vehicle_model}
            </p>
            <div>
              <p className="text-base text-subtitle">
                {data.vehicle_license_plate}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RideDispatch;
