export enum RideStatus {
  incomplete = "incomplete",
  queued = "queued",
  claimed = "claimed",
  confirmed = "confirmed",
  to_pickup = "to_pickup",
  at_pickup = "at_pickup",
  to_dropoff = "to_dropoff",
  at_dropoff = "at_dropoff",
  completed = "completed",
  canceled = "canceled",
  requested = "requested",
  checking_availability = "checking_availability",
}

export enum RideType {
  one_way = "one_way",
  round_trip = "round_trip",
  repeating_trip = "Repeating Trip",
  return_ride = "Return Ride",
}

export enum RideShare {
  uber = "uber",
  lyft = "lyft",
}

export enum TransportationType {
  ride_share = "ride_share",
  taxi = "taxi",
  medical_sedan = "medical_sedan",
  wheelchair_van = "wheelchair",
}

export enum BenefitsType {
  limited = "limited",
  unlimited = "unlimited",
  credit = "credit",
  discount = "discount",
  flex = "flex card",
}

export enum PayerTypes {
  patient = "patient",
  facility = "facility",
}

export enum HospitalNames {
  acs = "American Cancer Society - Road to Recovery",
  medical_mutual = "Medical Mutual",
  mass_advantage = "Mass Advantage Parent",
  health_first = "Health First - Health Plans",
  paratransit = "Paratransit - Discount", //is this a hospital name?
}

export enum HealthFirst {
  primary = "Health First - Health Plans",
  alt0 = "Health First CLASSIC PLAN",
  alt1 = "Health First VALUE PLAN",
  alt2 = "Health First REWARDS PLAN",
  alt3 = "Health First SECURE PLAN",
  alt4 = "Health First SUNSAVER PLAN",
  alt5 = "Health First",
}

export enum AllyAlign {
  test = "Test Facility 1",
  primary = "Ally Align",
  alt0 = "H9917_003 Align Kidney Care",
  alt1 = "H1277_004 Align Kidney Care",
  alt2 = "H6832_003 Align Kidney Care",
  alt3 = "H3274_004 Align Kidney Care",
  alt4 = "H3419_002 Perennial Advantage Concierge (FLEX)",
  alt5 = "H8797_003 Perennial Advantage Freedom (FLEX)",
}

export enum ACS {
  test = "Test Facility 3",
  primary = "American Cancer Society",
  alt1 = "American Cancer Society - Road To Recovery",
}

export enum MedicalMutual {
  primary = "Medical Mutual",
}
export enum MassAdvantage {
  test = "Test Facility 2",
  primary = "Mass Advantage",
  alt1 = "Mass Advantage Plus (HMO)",
  alt2 = "Mass Advantage Premiere (PPO)",
  alt3 = "Mass Advantage HMO Social",
  alt4 = "Mass Advantage Basic (HMO)",
}
export enum Chesterfield {
  primary = "Chesterfield County",
  alt1 = "Chesterfield - Mobility Services",
  alt2 = "Chesterfield - Social Services",
}
export enum discount {
  primary = "GRTC",
  alt1 = "Paratransit - Discount",
  alt2 = "Chesterfield County",
}

const enums = {
  RideStatus,
  RideType,
  RideShare,
  TransportationType,
  BenefitsType,
  PayerTypes,
  HospitalNames,
  HealthFirst,
  AllyAlign,
  ACS,
  MedicalMutual,
  MassAdvantage,
  discount,
  Chesterfield,
};
export default enums;
