import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { INotification } from "../../interfaces/Notification";

// Define a type for the slice state
interface NotificationState {
  list: INotification[];
  current: INotification | null;
}

const initState: NotificationState = {
  list: [],
  current: null,
};

const notificatioSlice = createSlice({
  name: "ui/notification",
  initialState: initState,
  reducers: {
    notificationPush: (state, action: PayloadAction<INotification>) => {
      if (state.list.length === 0) {
        state.current = action.payload;
      }
      state.list = [...state.list, action.payload];
    },
    notificationPop: (state) => {
      state.list.shift();
      if (state.list.length) {
        state.current = state.list[0];
      } else {
        state.current = null;
      }
    },
  },
});

export const selectNotification = ({ ui }: any) => ui.notification.current;
export const { notificationPop, notificationPush } = notificatioSlice.actions;
export default notificatioSlice.reducer;
