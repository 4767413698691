//dependencies imports
import { Routes, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

//screens imports
import SelectYourBenefits from "./screens/SelectYourBenefits";
import MembershipLookup from "./screens/MembershipLookup";
import PersonalInformation from "./screens/PersonalInformation";
import MemberBenefits from "./screens/MemberBenefits";
import TermsOfService from "./screens/TermsOfService";

//component imports
import ProgressBar from "../../components/ProgressBar";
import OnboardingEmail from "../../components/OnboardingEmail";

//store imports
import { selectPatient } from "../../store/account/userSlice";

const RegisterDetailsScreen = () => {
  const patient = useSelector(selectPatient);
  const location = useLocation();

  return (
    <Routes key={location.pathname} location={location}>
      {patient.data ? (
        <>
          <Route
            path="/*"
            element={
              <>
                <div className="w-full md:w-2/3 lg:w-1/2 px-6 md:px-0 pt-12 mx-auto">
                  <ProgressBar progress={60} variant="determinate" />
                  <OnboardingEmail />
                </div>
                <MemberBenefits />
              </>
            }
          />

          <Route
            path="/information"
            element={
              <>
                <div className="w-full md:w-2/3 lg:w-1/2 px-6 md:px-0 pt-12 mx-auto">
                  <ProgressBar progress={80} variant="determinate" />
                  <OnboardingEmail />
                </div>
                <PersonalInformation />
              </>
            }
          />

          <Route
            path="/tos"
            element={
              <>
                <div className="w-full md:w-2/3 lg:w-1/2 px-6 md:px-0 pt-12 mx-auto">
                  <ProgressBar progress={100} variant="determinate" />
                  <OnboardingEmail />
                </div>
                <TermsOfService />
              </>
            }
          />
        </>
      ) : (
        <>
          <Route
            path="/"
            element={
              <>
                <div className="w-full md:w-2/3 lg:w-1/2 px-6 md:px-0 pt-12 mx-auto">
                  <ProgressBar progress={20} variant="determinate" />
                </div>
                <OnboardingEmail />
                <SelectYourBenefits />
              </>
            }
          />

          <Route
            path="/register"
            element={
              <>
                <div className="w-full md:w-2/3 lg:w-1/2 px-6 md:px-0 pt-12 mx-auto">
                  <ProgressBar progress={40} variant="determinate" />
                  <OnboardingEmail />
                </div>
                <MembershipLookup />
              </>
            }
          />
        </>
      )}
    </Routes>
  );
};

export default RegisterDetailsScreen;
