//import dependencies
import { Menu, MenuItem } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

//import components
import Button from "../../components/Button";
import Slider from "../../components/Slider";
import Spinner from "../../components/Spinner";

//import svgs
import { ReactComponent as RtHealthLogo } from "../../assets/svg/roundtrip-health-logo.svg";
import { ReactComponent as LangGlobe } from "../../assets/svg/globe-svg.svg";

//import constants
import colors from "../../constants/colors";
import { useAuth } from "../../contexts/Auth";

const LandingScreen = () => {
  const { signIn, signUp } = useAuth();

  const { t, i18n } = useTranslation();

  const [onboardingIntro, setOnboardingIntro] = useState([{}]);

  //language changing states & handlers
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const location = useLocation();

  const handleLanguageChange = (e: any) => {
    i18n.changeLanguage(e);
    if (open) {
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    setOnboardingIntro([
      {
        imageUri: require("../../assets/images/landing-0.png"),
        heading: t("easily-book-trips"),
        description: t("to-get-to-the-care-you-need"),
        key: "1",
      },
      {
        imageUri: require("../../assets/images/landing-1.png"),
        heading: t("real-time-updates"),
        description: t("on-your-healthcare-transportation"),
        key: "2",
      },
      {
        imageUri: require("../../assets/images/landing-2.png"),
        heading: t("get-reminders"),
        description: t("about-your-upcoming-trips"),
        key: "3",
      },
    ]);
  }, [i18n.language, t]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const hasAccessDeniedError = searchParams
      .toString()
      .includes("error=access_denied");
    if (hasAccessDeniedError) {
      navigate("/access_denied");
    }
  }, [navigate, location.search]);

  return (
    <div className="flex flex-col flex-1 w-full justify-center justify-around">
      <div className="flex flex-row">
        <div className="flex mx-auto">
          <div className="w-52">
            <RtHealthLogo />
          </div>
        </div>

        <div className="absolute top-2 right-0">
          <Button
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              setAnchorEl(event.currentTarget)
            }>
            <LangGlobe width={20} height={20} fill={colors.secondary} />
          </Button>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}>
            {Object.keys(i18n.services.resourceStore.data).map((option) => (
              <MenuItem
                key={option}
                value={option}
                onClick={() => handleLanguageChange(option)}>
                {t(option)}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>

      <div className="w-full">
        {onboardingIntro.length > 0 ? (
          <Slider data={onboardingIntro} />
        ) : (
          <Spinner />
        )}
      </div>

      <div className="w-full">
        <div className="my-2">
          <Button
            className="w-80"
            color="primary"
            variant="contained"
            onClick={signIn}>
            {t("sign-in")}
          </Button>
        </div>

        <div className="my-2 pt-2">
          <Button
            className="w-80"
            color="secondary"
            variant="contained"
            onClick={signUp}>
            {t("create-account")}
          </Button>
        </div>

        <p className="max-w-80 mx-auto text-sm mt-6 text-secondary">
          <Trans i18nKey={"user-agreement"}>
            <Link to="/terms-of-use" target="_blank" className="underline" />
            <a
              href="https://roundtriphealth.com/roundtrip-legal-documents/"
              rel="noreferrer"
              target="_blank"
              className="underline">
              Privacy Policy
            </a>
          </Trans>
        </p>
      </div>
    </div>
  );
};

export default LandingScreen;
