import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEng from "./languages/en.json";
import translationEsp from "./languages/es.json";

export type languages = "en" | "es";

i18n.use(LanguageDetector).init({
  //we init with resources
  resources: {
    en: {
      translations: translationEng,
    },
    es: {
      translations: translationEsp,
    },
  },
  fallbackLng: "en",

  //have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, //we use content as keys

  interpolation: {
    escapeValue: false, //not needed for react
    formatSeparator: ",",
  },
});

export default i18n;
