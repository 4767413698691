import { forwardRef, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { NotificationContext } from "../../contexts/Notification";
import colors from "../../constants/colors";
import { ReactComponent as SuccessIcon } from "../../assets/icons/success.svg";
import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-filled.svg";

const Alert = forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
  },
);

const Notification = () => {
  const notificationCtx = useContext(NotificationContext);

  const iconType = () => {
    if (notificationCtx.typeColour === "success") {
      return <SuccessIcon fill={colors.positive} width={40} height={40} />;
    } else if (notificationCtx.typeColour === "error") {
      return <ErrorIcon fill={colors.negative} width={40} height={40} />;
    }
    return <InfoIcon fill={colors.primary} width={40} height={40} />;
  };

  const borderColour = () => {
    if (notificationCtx.typeColour === "success") {
      return colors.positive;
    } else if (notificationCtx.typeColour === "error") {
      return colors.negative;
    }
    return colors.primary;
  };

  return (
    <Snackbar
      open={notificationCtx.open}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={notificationCtx.unnotify}>
      <Alert
        icon={iconType()}
        onClose={notificationCtx.unnotify}
        severity={notificationCtx.typeColour}
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderLeftWidth: "6px",
          borderColor: borderColour(),
          "& .MuiAlert-icon": { alignItems: "center" },
        }}>
        <p className="text-lg font-extrabold text-text">
          {notificationCtx.title}
        </p>
        <p className="text-text">{notificationCtx.message}</p>
      </Alert>
    </Snackbar>
  );
};

export default Notification;
