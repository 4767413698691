import { useTranslation } from "react-i18next";

interface IExpiredUIProps {
  exp_month: number;
  exp_year: number;
}

const ExpiredUI = ({ exp_month, exp_year }: IExpiredUIProps) => {
  const { t } = useTranslation();

  return (
    <div className="bg-surface flex rounded-b-md px-4 text-secondary">
      <i className="icon-info text-2xl" />
      <p className="my-auto text-base capitalize">
        {t("expired")} {exp_month}/{exp_year}
      </p>
    </div>
  );
};

export default ExpiredUI;
