import { ThemeProvider, FormControl, RadioGroup } from "@mui/material";
import theme from "../../../../../theme";
import TripTypeItem from "./TripTypeItem";
import { RideType } from "../../../../../constants/enums";
import { useTranslation } from "react-i18next";
import React from "react";

interface ITripType {
  onChange: (e: any) => void;
  tripTypeValue: string;
}

const TripType = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ onChange, tripTypeValue }: ITripType, ref) => {
    const { t } = useTranslation();
    return (
      <ThemeProvider theme={theme}>
        <FormControl>
          <RadioGroup className="pt-4" value={tripTypeValue}>
            <div className="flex min-w-96 max-w-full justify-between bg-white rounded-xl shadow-lg">
              <div className="p-1 grow">
                <TripTypeItem
                  selected={tripTypeValue === RideType.one_way}
                  label={t("one-way")}
                  onClick={() => {
                    onChange(RideType.one_way);
                  }}
                />
              </div>

              <div className="p-1 grow">
                <TripTypeItem
                  selected={tripTypeValue === RideType.round_trip}
                  label={t("round-trip")}
                  onClick={() => {
                    onChange(RideType.round_trip);
                  }}
                />
              </div>
            </div>
          </RadioGroup>
        </FormControl>
      </ThemeProvider>
    );
  },
);

export default TripType;
