import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

//import components
import StatusTag from "../StatusTag";
import TagIcon from "../TagIcon";
import TripItemIcon from "../TripItemIcon";
import DateTimeFormat from "../DateTimeFormat";

//import svgs
import { ReactComponent as SquareIcon } from "../../assets/icons/square-filled.svg";
import { ReactComponent as PolygonIcon } from "../../assets/icons/polygon-filled.svg";
import { ReactComponent as CircleIcon } from "../../assets/icons/circle-filled.svg";

//import constants
import enums from "../../constants/enums";

//import interfaces
import { Ride } from "../../interfaces/Ride";
import { ILocation } from "../../interfaces/Location";
import { ITripEstimateDistanceParams, Trip } from "../../interfaces/Trip";

//import store
import {
  fetchTripEstimateDistance,
  selectTripEstimateDistance,
} from "../../store/booking/estimateSlice";
import { AppDispatch } from "../../store";
import { Skeleton } from "@mui/material";

interface IRideItemProps {
  ride: Ride;
  pickup: ILocation;
  dropoff: ILocation;
  trip: Trip;
  collapsed?: boolean;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.6rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  paddingRight: 0,
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingTop: 0,
  paddingLeft: theme.spacing(4),
}));

const RideItem = ({
  ride,
  pickup,
  dropoff,
  trip,
  collapsed,
}: IRideItemProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const tripEstimate = useSelector(selectTripEstimateDistance);
  const { t } = useTranslation();

  useEffect(() => {
    if (ride && !ride.end_time) {
      const distanceRequest: ITripEstimateDistanceParams = {
        start_time: ride.start_time,
        origin: pickup,
        destination: dropoff,
      };
      dispatch(fetchTripEstimateDistance(distanceRequest));
    }
  }, [ride, pickup, dropoff, dispatch]);

  if (collapsed) {
    return (
      <div className="bg-surface rounded shadow-md p-4">
        <div className="flex justify-between">
          <div className="text-left">
            <div className="flex items-start">
              <div>
                <StatusTag status="return_ride" />
              </div>
              <div className="w-auto flex pl-1.5">
                <StatusTag status={ride.current_status} />
              </div>
            </div>
            <DateTimeFormat
              styling={"font-semibold my-1"}
              timestamp={ride?.start_time}
              variant={"date"}
            />
            <DateTimeFormat
              styling={"text-base text-subtitle"}
              timestamp={ride?.start_time}
              variant={"time"}
            />
          </div>
          <div className="my-auto">
            <div
              className="flex text-primary font-semibold cursor-pointer"
              onClick={() => navigate(`/trip-details/${trip.id}/${ride.id}`)}>
              <p className="text-base uppercase">{t("view")}</p>
              <i className="icon-chev-right text-lg" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const getRideStatus = () => {
    if (ride.current_status === enums.RideStatus.queued) {
      if (trip.transportation_type === enums.TransportationType.ride_share) {
        return enums.RideStatus.requested;
      } else {
        return enums.RideStatus.checking_availability;
      }
    } else {
      return ride.current_status;
    }
  };

  return (
    <>
      <div className="flex items-start justify-between">
        <div className="text-left">
          {ride?.current_status && (
            <div className="w-auto flex">
              <StatusTag status={getRideStatus()} />
            </div>
          )}
          <DateTimeFormat
            styling={"font-semibold my-1"}
            timestamp={ride?.start_time}
            variant={"date"}
          />
          <div className="flex -mx-1.5 -my-1.5">
            <TagIcon type={trip.trip_type} />
            <p className="text-subtitle text-md capitalize">
              {t(trip.trip_type)} -{" "}
              {trip.vehicle_need_type ===
              enums.TransportationType.wheelchair_van
                ? t("wheelchair_van")
                : trip.transportation_type ===
                  enums.TransportationType.ride_share
                ? t(enums.TransportationType.ride_share)
                : t(trip.transportation_type)}
            </p>
          </div>
        </div>
        <div className="my-auto">
          {trip.transportation_type !== null &&
          trip.vehicle_need_type === enums.TransportationType.wheelchair_van ? (
            <TripItemIcon type={trip.vehicle_need_type} />
          ) : (
            <TripItemIcon type={trip.transportation_type} />
          )}
        </div>
      </div>

      <div className="h-0.5 bg-background my-4 -m-4" />

      <div className="flex">
        <div className="flex-initial flex-col justify-between mt-4 mb-1  justify-center">
          <SquareIcon className="fill-primary" />
          <div className="flex h-full">
            <div className="px-2 mt-1 mb-11">
              <div className="self-stretch border-r-2 border-primary border-dashed h-full" />
            </div>
          </div>
          {ride?.current_status === enums.RideStatus.completed ? (
            <CircleIcon className="-mt-11 fill-primary" />
          ) : (
            <PolygonIcon className="-mt-11 fill-inactive" />
          )}
        </div>

        <div className="flex flex-1 flex-col">
          <Accordion>
            <AccordionSummary
              aria-controls="panel1a-content"
              id={`pickup-address-${ride?.id}`}>
              <div className="flex flex-1 items-center">
                <p className="text-left text-base text-text font-extrabold">
                  {pickup.name}
                </p>
              </div>
              <div>
                {/* in event for review book and asap, show current time + 3 mins */}
                {ride ? (
                  <DateTimeFormat
                    styling={
                      "text-right text-base text-text font-extrabold whitespace-nowrap"
                    }
                    timestamp={ride?.start_time}
                    variant={"time"}
                  />
                ) : (
                  <DateTimeFormat
                    styling={
                      "text-right text-base text-text font-extrabold whitespace-nowrap"
                    }
                    timestamp={moment()
                      .add(moment.duration(3, "minutes"))
                      .toISOString()}
                    variant={"time"}
                  />
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="text-left">
                <p className="text-base text-secondary">
                  {pickup.street_address}
                </p>
                <p className="text-base text-secondary">
                  {pickup.city}, {pickup.state}, {pickup.zipcode}
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
          <div className="h-0.5 bg-background -mr-4 ml-5 my-1" />
          <Accordion>
            <AccordionSummary
              aria-controls="panel1a-content"
              id={`dropoff-address-${ride?.id}`}>
              <div className="flex flex-1 items-center">
                <p className="text-left text-base text-secondary">
                  {dropoff.name}
                </p>
              </div>
              <div>
                {ride?.end_time ? (
                  <DateTimeFormat
                    styling={
                      "text-right text-base text-secondary whitespace-nowrap"
                    }
                    timestamp={ride?.end_time}
                    variant={"time"}
                  />
                ) : tripEstimate.data?.duration ? (
                  tripEstimate.loading !== "finished" ? (
                    <div className="flex justify-between px-2 py-2">
                      <Skeleton variant="rounded" width={44} height={6} />
                    </div>
                  ) : (
                    <DateTimeFormat
                      styling={
                        "text-right text-base text-secondary whitespace-nowrap"
                      }
                      timestamp={moment(ride?.start_time)
                        .add(tripEstimate.data.duration, "seconds")
                        .toISOString()}
                      variant={"time"}
                    />
                  )
                ) : (
                  ""
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="text-left">
                <p className="text-base text-secondary">
                  {dropoff.street_address}
                </p>
                <p className="text-base text-secondary">
                  {dropoff.city}, {dropoff.state}, {dropoff.zipcode}
                </p>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default RideItem;
