import alert from "../../assets/lottie/alert.json";
import Lottie from "lottie-react";
import Container from "../../components/Container";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";

const ErrorScreen = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <div className="flex my-auto justify-center">
        <div className="w-full md:w-1/3 lg:w-1/5">
          <div className="my-6 text-center">
            <div className="w-24 mx-auto">
              <Lottie
                animationData={alert}
                loop={false}
                width={32}
                height={32}
              />
            </div>

            <h2 className="text-2xl font-extrabold text-text">{t("oooops")}</h2>

            <p className="text-base text-secondary m-2">
              {t("something-went-wrong")}
            </p>
            <p className="text-secondary mt-3">{t("team-member-notified")}</p>

            <div className={"mt-4 w-80"}>
              <Button
                className="w-72"
                color="primary"
                variant="contained"
                onClick={onClick}>
                {t("refresh")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ErrorScreen;
