import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IEditTrip } from "../../interfaces/Trip";
import apiService from "../../services/api";
import { getErrorMessage } from "../../utilities/helpers";

interface SliceState {
  trip: {
    data: any | null;
    loading: "idle" | "loading" | "finished";
    error: string | null;
  };
  details: {
    data: any | null;
    loading: "idle" | "loading" | "finished";
    error: string | null;
  };
}

const initialState: SliceState = {
  trip: {
    data: null,
    loading: "idle",
    error: null,
  },
  details: {
    data: null,
    loading: "idle",
    error: null,
  },
};

export const fetchTrip = createAsyncThunk(
  "booking/edit/fetchTrip",
  async (trip_id: string, { rejectWithValue }) => {
    try {
      const trip = await apiService.getTrip(trip_id);
      return trip;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchTripDetails = createAsyncThunk(
  "booking/edit/fetchTripDetails",
  async (trip_id: string, { rejectWithValue }) => {
    try {
      const trip = await apiService.getTripDetails(trip_id);
      return trip;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateTrip = createAsyncThunk(
  "booking/edit/updateTrip",
  async (e: IEditTrip, { rejectWithValue }) => {
    try {
      return await apiService.updateTrip(e.trip_id, e.trip_intent_id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const tripEditSlice = createSlice({
  name: "booking/edit",
  initialState: initialState,
  reducers: {
    resetEditTrip: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTrip.pending, (state) => {
        state.trip.loading = "loading";
      })
      .addCase(fetchTrip.rejected, (state, action) => {
        state.trip.loading = "finished";
        state.trip.error = getErrorMessage(action.error);
      })
      .addCase(fetchTrip.fulfilled, (state, action) => {
        state.trip.loading = "finished";
        state.trip.data = action.payload;
      })

      .addCase(fetchTripDetails.pending, (state) => {
        state.details.loading = "loading";
      })
      .addCase(fetchTripDetails.rejected, (state, action) => {
        state.details.loading = "finished";
        state.details.error = getErrorMessage(action.error);
      })
      .addCase(fetchTripDetails.fulfilled, (state, action) => {
        state.details.loading = "finished";
        state.details.data = action.payload;
      })

      .addCase(updateTrip.pending, (state) => {
        state.details.loading = "loading";
      })
      .addCase(updateTrip.rejected, (state, action) => {
        state.details.loading = "finished";
        state.details.error = getErrorMessage(action.error);
      })
      .addCase(updateTrip.fulfilled, (state, action) => {
        state.details.loading = "finished";
        state.details.data = action.payload;
      });
  },
});

export const selectEditTrip = ({ booking }: any) => booking.edit.trip;
export const selectEditTripDetails = ({ booking }: any) => booking.edit.details;
export const { resetEditTrip } = tripEditSlice.actions;
export default tripEditSlice.reducer;
