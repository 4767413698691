import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface ITimeItemProps {
  onClick: any;
  selected: boolean;
  disabled?: boolean;
  error?: boolean;
}

const TimeItem = ({ onClick, selected, disabled, error }: ITimeItemProps) => {
  const { t } = useTranslation();

  const highlightedCard = () => {
    if (selected) {
      return "border-primary";
    }
    return;
  };

  const outlineCard = () => {
    if (error) {
      return "border-error";
    } else if (!selected) {
      return "border-surface";
    }
    return;
  };

  return (
    <div
      className={
        disabled
          ? "cursor-not-allowed h-full border-2 border-gray-300 bg-gray-300 py-2 px-2 md:py-4 md:px-4 rounded"
          : clsx([
              `${highlightedCard()} ${outlineCard()} bg-background cursor-pointer h-full border-2  py-2 px-2 md:py-4 md:px-4 rounded transition-all`,
            ])
      }
      onClick={disabled ? undefined : onClick}>
      <div className="h-full grid place-items-center">
        <i
          className={clsx([
            `${disabled ? "text-gray-500" : ""} icon-zap text-4xl`,
          ])}
        />
        <p
          className={clsx([
            `${
              disabled ? "text-gray-500" : "text-text"
            } font-extrabold font-lg`,
          ])}>
          {t("asap")}
        </p>
      </div>
    </div>
  );
};

export default TimeItem;
