//dependencies imports
import { useState, useEffect, useCallback } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import { useLoadScript } from "@react-google-maps/api";
import type { LoadScriptProps } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/es";

//screens imports
import LandingScreen from "./screens/general/LandingScreen";
import Loading from "./screens/general/LoadingScreen";
import ErrorScreen from "./screens/general/ErrorScreen";
import RegisterDetailsScreen from "./screens/onboarding/RegisterDetailsScreen";
import DashboardScreen from "./screens/home/DashboardScreen";
import ProfileScreen from "./screens/profile/ProfileScreen";
import TripDetailsScreen from "./screens/home/TripDetailsScreen";
import TripBookingScreen from "./screens/booking/TripBookingScreen";
import TermsOfUseScreen from "./screens/general/TermsOfUseScreen";

//component imports
import NavigationBar from "./components/Navigation";

import pendoService from "./services/pendo";
import fullStoryService from "./services/fullstory";
import configService from "./services/config";

//store imports
import { fetchPatient, selectPatient } from "./store/account/userSlice";
import { fetchHospital, selectHospital } from "./store/account/hospitalSlice";
import InactiveUserScreen from "./screens/general/InactiveUserScreen";

import { useAuth } from "./contexts/Auth";
import { isDev } from "./utilities/isDev";
import { notificationPush } from "./store/ui/notificationSlice";
import { fetchPayer } from "./store/account/payerSlice";
import { fetchBenefits } from "./store/account/benefitsSlice";
import { AppDispatch } from "./store";
import { useLogger } from "./contexts/Logger";

const libraries: LoadScriptProps["libraries"] = ["places"];

const App = () => {
  const {
    currentUser,
    isAuthenticated,
    loading: authLoading,
    signOut,
    authError,
    isAuth0Authenticated,
  } = useAuth();
  const { debug } = useLogger();

  debug("App - Auth is loading:", authLoading);

  const dispatch = useDispatch<AppDispatch>();
  const patient = useSelector(selectPatient);
  const hospital = useSelector(selectHospital);
  const { i18n } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(authError);
  //check initial render for setting user language
  const [initialRender, setInitialRender] = useState(false);

  useLoadScript({
    googleMapsApiKey: configService.get("MAPS_API_KEY"),
    libraries,
  });

  // Init Pendo and Fullstory
  useEffect(() => {
    if (isDev) {
      return;
    }

    if (patient.loading === "finished" && hospital.loading === "finished") {
      try {
        pendoService.initPendo(currentUser, patient, hospital);
        fullStoryService.initFullStory();
      } catch (e) {
        console.error(e);
      }
    }
  }, [currentUser, patient, hospital]);

  const notifyError = useCallback((e: Error | undefined) => {
    console.error(e);
    setError(e?.message || e);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (
      patient.loading === "finished" &&
      hospital.loading === "finished" &&
      !initialRender
    ) {
      //we only want to update language on log in, if the user has accepted tos
      //as we do not want to apply the back end default of English, after the
      //user has inputted their members id
      if (patient?.data?.language_preference && currentUser.tos_accepted) {
        i18n.changeLanguage(patient.data.language_preference);
        moment.locale(i18n.language);
        setInitialRender(true);
      }
    }
  }, [currentUser, patient, hospital, i18n, initialRender]);

  useEffect(() => {
    if (authLoading) {
      return setLoading(true);
    }

    setLoading(false);
  }, [authLoading]);

  useEffect(() => {
    if (isAuthenticated && patient.loading === "idle") {
      debug("App - Dispatching fetch patient");
      setLoading(true);

      dispatch(fetchPatient(`${currentUser.id}`))
        .unwrap()
        .then(() => setLoading(false))
        .catch(notifyError);
    }
  }, [currentUser, patient, dispatch, notifyError, isAuthenticated, debug]);

  useEffect(() => {
    if (patient.data?.id) {
      setLoading(true);
      debug("App - Dispatching Fetch All");
      // Do not finish 'loading' until all data has come back from the API server
      Promise.all([
        dispatch(fetchPayer(patient.data.hospital_id)).unwrap(),
        dispatch(fetchHospital(patient.data.hospital_id)).unwrap(),
        dispatch(fetchBenefits(patient.data.id)).unwrap(),
      ])
        .then(() => setLoading(false))
        .catch(notifyError);
    }
  }, [
    patient.data?.id,
    patient.data?.hospital_id,
    dispatch,
    notifyError,
    debug,
  ]);

  /**
   * Ensure authentication errors result in loading the error page
   */
  useEffect(() => setError(authError), [authError]);

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col text-lg">
        <Loading />
      </div>
    );
  }

  if (error) {
    console.warn(error);

    if (isDev) {
      dispatch(
        notificationPush({
          title: "Development Auth Error",
          message: "Please check console",
        }),
      );
    }

    return (
      <div className="text-center">
        <div className="min-h-screen flex flex-col text-lg">
          <>
            <NavigationBar />
            <ErrorScreen
              onClick={() => {
                setError(undefined);
                signOut();
              }}
            />
          </>
        </div>
      </div>
    );
  }

  if (!isAuth0Authenticated) {
    return (
      <div className="text-center">
        <div className="min-h-screen flex flex-col text-lg">
          <Routes>
            <Route path="/*" element={<LandingScreen />} />
            <Route path="/terms-of-use/" element={<TermsOfUseScreen />} />
            <Route
              path="/terms-of-use/:provider"
              element={<TermsOfUseScreen />}
            />
            <Route
              path="/error"
              element={
                <>
                  <NavigationBar />
                  <ErrorScreen onClick={signOut} />
                </>
              }
            />
            <Route path="/access_denied" element={<InactiveUserScreen />} />
          </Routes>
        </div>
      </div>
    );
  }

  return (
    <div className="text-center">
      <div className="min-h-screen flex flex-col text-lg bg-background">
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/terms-of-use/" element={<TermsOfUseScreen />} />
            <Route
              path="/terms-of-use/:provider"
              element={<TermsOfUseScreen />}
            />
            <Route
              path="/error"
              element={
                <>
                  <NavigationBar />
                  <ErrorScreen onClick={signOut} />
                </>
              }
            />
            <Route path="/access_denied" element={<InactiveUserScreen />} />
            {!loading ? (
              <>
                {/* if the user is authenticated but has not completed the onboarding process */}
                {(!currentUser.tos_accepted || !patient.data) && (
                  <Route
                    path="/*"
                    element={
                      <>
                        <NavigationBar onboarding />
                        <RegisterDetailsScreen />
                      </>
                    }
                  />
                )}

                {/* checks if user has completed onboarding */}
                {currentUser.tos_accepted && (
                  <>
                    <Route
                      path="/*"
                      element={
                        <>
                          <NavigationBar />
                          <DashboardScreen />
                        </>
                      }
                    />

                    <Route
                      path="/trip-details/:tid/:rid"
                      element={
                        <>
                          <NavigationBar />
                          <TripDetailsScreen />
                        </>
                      }
                    />
                    {/* Checks if the patient is do-not-book */}
                    {!patient.do_not_book && (
                      <Route
                        path="/book-trip/*"
                        element={
                          <>
                            <NavigationBar />
                            <TripBookingScreen />
                          </>
                        }
                      />
                    )}
                    <Route
                      path="/profile/*"
                      element={
                        <>
                          <NavigationBar />
                          <ProfileScreen />
                        </>
                      }
                    />
                  </>
                )}
              </>
            ) : (
              <Route path="/*" element={<Loading />} />
            )}
          </Routes>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default App;
