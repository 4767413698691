import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITripEstimateDistanceParams } from "../../interfaces/Trip";
import apiService from "../../services/api";
import { getErrorMessage } from "../../utilities/helpers";

interface ITripEstimateFare {
  direction: string;
  discounted_fare: number;
  estimated_fare: number;
}

interface ITripEstimateDistance {
  distance: number;
  duration: number;
}

interface SliceState {
  fare: {
    data: Array<ITripEstimateFare>;
    loading: "idle" | "loading" | "finished";
    error: String | null;
  };
  distance: {
    data: ITripEstimateDistance | null;
    loading: "idle" | "loading" | "finished";
    error: String | null;
  };
}

const initialState: SliceState = {
  fare: {
    data: [],
    loading: "idle",
    error: null,
  },
  distance: {
    data: null,
    loading: "idle",
    error: null,
  },
};

export const validateFetchTripEstimateDistancePayload = ({
  start_time,
  origin,
  destination,
}: ITripEstimateDistanceParams) =>
  origin?.latitude &&
  origin?.longitude &&
  destination?.latitude &&
  destination?.longitude &&
  start_time;

export const fetchTripEstimateFare = createAsyncThunk(
  "booking/estimates/fetchTripEstimateFare",
  async (trip_intent_id: string, { rejectWithValue }) => {
    try {
      return await apiService.createTripEstimateFare(trip_intent_id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchTripEstimateDistance = createAsyncThunk(
  "booking/estimates/fetchTripEstimateDistance",
  async (
    distance_request: ITripEstimateDistanceParams,
    { rejectWithValue },
  ) => {
    try {
      if (validateFetchTripEstimateDistancePayload(distance_request)) {
        return await apiService.createTripEstimateDistance(distance_request);
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const estimateSlice = createSlice({
  name: "booking/estimates",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTripEstimateFare.pending, (state) => {
        state.fare.loading = "loading";
      })
      .addCase(fetchTripEstimateFare.rejected, (state, action) => {
        state.fare.loading = "finished";
        state.fare.error = getErrorMessage(action.error);
      })
      .addCase(fetchTripEstimateFare.fulfilled, (state, action) => {
        state.fare.loading = "finished";
        state.fare.data = action.payload;
      })

      .addCase(fetchTripEstimateDistance.pending, (state) => {
        state.distance.loading = "loading";
      })
      .addCase(fetchTripEstimateDistance.rejected, (state, action) => {
        state.distance.loading = "finished";
        state.distance.error = getErrorMessage(action.error);
      })
      .addCase(fetchTripEstimateDistance.fulfilled, (state, action) => {
        state.distance.loading = "finished";
        state.distance.data = action.payload;
      });
  },
});

export const selectTripEstimateFare = ({ booking }: any) =>
  booking.estimate.fare;
export const selectTripEstimateDistance = ({ booking }: any) =>
  booking.estimate.distance;
export default estimateSlice.reducer;
