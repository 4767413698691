import { ITransportationCompany } from "../../../../interfaces/TransportationCompany";
import { useTranslation } from "react-i18next";

interface ITransportationCompanyProps {
  data: ITransportationCompany;
}

const TransportationCompany = ({ data }: ITransportationCompanyProps) => {
  const { t } = useTranslation();
  return (
    <div className="text-left">
      <p className="text-secondary text-sm font-extrabold">
        {t("transportation-details")}
      </p>
      <div className="flex pt-2">
        <div className="w-auto px-2 py-1 h-16 bg-background border border-background rounded justify-center my-auto">
          <i className="icon-car text-5xl" />
        </div>

        <div className="flex flex-1 py-3">
          <div className="text-base pl-3">
            <p className="font-extrabold text-text my-1">
              {data?.name_display}
            </p>
            <p className="text-text my-1">{t("transportation-company")}</p>
          </div>

          {/* {data.driver_phone_number &&
                        <div className="flex my-auto ml-auto items-center text-primary bg-primary/5 rounded-md pr-4">
                            <i className='icon-phone text-5xl -mt-1 -mb-1' />
                            <a href={`tel:${data.driver_phone_number}`} className='text-sm font-extrabold'>CALL</a>
                        </div>
                    } */}
        </div>
      </div>
    </div>
  );
};

export default TransportationCompany;
