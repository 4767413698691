import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import Card from "../Card";
import Container from "../Container";

const OnboardingEmail = () => {
  const { user, isLoading } = useAuth0();
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Container>
        <div className="pt-8 w-full mx-auto md:w-80">
          <Card>
            <div className="flex justify-between">
              <div className="pr-4">
                <Skeleton variant="rounded" width={40} height={40} />
              </div>
              <div className="flex-1">
                <Skeleton variant="text" width={"50%"} height={20} />
                <Skeleton variant="text" width={"70%"} height={20} />
              </div>
            </div>
          </Card>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div className="pt-8 w-full mx-auto md:w-80">
        <Card>
          <div className="flex">
            <Avatar
              alt={user?.name}
              src={user?.picture}
              className=""
              sx={{ width: 46, height: 46 }}
            />
            <div className="text-text text-sm text-left my-auto pl-4">
              <p>
                <strong>{t("onboarding-for")}</strong>
              </p>
              <p className="pt-1">{user?.email}</p>
            </div>
          </div>
        </Card>
      </div>
    </Container>
  );
};

export default OnboardingEmail;
