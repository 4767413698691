//import dependencies
import { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//import components
import Card from "../Card";

//import interfaces
import { IBenefit } from "../../interfaces/Benefit";
import { IPayer } from "../../interfaces/Payer";
import { IHospital } from "../../interfaces/Hospital";

//import constants
import enums from "../../constants/enums";

//import services
import benefitService from "../../services/benefit";

//import default hospital logo
import DefaultLogo from "../../assets/hospitals/default.png";

interface IRideBenefitsProps {
  loading?: Boolean;
  benefits: {
    data: IBenefit;
    loading: string;
  };
  payer: {
    data: IPayer;
    loading: string;
  };
  hospital: {
    data: IHospital;
    loading: string;
  };
  dashboard?: boolean;
}

const RideBenefits = ({
  benefits,
  hospital,
  payer,
  dashboard,
}: IRideBenefitsProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const benefitConfigs = benefitService.get(hospital?.data?.name);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const isDiscounted = (p: any) => {
    return Object.values(enums.discount).includes(p);
  };

  useEffect(() => {
    if (
      benefits.loading === "loading" ||
      hospital.loading === "loading" ||
      payer.loading === "loading"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [hospital, payer, benefits]);

  if (isLoading) {
    return (
      <div className="flex justify-between px-2 py-2">
        <div className="flex-1">
          <Skeleton variant="text" width={"50%"} height={20} />
          <Skeleton variant="text" width={"70%"} height={20} />
        </div>
        <div>
          <Skeleton variant="rounded" width={40} height={40} />
        </div>
      </div>
    );
  } else {
    const availableRides = benefits.data?.rides_limit
      ? benefits.data?.rides_limit -
        (benefits.data?.rides_consumed ? benefits.data.rides_consumed : 0)
      : 0;

    return (
      <Card>
        <div className="flex justify-between px-2 pt-1 pb-0">
          <div className="flex-row font-extrabold">
            <div
              className={"flex text-primary " + (dashboard && "cursor-pointer")}
              onClick={() => {
                if (dashboard) {
                  navigate("/profile/benefits");
                }
              }}>
              <p className="text-base">{t("ride-benefits")}</p>
              {dashboard && <i className="icon-chev-right text-lg mt-0.5" />}
            </div>

            {payer?.data?.category === enums.PayerTypes.facility && (
              <>
                {(!benefits.data ||
                  (!benefits.data.budget_limit &&
                    !benefits.data?.rides_limit)) && (
                  <p className="flex items-center text-left text-sm text-secondary uppercase">
                    <i className="-ml-2 icon icon-infinity text-2xl" />
                    {t("unlimited-rides")}
                  </p>
                )}

                {benefits.data?.budget_limit && (
                  <p className="text-left text-sm text-secondary uppercase">
                    $
                    {benefits.data.budget_limit -
                      (benefits.data?.budget_consumed
                        ? benefits.data.budget_consumed
                        : 0)}{" "}
                    {t("available-to-use-on-rides")}
                  </p>
                )}

                {benefits.data?.rides_limit && (
                  <p className="text-left text-sm text-secondary uppercase">
                    {t("you-have-rides-available", { availableRides })}
                  </p>
                )}
              </>
            )}

            {payer.data?.category !== enums.PayerTypes.facility && (
              <>
                {isDiscounted(benefitConfigs.displayName) ? (
                  <p className="flex items-center text-left text-sm text-secondary uppercase">
                    <i className="-ml-2 icon icon-tag text-2xl" />{" "}
                    {t("discounted-rides")}
                  </p>
                ) : (
                  <p className="flex items-center text-left text-sm text-secondary uppercase">
                    <i className="-ml-2 icon icon-card text-2xl" />{" "}
                    {t("use-your-flex-card-to-book")}
                  </p>
                )}
              </>
            )}
          </div>

          <div className="flex-row my-auto">
            <img
              className="w-20 h-auto"
              alt={
                hospital?.data?.name !== null
                  ? hospital.data.name
                  : "Hospital Logo"
              }
              src={
                benefitConfigs.logo !== null ? benefitConfigs.logo : DefaultLogo
              }
            />
          </div>
        </div>
      </Card>
    );
  }
};

export default RideBenefits;
