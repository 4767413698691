import React from "react";
import { motion } from "framer-motion";
import clsx from "clsx";

interface IContainer {
  className?: string;
  children: React.ReactNode;
}

export default function Page({ children, className }: IContainer) {
  return (
    <motion.div
      className={clsx([
        "w-full md:w-2/3 lg:w-1/2 px-4 md:px-0 mx-auto flex flex-col flex-1",
        className && className,
      ])}
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -10, opacity: 0 }}
      transition={{ duration: 0.2 }}>
      {children}
    </motion.div>
  );
}
