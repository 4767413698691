import { useParams } from "react-router-dom";
import Container from "../../components/Container";
import enums from "../../constants/enums";
import { useTranslation } from "react-i18next";

const TermsOfUseScreen = () => {
  const routeParams = useParams();
  const { provider } = routeParams;

  const { t } = useTranslation();

  const isACS = (p: any) => {
    return Object.values(enums.ACS).includes(p);
  };

  return (
    <Container>
      {provider && isACS(provider) ? (
        <div className="py-4 md:w-3/4 md:mx-auto">
          <div className="overflow-auto">
            <b className="text-lg my-3 block">{t("terms-of-use")}</b>
            <div className="flex-1 mx-auto text-left">
              <p className="pb-2">{t("acs-terms-of-use-desc-1")}</p>

              <b>{t("access-to-application")}</b>

              <p className="pb-2">{t("acs-terms-of-use-desc-2")}</p>

              <b>{t("application-use")}</b>

              <p className="pb-2">{t("acs-terms-of-use-desc-3")}</p>

              <b>{t("communication")}</b>

              <p className="pb-2">{t("acs-terms-of-use-desc-4")}</p>

              <b>{t("restrictions")}</b>

              <p className="pb-2">{t("acs-terms-of-use-desc-5")}</p>

              <b>{t("disclaimers")}</b>

              <p className="pb-2">{t("acs-terms-of-use-desc-6")}</p>

              <b>{t("indemnification")}</b>

              <p className="pb-2">{t("acs-terms-of-use-desc-7")}</p>

              <b>{t("limitation-of-liability")}</b>

              <p className="pb-2">{t("acs-terms-of-use-desc-8")}</p>

              <b>{t("governing-law")}</b>

              <p className="pb-2">{t("acs-terms-of-use-desc-9")}</p>

              <b>{t("severability")}</b>

              <p className="pb-2">{t("acs-terms-of-use-desc-10")}</p>

              <b>{t("privacy-policy")}</b>

              <p className="pb-2">
                {t("acs-privacy-policy-desc-1")}
                <br />
                {t("acs-privacy-policy-desc-2")}
                <br />
                <a
                  className="text-primary"
                  target="_blank"
                  rel="noreferrer"
                  href="https://roundtriphealth.com/roundtrip-legal-documents/">
                  https://roundtriphealth.com/roundtrip-legal-documents/
                </a>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="py-4 md:w-3/4 md:mx-auto">
          <div className="overflow-auto">
            <b className="text-lg my-3 block">{t("terms-of-use")}</b>
            <div className="flex-1 mx-auto text-left">
              <p className="pb-2">{t("terms-of-use-desc-1")}</p>

              <b>{t("access-to-application")}</b>

              <p className="pb-2">{t("terms-of-use-desc-2")}</p>

              <b>{t("application-use")}</b>

              <p className="pb-2">{t("terms-of-use-desc-3")}</p>

              <b>{t("restrictions")}</b>

              <p>{t("terms-of-use-desc-4")}</p>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default TermsOfUseScreen;
