import React from "react";
import { Box, TextField } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";

const Input = React.forwardRef((props: TextFieldProps, ref) => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ "& > :not(style)": { width: "100%" } }}>
        <TextField
          inputRef={ref}
          id="outlined-basic"
          variant="outlined"
          {...props}
        />
      </Box>
    </ThemeProvider>
  );
});

export default Input;
