import StatusTag from "../../../../../components/StatusTag";
import TripItemIcon from "../../../../../components/TripItemIcon";
import DateTimeFormat from "../../../../../components/DateTimeFormat";
import { Card } from "@mui/material";
import { Trip } from "../../../../../interfaces/Trip";
import { Ride } from "../../../../../interfaces/Ride";
import { ReactComponent as SquareIcon } from "../../../../../assets/icons/square-filled.svg";
import { ReactComponent as CircleIcon } from "../../../../../assets/icons/circle-filled.svg";
import enums from "../../../../../constants/enums";
import { useTranslation } from "react-i18next";

interface IHistoryItemProps {
  trip: Trip;
  ride: Ride;
  onClick: any;
}

const HistoryListItem = ({ trip, ride, onClick }: IHistoryItemProps) => {
  const { t } = useTranslation();
  return (
    <Card elevation={0} className="p-2" onClick={onClick}>
      <div className="flex items-center">
        <div className="w-20 mr-2 bg-surface/50 rounded-md relative ">
          <div className="flex justify-center ">
            {trip.transportation_type !== null && (
              <TripItemIcon
                type={
                  trip.vehicle_need_type ===
                  enums.TransportationType.wheelchair_van
                    ? trip.vehicle_need_type
                    : trip.transportation_type
                }
              />
            )}
          </div>
          <StatusTag status={ride.current_status} />
        </div>

        <div className="flex flex-1">
          <div className="flex-1 text-left">
            <div className="flex flex-row items-center">
              <p className="text-base font-extrabold my-1 capitalize">
                {t(trip.trip_type)} -{" "}
                {trip.vehicle_need_type ===
                enums.TransportationType.wheelchair_van
                  ? t("wheelchair_van")
                  : trip.transportation_type ===
                    enums.TransportationType.ride_share
                  ? t(enums.TransportationType.ride_share)
                  : t(trip.transportation_type)}
              </p>
              <span className="mx-1">•</span>
              <DateTimeFormat
                styling={"text-base font-extrabold"}
                timestamp={ride.start_time}
                variant={"datetime"}
              />
            </div>
            <div className="flex flex-row items-center">
              <div className="flex-initial flex-col justify-between items-center justify-center">
                <SquareIcon className="fill-disabled w-3" />
                <div className="flex h-full">
                  <div className="w-2 border border-transparent h-4 ">
                    <div className="self-stretch border-r-2 border-disabled border-dashed h-full" />
                  </div>
                </div>

                <CircleIcon className="fill-disabled w-3" />
              </div>
              <div className="flex-1 pl-2">
                <p className="text-base text-disabled">
                  {ride.pickup_location_id === trip.pickup_location.id
                    ? trip.pickup_location.name
                    : trip.dropoff_location.name}
                </p>
                <p className="text-base text-disabled">
                  {ride.dropoff_location_id === trip.dropoff_location.id
                    ? trip.dropoff_location.name
                    : trip.pickup_location.name}
                </p>
              </div>
            </div>
          </div>
          <div className="justify-center self-stretch flex my-auto ml-auto">
            <i className="icon-chev-right text-2xl" />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default HistoryListItem;
