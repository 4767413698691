//import dependencies
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//import lottie files
import Success from "../../../assets/lottie/success.json";
import Spinner from "../../../assets/lottie/spinner.json";

//import components
import Button from "../../../components/Button";
import Container from "../../../components/Container";
import RideBenefits from "../../../components/RideBenefits";

//import store
import { selectBenefits } from "../../../store/account/benefitsSlice";
import { selectPayer } from "../../../store/account/payerSlice";
import { selectHospital } from "../../../store/account/hospitalSlice";
import { selectPatient } from "../../../store/account/userSlice";

const MemberBenefitsScreen = () => {
  const navigate = useNavigate();
  const patient = useSelector(selectPatient);
  const benefits = useSelector(selectBenefits);
  const payer = useSelector(selectPayer);
  const hospital = useSelector(selectHospital);
  const { t } = useTranslation();

  const handleSubmit = () => {
    navigate("../information");
  };

  return (
    <>
      <Container className="className='items-center justify-around'">
        <div className="items-center justify-center flex flex-col flex-1 p-8 mb-40">
          {patient.loading === "loading" || benefits.loading === "loading" ? (
            <div className="w-16 mx-auto">
              <Lottie animationData={Spinner} width={32} height={32} />
            </div>
          ) : (
            <>
              <div className="w-16 mx-auto">
                <Lottie
                  animationData={Success}
                  loop={false}
                  width={32}
                  height={32}
                />
              </div>

              <h1 className="text-2xl text-text font-extrabold py-4">
                {t("good-news")}
              </h1>
              <p className="text-base text-secondary">
                {t("confirmed-membership")}
              </p>
            </>
          )}
        </div>
      </Container>

      <div className="bg-gradient-to-t from-background via-background to-background/50 fixed bottom-0 left-0 right-0 p-6">
        <div className="mb-8 w-full md:w-80 mx-auto">
          <RideBenefits payer={payer} benefits={benefits} hospital={hospital} />
        </div>

        <Button
          onClick={handleSubmit}
          className="w-full md:w-80"
          color="primary"
          variant="contained">
          {t("continue")}
          <div className="absolute right-1.5 bottom-1.5 top-1.5  text-2xl bg-white/25 rounded">
            <i className="icon-arrow-right" />
          </div>
        </Button>
      </div>
    </>
  );
};

export default MemberBenefitsScreen;
