//dependencies
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";

//components
import Container from "../../../components/Container";
import HistoryListItem from "./components/HistoryListItem";
import Spinner from "../../../components/Spinner";

//store
import { AppDispatch } from "../../../store";
import {
  fetchHistoryTrips,
  selectHistoryData,
} from "../../../store/trips/historySlice";

//interfaces
import { Trip } from "../../../interfaces/Trip";

const TripHistoryScreen = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { list, loading, page, more } = useSelector(selectHistoryData);
  const { t } = useTranslation();

  const fetchTripData = () => {
    try {
      if (loading !== "loading") {
        dispatch(fetchHistoryTrips(page));
      }
    } catch (e) {
      console.error(e);
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchTripData();
  }, [dispatch]); // eslint-disable-line

  if ((loading === "loading" || loading === "idle") && page === 1) {
    return (
      <div className="flex flex-1 items-center">
        <div className="w-12 my-6 mx-auto">
          <Spinner />
        </div>
      </div>
    );
  }
  return (
    <Container>
      <div className="pt-4">
        <b className="text-text text-base">{t("trip-history")}</b>
        <div className="pt-4">
          {list.length ? (
            <InfiniteScroll
              dataLength={list.length} //This is important field to render the next data
              next={fetchTripData}
              hasMore={more}
              loader={
                <div className="w-12 my-6 mx-auto">
                  <Spinner />
                </div>
              }>
              {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
              {list.map((t: Trip) => {
                return t.rides.map((r) => {
                  return (
                    <div
                      className="my-2 cursor-pointer"
                      key={`${t.id}-${r.id}`}>
                      <HistoryListItem
                        trip={t}
                        ride={r}
                        onClick={() => {
                          navigate(`/trip-details/${t.id}/${r.id}`);
                        }}
                      />
                    </div>
                  );
                });
              })}
            </InfiniteScroll>
          ) : (
            <div className=" my-6 mx-auto">
              <p className="font-extrabold text-md text-center text-subtitle pt-8 w-full">
                {t("you-havent-taken-any-trips-yet")}
              </p>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default TripHistoryScreen;
