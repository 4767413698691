import alert from "../../assets/lottie/alert.json";
import Lottie from "lottie-react";
import Container from "../../components/Container";
import { useTranslation } from "react-i18next";

const InactiveUserScreen = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div
        className="flex my-auto justify-center"
        style={{ marginBottom: "400px" }}>
        <div className="my-6 text-center">
          <div className="w-24 mx-auto">
            <Lottie animationData={alert} loop={false} width={32} height={32} />
          </div>

          <h2 className="text-2xl font-extrabold text-text">
            {t("account-inactive")}
          </h2>

          <div className="text-base text-secondary m-2">
            {t("benefits-no-longer-active")}
          </div>
          <div className="text-base text-secondary m-2">
            {t("contact-roundtrip-administrator")}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default InactiveUserScreen;
