import { combineReducers } from "@reduxjs/toolkit";
import tripReducer from "./tripSlice";
import locationReducer from "./locationSlice";
import transportationReducer from "./transportationSlice";
import reasonReducer from "./reasonSlice";
import estimateReducer from "./estimateSlice";
import editReducer from "./editSlice";

const reducer = combineReducers({
  trip: tripReducer,
  location: locationReducer,
  transportation: transportationReducer,
  reason: reasonReducer,
  estimate: estimateReducer,
  edit: editReducer,
});

const booking = (state: any, action: any) => {
  if (action.type === "RESET" || action.type === "booking/reset") {
    state = undefined;
  }
  return reducer(state, action);
};

export default booking;
