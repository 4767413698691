import moment from "moment/moment";

interface IDateTimeItemProps {
  timestamp: string | null;
  variant: "date" | "time" | "datetime";
  styling?: string; //font-bold text-sm text-text
}

const DateTimeFormat = ({
  timestamp,
  variant,
  styling,
}: IDateTimeItemProps) => {
  const timestampParser = (dateTimeValue: string) => {
    if (variant === "date") {
      return moment(dateTimeValue).format("dddd, MMM DD");
    } else if (variant === "time") {
      return moment(dateTimeValue).format("h:mm A");
    } else {
      return moment(dateTimeValue).format("dddd, h:mm A");
    }
  };

  if (timestamp !== null) {
    return (
      <p className={`${styling} ${variant === "datetime" && "text-lg"}`}>
        {timestampParser(timestamp)}
      </p>
    );
  } else {
    return null;
  }
};

export default DateTimeFormat;
