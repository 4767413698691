import { combineReducers } from "@reduxjs/toolkit";
import notificationSlice from "./notificationSlice";

const reducer = combineReducers({
  notification: notificationSlice,
});

const notifications = (state: any, action: any) => {
  if (action.type === "RESET") {
    state = undefined;
  }
  return reducer(state, action);
};

export default notifications;
