import TripItem from "./TripItem";
import { Trip } from "../../../interfaces/Trip";
import { useTranslation } from "react-i18next";

interface IDashboardTripsProp {
  tripsData: Trip[];
}

const DashboardTrips = ({ tripsData }: IDashboardTripsProp) => {
  const { t } = useTranslation();

  const tripsList = tripsData.map((trip) => (
    <li className="py-3" key={trip.id}>
      <TripItem tripData={trip} />
    </li>
  ));

  return (
    <div>
      <p className="text-left font-extrabold text-3xl text-subtitle mt-4">
        {t("my-trips")}
      </p>
      <ul>{tripsList}</ul>
    </div>
  );
};

export default DashboardTrips;
