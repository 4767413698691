//dependencies imports
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

//import screens
import ChooseRide from "./screens/ChooseRide";
import DropoffPickupLocation from "./screens/DropoffPickupLocation";
import ReviewBook from "./screens/ReviewBook";
import RideSetup from "./screens/RideSetup";
import CardSelection from "./screens/CardSelection";
import ErrorScreen from "../general/ErrorScreen";
import ErrorScreenBooking from "./screens/PaymentSuccessError/ErrorScreen";
import SuccessScreen from "./screens/PaymentSuccessError/SuccessScreen";

//import components
import StyledProgressBar from "./screens/components/StyledProgressBar";
import Container from "../../components/Container";
import RideBenefits from "../../components/RideBenefits";

//import store
import { selectBenefits } from "../../store/account/benefitsSlice";
import { selectPayer } from "../../store/account/payerSlice";
import { selectHospital } from "../../store/account/hospitalSlice";
import { selectDropoffLocations } from "../../store/booking/locationSlice";

const TripBookingScreen = () => {
  const location = useLocation();
  const benefits = useSelector(selectBenefits);
  const payer = useSelector(selectPayer);
  const hospital = useSelector(selectHospital);
  const dropoffLocations = useSelector(selectDropoffLocations); //dropoff

  return (
    <Routes key={location.pathname} location={location}>
      <Route path="/*" element={<Navigate to="./drop-off" replace />} />
      <Route
        path="/not-found"
        element={<ErrorScreen onClick={() => window.location.replace("/")} />}
      />

      {["/drop-off/:tid", "/drop-off"].map((path, index) => {
        return (
          <Route
            path={path}
            element={
              <Container>
                <div className="w-full mx-auto pt-6 px-3 md:px-0">
                  <RideBenefits
                    payer={payer}
                    benefits={benefits}
                    hospital={hospital}
                  />
                  <div className="pt-3">
                    <StyledProgressBar progress={25} step={1} />
                  </div>
                  <div className="pt-3">
                    <DropoffPickupLocation searchType={"Dropoff"} />
                  </div>
                </div>
              </Container>
            }
            key={index}
          />
        );
      })}

      {["/pick-up/:tid", "/pick-up"].map((path, index) => {
        return (
          <Route
            path={path}
            element={
              <Container>
                <div className="w-full mx-auto pt-6 px-3 md:px-0">
                  <RideBenefits
                    payer={payer}
                    benefits={benefits}
                    hospital={hospital}
                  />
                  <div className="pt-3">
                    <StyledProgressBar progress={50} step={2} />
                  </div>
                  <div className="pt-3">
                    <DropoffPickupLocation searchType={"Pickup"} />
                  </div>
                </div>
              </Container>
            }
            key={index}
          />
        );
      })}

      {dropoffLocations.data && (
        <>
          {["/choose-ride/:tid", "/choose-ride"].map((path, index) => {
            return (
              <Route
                path={path}
                element={
                  <Container>
                    {/* minus padding to combat issue on mobile with integrated padding in container*/}
                    <div className="-mx-4">
                      <div className="pt-4 px-4 md:px-0">
                        <StyledProgressBar progress={75} step={3} />
                      </div>
                      <ChooseRide />
                    </div>
                  </Container>
                }
                key={index}
              />
            );
          })}

          {["/ride-setup/:tid", "/ride-setup"].map((path, index) => {
            return (
              <Route
                path={path}
                element={
                  <Container>
                    <div className="pt-4">
                      <StyledProgressBar progress={100} step={4} />
                    </div>
                    <RideSetup />
                  </Container>
                }
                key={index}
              />
            );
          })}

          {["/review-booking/:tid", "/review-booking"].map((path, index) => {
            return (
              <Route
                path={path}
                element={
                  <Container>
                    <ReviewBook />
                  </Container>
                }
                key={index}
              />
            );
          })}

          {/* ensure only accessible if eligible to use card */}
          {[
            "/review-booking/select-card/:tid",
            "/review-booking/select-card/",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={
                  <Container>
                    <CardSelection />
                  </Container>
                }
                key={index}
              />
            );
          })}
        </>
      )}

      {["/review-booking/success/:tid", "/review-booking/success/"].map(
        (path, index) => {
          return (
            <Route
              path={path}
              element={
                <Container>
                  <SuccessScreen />
                </Container>
              }
              key={index}
            />
          );
        },
      )}

      {["/review-booking/error/:tid", "/review-booking/error/"].map(
        (path, index) => {
          return (
            <Route
              path={path}
              element={
                <Container>
                  <ErrorScreenBooking />
                </Container>
              }
              key={index}
            />
          );
        },
      )}
    </Routes>
  );
};

export default TripBookingScreen;
