import { combineReducers } from "@reduxjs/toolkit";
import historySlice from "./historySlice";
import ongoingSlice from "./ongoingSlice";
import upcomingSlice from "./upcomingSlice";

const reducer = combineReducers({
  ongoing: ongoingSlice,
  upcoming: upcomingSlice,
  history: historySlice,
});

const trips = (state: any, action: any) => {
  if (action.type === "RESET") {
    state = undefined;
  }
  return reducer(state, action);
};

export default trips;
