//dependencies
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Spinner from "../../../components/Spinner";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import { Trans, useTranslation } from "react-i18next";

//components
import Container from "../../../components/Container";

//store
import { selectPatient, fetchPatient } from "../../../store/account/userSlice";
import { fetchPayer, selectPayer } from "../../../store/account/payerSlice";
import { AppDispatch } from "../../../store";
import { selectHospital } from "../../../store/account/hospitalSlice";
import { useAuth } from "../../../contexts/Auth";

const ProfileNavigation = () => {
  const { user, logout } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const { currentUser } = useAuth();
  const hospital = useSelector(selectHospital);
  const { data, loading } = useSelector(selectPatient);
  const payer = useSelector(selectPayer);

  const [modalIsShown, setModalIsShown] = useState(false);

  const handleLogout = async () => {
    logout({ returnTo: window.location.origin });
    dispatch({ type: "RESET" });
  };

  // TODO: Should these use auth `loading`?
  useEffect(() => {
    if (
      user &&
      (!data?.default_identifier_value || data?.default_identifier_value === "")
    ) {
      dispatch(fetchPatient(user.id));
    }
  }, [dispatch, user, data?.default_identifier_value]);

  useEffect(() => {
    if (data?.hospital_id) {
      dispatch(fetchPayer(data.hospital_id));
    }
  }, [data?.hospital_id, user, dispatch]);

  if (payer.loading !== "finished" || loading !== "finished") {
    return (
      <div className="flex flex-1 items-center">
        <div className="w-12 my-6 mx-auto">
          <Spinner />
        </div>
      </div>
    );
  } else {
    return (
      <Container>
        <div className="mt-6 mx-auto">
          <Avatar
            alt={user?.name}
            src={user?.picture}
            className="mx-auto"
            sx={{ width: 56, height: 56 }}
          />
          <p className="pt-4 text-text text-base font-extrabold">
            {t("hello")}, {currentUser.first_name}
          </p>
          <p className="text-subtitle text-sm">
            {t("member-id")}: {data?.default_identifier_value}
          </p>
        </div>

        <div className="pt-6">
          <Card>
            <div className="justify-center my-auto items-center">
              <div className="w-full md:mx-auto cursor-pointer">
                <div
                  className="flex hover:text-primary active:text-primary text-text"
                  onClick={() => navigate("./edit-profile")}>
                  <div>
                    <i className="icon-profile text-6xl" />
                  </div>
                  <div className="flex flex-1 py-3">
                    <div className="pl-3 my-auto">
                      <p className="text-base">{t("edit-profile")}</p>
                    </div>
                    <div className="justify-center self-stretch flex my-auto ml-auto">
                      <i className="icon-chev-right text-disabled text-4xl" />
                    </div>
                  </div>
                </div>

                <div className="h-0.5 bg-background my-2 -m-2" />

                <div
                  className="flex hover:text-primary active:text-primary text-text"
                  onClick={() => navigate("./trip-history")}>
                  <div>
                    <i className="icon-schedule-ride text-6xl" />
                  </div>
                  <div className="flex flex-1 py-3">
                    <div className="pl-3 my-auto">
                      <p className="text-base">{t("trip-history")}</p>
                    </div>
                    <div className="justify-center self-stretch flex my-auto ml-auto">
                      <i className="icon-chev-right text-disabled text-4xl" />
                    </div>
                  </div>
                </div>

                <div className="h-0.5 bg-background my-2 -m-2" />

                {payer.data.category === "patient" && (
                  <>
                    <div
                      className="flex hover:text-primary active:text-primary text-text"
                      onClick={() => navigate("./saved-payment-cards")}>
                      <div>
                        <i className="icon-card text-6xl" />
                      </div>
                      <div className="flex flex-1 py-3">
                        <div className="pl-3 my-auto">
                          <p className="text-base">
                            {t("saved-payment-cards")}
                          </p>
                        </div>
                        <div className="justify-center self-stretch flex my-auto ml-auto">
                          <i className="icon-chev-right text-disabled text-4xl" />
                        </div>
                      </div>
                    </div>

                    <div className="h-0.5 bg-background my-2 -m-2" />
                  </>
                )}

                <div
                  className="flex hover:text-primary active:text-primary text-text"
                  onClick={() => navigate("./benefits")}>
                  <div>
                    <i className="icon-gift text-6xl" />
                  </div>
                  <div className="flex flex-1 py-3">
                    <div className="pl-3 my-auto">
                      <p className="text-base">{t("benefits")}</p>
                    </div>
                    <div className="justify-center self-stretch flex my-auto ml-auto">
                      <i className="icon-chev-right text-disabled text-4xl" />
                    </div>
                  </div>
                </div>

                <div className="h-0.5 bg-background my-2 -m-2" />

                <div
                  className="flex hover:text-primary active:text-primary text-text"
                  onClick={() => navigate("./help-center")}>
                  <div>
                    <i className="icon-question text-6xl" />
                  </div>
                  <div className="flex flex-1 py-3">
                    <div className="pl-3 my-auto">
                      <p className="text-base">{t("help-and-support")}</p>
                    </div>
                    <div className="justify-center self-stretch flex my-auto ml-auto">
                      <i className="icon-chev-right text-disabled text-4xl" />
                    </div>
                  </div>
                </div>

                <div className="h-0.5 bg-background my-2 -m-2" />

                <div
                  className="flex hover:text-primary active:text-primary text-text"
                  onClick={() => {
                    setModalIsShown(true);
                  }}>
                  <div>
                    <i className="icon-logout text-6xl" />
                  </div>
                  <div className="flex flex-1 py-3">
                    <div className="pl-3 my-auto">
                      <p className="text-base">{t("log-out")}</p>
                    </div>
                    <div className="justify-center self-stretch flex my-auto ml-auto">
                      <i className="icon-chev-right text-disabled text-4xl" />
                    </div>
                  </div>
                </div>

                {modalIsShown && (
                  <Modal
                    onHideModal={() => {
                      setModalIsShown(false);
                    }}>
                    <i
                      className={
                        "icon-warning flex justify-center text-8xl -my-4"
                      }
                    />
                    <p className="text-center text-xl font-extrabold pt-2">
                      {t("log-out")}
                    </p>
                    <p className="text-center text-sm text-secondary pt-1">
                      {t("confirm-log-out")}
                    </p>
                    <div className="my-2 pt-6 flex justify-center">
                      <Button
                        className="w-72"
                        color="primary"
                        variant="contained"
                        onClick={handleLogout}>
                        {t("log-out")}
                      </Button>
                    </div>
                    <div className="my-2 flex justify-center">
                      <Button
                        className="w-72"
                        color="info"
                        variant="contained"
                        onClick={() => setModalIsShown(false)}>
                        {t("cancel")}
                      </Button>
                    </div>
                  </Modal>
                )}
              </div>
            </div>
          </Card>
        </div>

        <p className="max-w-80 mx-auto text-sm mt-6 text-secondary pb-8">
          {/* © Roundtrip 2023 - <a href={`/terms-of-use/${hospital.data?.name}`} rel="noreferrer" target="_blank" className='underline'>Terms & Conditions</a> and <a href='https://roundtriphealth.com/roundtrip-legal-documents/' rel="noreferrer" target="_blank" className='underline'>Privacy Policy</a> */}
          <Trans i18nKey={"user-agreement-profile"}>
            <a
              href={`/terms-of-use/${hospital.data?.name}`}
              rel="noreferrer"
              target="_blank"
              className="underline">
              Terms & Conditions
            </a>
            <a
              href="https://roundtriphealth.com/roundtrip-legal-documents/"
              rel="noreferrer"
              target="_blank"
              className="underline">
              Privacy Policy
            </a>
          </Trans>
        </p>
      </Container>
    );
  }
};

export default ProfileNavigation;
