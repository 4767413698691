import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { ThemeProvider, styled } from "@mui/material/styles";
import theme from "../../theme";

interface IProgressBarProps {
  progress: number;
  variant: any;
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#EAEBF5",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1E24AE",
  },
}));

const ProgressBar = ({ progress, variant }: IProgressBarProps) => {
  return (
    <ThemeProvider theme={theme}>
      <BorderLinearProgress variant={variant} value={progress} />
    </ThemeProvider>
  );
};

export default ProgressBar;
