import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api";
import { getErrorMessage } from "../../utilities/helpers";

const PAGE_SIZE = 6;

interface SliceState {
  more: boolean;
  page: number;
  loading: "idle" | "loading" | "finished";
  selected: any;
  list: Array<any>;
  error: String | null;
}

export const fetchHistoryTrips = createAsyncThunk(
  "trips/history/fetchHistoryTrips",
  async (page: number, { rejectWithValue }) => {
    try {
      const trips = await apiService.getTrips(page, PAGE_SIZE, "history");
      return { data: trips, page: page };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const initialState: SliceState = {
  more: true,
  page: 1,
  list: [],
  selected: null,
  loading: "idle",
  error: null,
};

export const historySlice = createSlice({
  name: "trips/history",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchHistoryTrips.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchHistoryTrips.rejected, (state, action) => {
        state.loading = "finished";
        state.more = false;
        state.error = getErrorMessage(action.error);
      })
      .addCase(fetchHistoryTrips.fulfilled, (state, action) => {
        state.loading = "finished";

        const data = [...state.list, ...action.payload.data];
        state.list = data.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id),
        );

        // Don't Fetch Anymore
        if (action.payload.data.length < PAGE_SIZE) {
          state.more = false;
        }
        // Fetch More
        else {
          state.page = action.payload.page + 1;
          state.more = true;
        }
      });
  },
});

// Action creators are generated for each case reducer function
export const selectHistoryData = ({ trips }: any) => trips.history;
export default historySlice.reducer;
