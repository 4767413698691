import Button from "../Button";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { notificationPush } from "../../store/ui/notificationSlice";
import { selectPatient } from "../../store/account/userSlice";
//import { fetchPaymentCards, selectPayment } from '../../store/account/paymentSlice';
import { fetchPaymentCards } from "../../store/account/paymentSlice";
import { AppDispatch } from "../../store";
import configService from "../../services/config";
import { useState } from "react";
import { useTranslation } from "react-i18next";

configService.init(process.env);
const AUTH0_AUDIENCE = configService.get("AUTH0_AUDIENCE");

const StripePaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch<AppDispatch>();
  const patient = useSelector(selectPatient);
  const { t } = useTranslation();
  //const payment = useSelector(selectPayment);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault(); // Prevent page from refreshing

    //before stripe loaded, return nothing
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    // //this is list of payment card fingerprints to compare new cards to
    // if (payment?.data){
    //   const fingerprints = payment.data.map((card: any) => {return card.card.fingerprint});

    //   //do comparison in here, if fingerprint is found, then bypass adding card, and display notification error that card already exists

    // }

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: AUTH0_AUDIENCE,
      },
      redirect: "if_required",
    });

    if (result.error) {
      setLoading(false);

      dispatch(
        notificationPush({
          title: t("error"),
          message: result.error.message
            ? result.error.message.toString()
            : t("there-was-an-error-adding-your-card-please-try-again"),
          type: "error",
        }),
      );
    } else {
      setLoading(false);

      dispatch(
        notificationPush({
          title: t("success"),
          message: t("your-card-has-been-successfully-added"),
          type: "success",
        }),
      );

      //refresh payment card list in redux
      dispatch(fetchPaymentCards(patient.data.id));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="pt-3 grid place-items-center">
        <Button
          className="w-64 md:w-80"
          color="primary"
          variant="contained"
          type="submit"
          disabled={!stripe || !elements || loading}
          loading={loading}>
          {t("submit")}
        </Button>
      </div>
    </form>
  );
};

export default StripePaymentForm;
