interface IRecentAddressItemProps {
  recentAddress: {
    name: string;
    street_address: string;
    city: string;
    state: string;
    zipcode: string | number;
  };
}

const RecentAddressItem = ({ recentAddress }: IRecentAddressItemProps) => {
  return (
    <div className="flex flex-1 py-3 pl-3 -mb-px cursor-pointer">
      {" "}
      {/* Border and divider done outside of component for customisability */}
      <div className="pl-3">
        <p className="text-base text-text my-1">{recentAddress.name}</p>
        <p className="text-sm text-secondary">{recentAddress.street_address}</p>
        <p className="text-sm text-secondary">
          {recentAddress.city}, {recentAddress.state}, {recentAddress.zipcode}
        </p>
      </div>
      <div className="justify-center self-stretch flex my-auto ml-auto">
        <i className="icon-chev-right text-2xl text-disabled" />
      </div>
    </div>
  );
};

export default RecentAddressItem;
