import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import benefitsReducer from "./benefitsSlice";
import payerReducer from "./payerSlice";
import paymentReducer from "./paymentSlice";
import hospitalReducer from "./hospitalSlice";

const reducer = combineReducers({
  user: userReducer,
  benefits: benefitsReducer,
  payer: payerReducer,
  payment: paymentReducer,
  hospital: hospitalReducer,
});

const account = (state: any, action: any) => {
  if (action.type === "RESET") {
    state = undefined;
  }
  return reducer(state, action);
};

export default account;
