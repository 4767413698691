import { useSelector } from "react-redux";
import Card from "../../../components/Card";
import Container from "../../../components/Container";
import ContactUsFooter from "./components/ContactUsFooter";
import { selectHospital } from "../../../store/account/hospitalSlice";
import { useTranslation } from "react-i18next";

const HelpCenterScreen = () => {
  const hospital = useSelector(selectHospital);
  const { t } = useTranslation();
  return (
    <Container>
      <div className="pt-4 md:w-3/4 md:mx-auto">
        <Card>
          <div className="items-center justify-center flex flex-col p-8">
            <img
              src={require("../../../assets/images/help-support.png")}
              alt="Help & Support"
            />
            <h2 className="text-xl my-10 font-extrabold">
              {t("help-and-support")}
            </h2>
          </div>
          <div className="flex flex-1  flex-col items-start ">
            <a
              href="https://info.roundtriphealth.com/riderquickstart"
              rel="noreferrer"
              target="_blank"
              className="flex py-3 hover:text-primary active:text-primary border-b-2 border-b-background w-full">
              <div className="pl-3 my-auto">
                <p className="text-base">{t("quick-start-guide")}</p>
              </div>
              <div className="justify-center self-stretch flex my-auto ml-auto">
                <i className="icon-chev-right text-disabled text-4xl" />
              </div>
            </a>

            <a
              href="https://info.roundtriphealth.com/riderfaq"
              rel="noreferrer"
              target="_blank"
              className="flex py-3 hover:text-primary active:text-primary w-full">
              <div className="pl-3 my-auto">
                <p className="text-base">{t("frequently-asked-questions")}</p>
              </div>
              <div className="justify-center self-stretch flex my-auto ml-auto">
                <i className="icon-chev-right text-disabled text-4xl" />
              </div>
            </a>
          </div>
        </Card>
        <div className="my-6">
          <ContactUsFooter hospital={hospital.data} />
        </div>
      </div>
    </Container>
  );
};

export default HelpCenterScreen;
