import clsx from "clsx";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { Datepicker, locale } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { useTranslation } from "react-i18next";

interface IDateSelectorItemProps {
  onChange: (e?: any) => void;
  value: string;
  selected?: boolean;
  minDate?: Moment; //impose minimum date
  reset?: boolean; //reset input if necessary
  returnDate?: boolean;
  error?: boolean;
}

const DateSelectorItem = React.forwardRef(
  (
    {
      onChange = () => {},
      value,
      selected,
      minDate,
      reset,
      returnDate,
      error,
    }: IDateSelectorItemProps,
    ref, // eslint-disable-line @typescript-eslint/no-unused-vars
  ) => {
    const { t, i18n } = useTranslation();
    const [val, setVal] = useState(
      value !== "" ? moment(value, "YYYY-MM-DD") : null,
    );
    const [isOpen, setIsOpen] = useState(false);

    //reset date input where required to avoid conflicts
    useEffect(() => {
      if (reset) {
        onChange();
        setVal(null);
      }
    }, [reset]); //eslint-disable-line

    const handleChange = (newValue: any) => {
      if (
        newValue !== null &&
        (minDate
          ? moment(minDate.format("YYYY-MM-DD")).isSameOrBefore(
              moment(newValue.value),
            )
          : moment().isSameOrBefore(moment(newValue.value)))
      ) {
        onChange(moment(newValue.value).format("YYYY-MM-DD"));
        setVal(moment(newValue.value));
      }
    };

    return (
      <div
        className={clsx([
          "border-2 rounded-md relative p-2 md:p-4 h-full cursor-pointer",
          selected && "border-primary",
          error && "border-error",
          !selected && !error && "border-surface",
        ])}
        onClick={() => (isOpen === false ? setIsOpen(true) : undefined)}>
        <div className="opacity-0 h-0">
          <Datepicker
            controls={["date"]}
            theme="ios"
            themeVariant="light"
            isOpen={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            onChange={isOpen ? handleChange : undefined} //only change when user changes
            min={minDate ? minDate.format("YYYY-MM-DD") : undefined}
            locale={locale[i18n.language]}
            responsive={{
              xsmall: {
                controls: ["date"],
                display: "bottom",
                touchUi: true,
              },
              small: {
                controls: ["date"],
                display: "anchored",
                touchUi: true,
              },
            }}
          />
        </div>
        <div className="h-full grid place-items-center">
          {val ? (
            <>
              <p
                className={
                  returnDate
                    ? "text-2xl font-extrabold"
                    : "text-lg font-extrabold"
                }>
                {moment(val).format("ll")}
              </p>
              <p className="text-primary font-medium text-sm flex items-center justify-center mt-1">
                {t("change-date")} <i className="icon-chev-right text-lg" />
              </p>
            </>
          ) : (
            <>
              <i className="text-4xl icon-calendar" />
              <p className="text-secondary text-sm">{t("select-date")}</p>
            </>
          )}
        </div>
      </div>
    );
  },
);

export default DateSelectorItem;
