//dependencies
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//components
import CancelModal from "./components/TripDetails/CancelModal";
import MapContainer from "../../components/MapContainer";
import Button from "../../components/Button";
import RideDispatch from "./components/TripDetails/RideDispatch";
import TripSummary from "./components/TripDetails/TripSummary";
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import RideItem from "../../components/RideItem";
import Card from "../../components/Card";
import TransportationCompany from "./components/TripDetails/TransportationCompany";

//store
import { AppDispatch } from "../../store";
import {
  fetchTrip,
  selectTrip,
  cancelRide,
  cancelTrip,
} from "../../store/trip/tripSlice";
import {
  fetchTripDispatch,
  selectTripDispatch,
  fetchTripDispatchDetails,
  selectTripDispatchDetails,
} from "../../store/trip/tripDispatchSlice";
import { refreshUpcomingTrips } from "../../store/trips/upcomingSlice";
import {
  fetchTransportationCompany,
  selectTransportationCompany,
} from "../../store/trip/tripTransportationCompanySlice";

//enums
import enums, { RideType } from "../../constants/enums";

//interfaces
import { Ride } from "../../interfaces/Ride";

const TripDetailsScreen = () => {
  const trip = useSelector(selectTrip);
  const tripDispatch = useSelector(selectTripDispatch);
  const tripDispatchDetails = useSelector(selectTripDispatchDetails);
  const transportationCompany = useSelector(selectTransportationCompany);
  const [transportationCompanyPresent, setTransportationCompanyPresent] =
    useState(false);
  const [tripDispatchPresent, setTripDispatchPresent] = useState(false)

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const routeParams = useParams();
  const { tid, rid } = routeParams;
  const { t } = useTranslation();

  const [ride, setRide] = useState<Ride>();
  const [isReturn, setIsReturn] = useState(false);
  const [modalIsShown, setModalIsShown] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isCancelingBoth, setIsCancelingBoth] = useState(false); //state to see if cancelling both legs of trip

  useEffect(() => {
    if (tid) {
      dispatch(fetchTrip(tid));
    }
  }, [dispatch, tid]);

  useEffect(() => {
    if (trip.data) {
      setRide(
        trip.data.rides.filter((r: Ride) => {
          return r.id.toString() === rid;
        })[0],
      );
      setIsReturn(
        trip.data.rides
          .map((e: Ride) => {
            return e.id.toString();
          })
          .indexOf(rid) > 0,
      ); //check if the current ride is a return ride
    }
  }, [trip, rid]);

  useEffect(() => {
    if (ride && rid) {
      dispatch(fetchTripDispatch(rid));
    }
  }, [dispatch, ride, rid]);

  useEffect(() => {
    if (tripDispatch) {
      dispatch(fetchTripDispatchDetails(tripDispatch?.id));
      setTripDispatchPresent(true)
    } else{
      setTripDispatchPresent(false)
    }
  }, [dispatch, tripDispatch]);

  useEffect(() => {
    if (ride?.transportation_company_id) {
      dispatch(fetchTransportationCompany(ride?.transportation_company_id));
      setTransportationCompanyPresent(true);
    } else {
      setTransportationCompanyPresent(false);
    }
  }, [dispatch, ride]);

  const handleCancelTrip = (
    reason: string,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    tid: string | any,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    rid: string | any,
  ) => {
    setIsCanceling(true);
    //cancels one ride
    if (!isCancelingBoth) {
      dispatch(cancelRide({ trip_id: rid, reason: reason }))
        .unwrap()
        .then(() => {
          setModalIsShown(false);
          dispatch(fetchTrip(tid));
          dispatch(fetchTripDispatch(rid));
          dispatch(refreshUpcomingTrips(1));
        })
        .catch((e) => {
          console.error(e);
          navigate("/error");
        })
        .finally(() => {
          setIsCanceling(false);
        });
    }
    //cancels entire roundtrip
    else if (isCancelingBoth) {
      dispatch(cancelTrip({ trip_id: tid, reason_id: reason }))
        .unwrap()
        .then(() => {
          setModalIsShown(false);
          dispatch(fetchTrip(tid));
          dispatch(fetchTripDispatch(rid));
          dispatch(refreshUpcomingTrips(1));
        })
        .catch((e) => {
          console.error(e);
          navigate("/error");
        })
        .finally(() => {
          setIsCanceling(false);
          setIsCancelingBoth(false);
        });
    }
  };

  if (trip.loading === "idle" || trip.loading === "loading") {
    return (
      <div className="flex flex-1 items-center">
        <div className="w-12 my-6 mx-auto">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <Container>
      {ride && (
        <div className="pt-4 md:w-3/4 md:mx-auto">
          <div className="h-60">
            <MapContainer
              pickup={
                !isReturn
                  ? trip.data.pickup_location
                  : trip.data.dropoff_location
              }
              dropoff={
                !isReturn
                  ? trip.data.dropoff_location
                  : trip.data.pickup_location
              }
            />
          </div>

          <div className="pt-4">
            <TripSummary
              ride={ride}
              transportation={
                trip.data.vehicle_need_type ===
                enums.TransportationType.wheelchair_van
                  ? trip.data.vehicle_need_type
                  : trip.data.transportation_type
              }
            />
          </div>

          {[
            enums.RideStatus.queued.toString(),
            enums.RideStatus.claimed.toString(),
          ].includes(ride.current_status) && (
            <div className="my-4">
              <Button
                className="w-full my-2"
                color="primary"
                variant="contained"
                onClick={() => navigate(`/book-trip/drop-off/${tid}`)}>
                {t("edit-trip")}
              </Button>
            </div>
          )}

          {trip.data.trip_type === enums.RideType.round_trip && !isReturn && (
            <div className="my-4">
              <Button
                className="w-full"
                color="secondary"
                variant="contained"
                onClick={() =>
                  navigate(`/trip-details/${tid}/${trip.data.rides[1].id}`, {
                    replace: true,
                  })
                }>
                {t("view-return-ride")}
              </Button>
            </div>
          )}

          {trip.data.trip_type === enums.RideType.round_trip && isReturn && (
            <div className="my-4">
              <Button
                className="w-full"
                color="secondary"
                variant="contained"
                onClick={() =>
                  navigate(`/trip-details/${tid}/${trip.data.rides[0].id}`, {
                    replace: true,
                  })
                }>
                {t("view-outbound-ride")}
              </Button>
            </div>
          )}

          {[
            enums.RideStatus.queued.toString(),
            enums.RideStatus.claimed.toString(),
          ].includes(ride.current_status) && (
            <div className="my-4">
              <Button
                className="w-full"
                color="warning"
                variant="contained"
                onClick={() => {
                  setModalIsShown(true);
                }}>
                {trip.data.trip_type === enums.RideType.round_trip
                  ? t("cancel-trip")
                  : t("cancel-ride")}
              </Button>
            </div>
          )}

          {tripDispatchDetails?.ride_attempts && tripDispatchPresent &&(
            <Card className="my-2">
              <RideDispatch data={tripDispatchDetails?.ride_attempts} />
            </Card>
          )}

          {transportationCompany?.data && transportationCompanyPresent && (
            <Card className="my-2">
              <TransportationCompany data={transportationCompany.data} />
            </Card>
          )}

          <Card className="my-2">
            <RideItem
              ride={ride}
              pickup={
                !isReturn
                  ? trip.data.pickup_location
                  : trip.data.dropoff_location
              }
              dropoff={
                !isReturn
                  ? trip.data.dropoff_location
                  : trip.data.pickup_location
              }
              trip={trip.data}
            />
          </Card>
        </div>
      )}

      {modalIsShown && (
        <CancelModal
          onConfirm={handleCancelTrip}
          rid={rid}
          tid={tid}
          loading={isCanceling}
          setModalIsShown={setModalIsShown}
          isRoundTrip={
            RideType[trip.data.trip_type as keyof typeof RideType] ===
              enums.RideType.round_trip &&
            trip.data.rides[0].current_status !== enums.RideStatus.canceled &&
            trip.data.rides[1].current_status !== enums.RideStatus.canceled
          }
          setIsCancelingBoth={setIsCancelingBoth}
        />
      )}
    </Container>
  );
};

export default TripDetailsScreen;
