//components
import RideType from "./components/RideType";
import TripReasons from "./components/TripReasons";
import Spinner from "../../../components/Spinner";

//dependencies
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//store
import {
  fetchVehicleTypes,
  fetchTransportationTypes,
  selectVehicleTypes,
  selectTransportationTypes,
} from "../../../store/booking/transportationSlice";
import {
  fetchReasonTypes,
  reasonTypes,
} from "../../../store/booking/reasonSlice";
import { selectPatient } from "../../../store/account/userSlice";
import { AppDispatch } from "../../../store";
import { selectHospital } from "../../../store/account/hospitalSlice";
import { selectPickupLocations } from "../../../store/booking/locationSlice";

//constants
import enums from "../../../constants/enums";

const ChooseRide = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  //selection
  const { data } = useSelector(selectPatient);
  const hospital_id = data.hospital_id; //hospital id
  const hospital = useSelector(selectHospital);
  const pickup = useSelector(selectPickupLocations);
  const vehicleTypes = useSelector(selectVehicleTypes); //ambulatory or wheelchair
  const transportationTypes = useSelector(selectTransportationTypes); //ride_share, taxi or medical sedan
  const reasonType = useSelector(reasonTypes); //reason types for trip

  //edit trip
  const routeParams = useParams();
  const { tid } = routeParams;

  //Modal State
  const [modalIsShown, setModalIsShown] = useState(false);
  const [rideClicked, setRideClicked] = useState("");

  //fetching
  useEffect(() => {
    if (pickup.data) {
      dispatch(
        fetchTransportationTypes({
          hospital_id: hospital_id,
          zipCode: pickup.data.zipcode,
        }),
      );
    }
    dispatch(fetchVehicleTypes(hospital_id));
    dispatch(fetchReasonTypes(hospital_id));
  }, [hospital_id, pickup.data, dispatch]);

  if (
    vehicleTypes.loading !== "finished" ||
    transportationTypes.loading !== "finished" ||
    reasonType.loading !== "finished"
  ) {
    return (
      <div className="flex flex-1 items-center">
        <div className="w-12 my-6 mx-auto">
          <Spinner />
        </div>
      </div>
    );
  } else {
    const advanced_booking_threshold =
      hospital.data?.advanced_booking_threshold;
    return (
      <>
        <div className="pt-4 pb-3 px-3 md:px-0 md:w-3/4 md:mx-auto">
          <p className="text-2xl font-extrabold text-left">
            {t("choose-your-ride")}
          </p>

          {vehicleTypes.data.includes("ambulatory") && (
            <>
              {transportationTypes.data.includes(
                enums.TransportationType.ride_share,
              ) && (
                <div
                  className="pt-6"
                  onClick={() =>
                    setRideClicked(enums.TransportationType.ride_share)
                  }>
                  <RideType
                    type={"ride_share"}
                    modelHandler={setModalIsShown}
                  />
                </div>
              )}

              {transportationTypes.data.includes(
                enums.TransportationType.taxi,
              ) && (
                <div
                  className="pt-6"
                  onClick={() => setRideClicked(enums.TransportationType.taxi)}>
                  <RideType type={"taxi"} modelHandler={setModalIsShown} />
                </div>
              )}
            </>
          )}
        </div>

        <div className="py-4 md:hidden">
          <div className="h-1 bg-gray-200 w-screen" />
        </div>

        {(vehicleTypes.data.includes(enums.TransportationType.wheelchair_van) ||
          transportationTypes.data.includes(
            enums.TransportationType.medical_sedan,
          )) && (
          <div className="px-3 md:px-0 md:pt-2 md:w-3/4 md:mx-auto">
            {hospital.data?.advanced_booking_threshold !== null && (
              <p className="text-base text-left text-secondary font-semibold">
                {t("need-assistance-please-schedule", {
                  advanced_booking_threshold,
                })}
                :
              </p>
            )}

            {/* ensure eligible for medical sedan */}
            {transportationTypes.data.includes(
              enums.TransportationType.medical_sedan,
            ) && (
              <div
                className="pt-3"
                onClick={() =>
                  setRideClicked(enums.TransportationType.medical_sedan)
                }>
                <RideType
                  type={"medical_sedan"}
                  modelHandler={setModalIsShown}
                />
              </div>
            )}

            {/* ensure eligible for wheel chair van */}
            {vehicleTypes.data.includes(
              enums.TransportationType.wheelchair_van,
            ) && (
              <div
                className="pt-3"
                onClick={() =>
                  setRideClicked(enums.TransportationType.wheelchair_van)
                }>
                <RideType
                  type={"wheelchair_van"}
                  modelHandler={setModalIsShown}
                />
              </div>
            )}
          </div>
        )}

        {modalIsShown && (
          <TripReasons
            modalHandler={setModalIsShown}
            reasons={reasonType}
            rideClicked={rideClicked}
            tid={tid}
          />
        )}
      </>
    );
  }
};

export default ChooseRide;
