const colors = {
  primary: "#1E24AE",
  text: "#0B1626",
  surface: "#EAEBF5",
  secondary: "#445879",
  subtitle: "#223551",
  disabled: "#8898AF",
  inactive: "#CCCCCC",
  success: "#4C713F",
  positive: "#659655",
  error: "#C60D22",
  negative: "#DE4A5C",
  background: "#F5F5F5",
};

export default colors;
