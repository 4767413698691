import SliderItem from "./components/SliderItem";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

interface ISliderProps {
  data: Array<any>;
}

const Slider = ({ data }: ISliderProps) => {
  const sliderList = data.map(
    (dataItem: {
      key: string;
      imageUri: any;
      imageAlt: string;
      heading: string;
      description: string;
    }) => (
      <SwiperSlide key={`slide${dataItem.key}`}>
        <SliderItem
          key={dataItem.key}
          imageUri={dataItem.imageUri}
          heading={dataItem.heading}
          description={dataItem.description}
        />
      </SwiperSlide>
    ),
  );
  return (
    <>
      <Swiper
        spaceBetween={50}
        pagination={{ el: ".swiper-custom-pagination", clickable: true }}
        modules={[Pagination]}
        className="w-full h-full">
        {sliderList}
      </Swiper>
      <div className="mt-3">
        <div className="swiper-custom-pagination" />
      </div>
    </>
  );
};

export default Slider;
