//import dependencies
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormControl, RadioGroup } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

//import theme
import theme from "../../../theme";

//import components
import ClickableCard from "./components/ClickableRadioCard";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Container from "../../../components/Container";

//import screens
import EligibilityMessage from "./EligibilityMessage";

enum BenefitType {
  healthPlan = "health-plan",
  acs = "acs-road-to-recovery",
  paratransit = "paratransit",
  other = "other",
}

const SelectYourBenefits = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  //useState
  const [modalIsShown, setModalIsShown] = useState(false); //modal
  const [value, setValue] = useState(""); //radio value
  const [buttonDisabled, setButtonDisabled] = useState(true); //button enabled/disabled

  //manage button
  useEffect(() => {
    if (Object.values(BenefitType).some((v) => v === value)) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [value]);

  //set value when radio changes
  const radioChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  //user input handler
  const submitHandler = () => {
    value === BenefitType.other ? setModalIsShown(true) : navigate("/register");
  };

  return (
    <>
      <Container>
        <ThemeProvider theme={theme}>
          <div className="w-full md:w-80 mx-auto">
            <FormControl className="w-full">
              <p className="font-extrabold text-lg text-text my-6">
                {t("who-is-providing-your-rides")}
              </p>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={value}
                onChange={radioChangeHandler}>
                <div className="w-full mx-auto h-full">
                  <div className="pb-2 pt-4">
                    <ClickableCard
                      selected={value === BenefitType.healthPlan}
                      value={BenefitType.healthPlan}
                      label={t("health-plan")}
                      onClick={() => {
                        setValue(BenefitType.healthPlan);
                      }}
                    />
                  </div>

                  <div className="py-2">
                    <ClickableCard
                      selected={value === BenefitType.acs}
                      value={BenefitType.acs}
                      label={t("acs-road-to-recovery")}
                      onClick={() => {
                        setValue(BenefitType.acs);
                      }}
                    />
                  </div>

                  <div className="py-2">
                    <ClickableCard
                      selected={value === BenefitType.paratransit}
                      value={BenefitType.paratransit}
                      label={t("paratransit")}
                      onClick={() => {
                        setValue(BenefitType.paratransit);
                      }}
                    />
                  </div>

                  <div className="pt-2">
                    <ClickableCard
                      selected={value === BenefitType.other}
                      value={BenefitType.other}
                      label={t("other")}
                      onClick={() => {
                        setValue(BenefitType.other);
                      }}
                    />
                  </div>
                  <div className="h-24" />
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        </ThemeProvider>
      </Container>

      <div>
        {modalIsShown && (
          <Modal onHideModal={() => setModalIsShown(false)}>
            <EligibilityMessage iconType="info" />
            <div className="mt-6">
              <Button
                className="w-80"
                color="primary"
                variant="contained"
                onClick={() => setModalIsShown(false)}>
                {t("back")}
              </Button>
            </div>
          </Modal>
        )}
      </div>

      <div className="bg-gradient-to-t from-background via-background to-background/50 fixed bottom-0 left-0 right-0 p-6">
        <Button
          className="w-80"
          color="primary"
          variant="contained"
          disabled={buttonDisabled}
          onClick={submitHandler}
          icon={<i className="icon-arrow-right" />}>
          {t("continue")}
        </Button>
      </div>
    </>
  );
};

export default SelectYourBenefits;
