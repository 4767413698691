import React, { useContext, useEffect } from "react";
import { isDev } from "../../utilities/isDev";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { selectPatient } from "../../store/account/userSlice";
import { useSelector } from "react-redux";

import { CLIENT_TOKEN, APPLICATION_ID, ENV } from "../../os/env";

import { createContext } from "react";

if (CLIENT_TOKEN && !isDev) {
  datadogLogs.init({
    clientToken: CLIENT_TOKEN,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: ENV,
    service: "rider-web",
    site: "datadoghq.com",
  });
}

if (APPLICATION_ID && CLIENT_TOKEN && !isDev) {
  datadogRum.init({
    applicationId: APPLICATION_ID,
    clientToken: CLIENT_TOKEN,
    service: "rider-web",
    env: ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, // if not included, the default is 100
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    allowedTracingUrls: [/https:\/\/app\.rideroundtrip\.com\/api\/v3/],
  });
}

const debug = (...args: any) => {
  isDev ? console.debug(...args) : datadogLogs.logger.debug(args);
};

const log = (...args: any) => {
  isDev ? console.log(args) : datadogLogs.logger.debug(args);
};

const info = (...args: any) => {
  isDev ? console.info(args) : datadogLogs.logger.debug(args);
};

const warn = (...args: any) => {
  isDev ? console.warn(args) : datadogLogs.logger.warn(args);
};

const error = (...args: any) => {
  isDev ? console.error(args) : datadogLogs.logger.error(args);
};

const ctx = {
  error,
  debug,
  info,
  log,
  warn,
};

const LogContext = createContext(ctx);

const LogProvider = ({ children }: { children: React.ReactNode }) => {
  const patient = useSelector(selectPatient);

  useEffect(() => {
    if (isDev) {
      return;
    }

    datadogRum.startSessionReplayRecording();
  }, []);

  useEffect(() => {
    if (isDev) {
      return;
    }

    if (patient?.data?.user_id) {
      datadogRum.setUser({
        id: patient.data.user_id,
        name: patient.data.full_name,
      });
    }
  }, [patient?.data?.full_name, patient?.data?.user_id]);

  return <LogContext.Provider value={ctx}>{children}</LogContext.Provider>;
};

const useLogger = () => useContext(LogContext);

export { LogProvider, useLogger };
