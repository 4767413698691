import colors from "../constants/colors";
import { createTheme } from "@mui/material/styles";
import { Shadows } from "@mui/material/styles/shadows";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    dashed: true;
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: React.CSSProperties["color"];
    };
  }

  interface PaletteColor {
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }

  interface ThemeOptions {
    status: {
      danger: React.CSSProperties["color"];
    };
  }
}

//mui theme

const theme = createTheme({
  shadows: Array(25).fill("none") as Shadows,
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            fontWeight: "bold",
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            fontWeight: "bold",
          },
        },
      ],
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "13px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        sx: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
  },
  status: {
    danger: colors.error.concat("1A"), //10% opacity
  },
  typography: {
    fontFamily: ["\"Source Sans 3\"", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "#FFF",
    },
    secondary: {
      main: colors.primary.concat("0D"), //5% opacity
      contrastText: colors.primary,
    },
    //default error colour (excluding button)
    error: {
      main: colors.error,
      contrastText: colors.error,
    },
    //error colour for button (under warning)
    warning: {
      main: colors.error.concat("1A"), //10% opacity
      contrastText: colors.error,
    },
    info: {
      main: "#FFF",
      contrastText: "#000000",
    },
    background: {
      paper: "#000000",
    },
  },
});

export default theme;
