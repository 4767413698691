import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiService from "../../services/api";
import { getErrorMessage } from "../../utilities/helpers";

export const fetchBenefits = createAsyncThunk(
  "account/fetchBenefits",
  async (patient_id: string, { rejectWithValue }) => {
    try {
      const b = await apiService.getPatientBenefits(patient_id);
      // Get Active Benefit
      let active = null;
      b.forEach((item: any) => {
        const validEffectiveDate =
          moment().diff(item.effective_date, "days") > 0;
        const validExpirationDate =
          moment().diff(item.expiration_date, "days") < 0 ||
          item.expiration_date === null;
        if (validEffectiveDate && validExpirationDate) {
          active = item;
        }
      });

      return active;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

interface SliceState {
  loading: "idle" | "loading" | "finished";
  data: any | null;
  error: String | null;
}

const initialState: SliceState = {
  loading: "idle",
  data: null,
  error: null,
};

const benefitsSlice = createSlice({
  name: "account/benefits",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBenefits.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchBenefits.rejected, (state, action) => {
        state.loading = "finished";
        state.error = getErrorMessage(action.error);
      })
      .addCase(fetchBenefits.fulfilled, (state, action) => {
        state.loading = "finished";
        state.data = action.payload ? action.payload : null;
      });
  },
});

export const selectBenefits = ({ account }: any) => account.benefits;
export const benefitsActions = benefitsSlice.actions;
export default benefitsSlice.reducer;
