import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const listVariants = {
  hidden: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
  visible: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: {
    opacity: 0,
    x: -16,
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const NavLinks = ({
  styling,
  onShowModal,
  hideBurgerMenu,
  onboarding,
}: any) => {
  //navigation
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <motion.ul
      variants={listVariants}
      initial="hidden"
      animate="visible"
      className={clsx(["text-text", styling])}
      transition={{ duration: 1 }}>
      {!onboarding && (
        <>
          <motion.li
            variants={itemVariants}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}>
            <button
              className="hover:text-primary active:text-primary text-text flex flex-col items-center"
              onClick={() => (
                <>
                  {hideBurgerMenu && hideBurgerMenu()}
                  {navigate("/")}
                </>
              )}>
              <i className="icon-home text-4xl" />
              <p className="text-base">{t("my-trips")}</p>
            </button>
          </motion.li>

          <motion.li
            variants={itemVariants}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}>
            <button
              className="hover:text-primary active:text-primary text-text flex flex-col items-center"
              onClick={() => (
                <>
                  {hideBurgerMenu && hideBurgerMenu()}
                  {navigate("/book-trip")}
                </>
              )}>
              <i className="icon-book-ride text-4xl" />
              <p className="text-base">{t("book-trip")}</p>
            </button>
          </motion.li>

          <motion.li
            variants={itemVariants}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}>
            <button
              className="hover:text-primary active:text-primary text-text flex flex-col items-center"
              onClick={() => (
                <>
                  {hideBurgerMenu && hideBurgerMenu()}
                  {navigate("/profile")}
                </>
              )}>
              <i className="icon-profile text-4xl" />
              <p className="text-base">{t("my-account")}</p>
            </button>
          </motion.li>
        </>
      )}

      <motion.li
        variants={itemVariants}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}>
        <button
          className="hover:text-primary active:text-primary text-text flex flex-col items-center"
          onClick={onShowModal}>
          <i className="icon-logout text-4xl" />
          <p className="text-base">{t("log-out")}</p>
        </button>
      </motion.li>
    </motion.ul>
  );
};

export default NavLinks;
