import enums from "../../constants/enums";

interface ITagIconProp {
  type: keyof typeof enums.RideType | string;
}

const TagIcon = ({ type }: ITagIconProp) => {
  if (type === enums.RideType.one_way) {
    return <i className={"icon-oneway text-3xl -my-0.5"} />;
  }
  // if (type === enums.RideType.repeating_trip) {
  //     return <i className={'icon-repeat text-3xl -my-0.5'}/>
  // }
  if (type === enums.RideType.round_trip) {
    return <i className={"icon-return text-3xl -my-0.5"} />;
  }
  return <i className={"text-3xl -my-0.5"} />;
};

export default TagIcon;
