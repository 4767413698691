import { Card } from "@mui/material";
import clsx from "clsx";
import PaymentIcon from "../../PaymentIcon";
import { IPaymentItemProps } from "../PaymentCardList";
import ExpiredUI from "../UI";

interface INonClickableCardProps {
  card: IPaymentItemProps;
  expired: boolean;
  clickable?: boolean;
}

const NonClickableCard = ({
  card,
  expired,
  clickable,
}: INonClickableCardProps) => {
  return (
    <div className={clsx(["pt-3 px-4", clickable && "pt-0.5 px-0"])}>
      <Card elevation={0}>
        <div
          className={clsx([
            "border rounded-md flex items-center justify-center p-6",
            expired && "rounded-b-none",
            clickable && "border-0 p-0",
          ])}>
          <div className="flex flex-1">
            <PaymentIcon type={card.brand} />
            <p className="text-lg pl-3">•••• {card.last4}</p>
          </div>
          {/* <div className='flex-1 text-right'><i className='icon-trash text-5xl'/></div> */}
        </div>
        {/* IF EXPIRED */}
        {expired && !clickable && (
          <ExpiredUI exp_month={card.exp_month} exp_year={card.exp_year} />
        )}
      </Card>
    </div>
  );
};

export default NonClickableCard;
