import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api";
import { getErrorMessage } from "../../utilities/helpers";
import enums from "../../constants/enums";

export class PayerValidationError extends Error {
  constructor(message: string) {
    super(message); // (1)
    this.name = "PayerValidationError"; // (2)
  }
}

export const fetchPayer = createAsyncThunk(
  "account/fetchPayer",
  async (hospital_id: string) => {
    const res = await apiService.getHospitalPayer(hospital_id);

    if (!Object.values(enums.PayerTypes).includes(res.category)) {
      throw new PayerValidationError(
        "Unsupported payer type. Please contact support",
      );
    }

    return res;
  },
);

interface SliceState {
  loading: "idle" | "loading" | "finished";
  data: any | null;
  error: String | null;
}

const initialState: SliceState = {
  loading: "idle",
  data: null,
  error: null,
};

const payerSlice = createSlice({
  name: "account/payer",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPayer.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchPayer.rejected, (state, action) => {
        state.loading = "finished";
        state.error = getErrorMessage(action.error);
      })
      .addCase(fetchPayer.fulfilled, (state, action) => {
        state.loading = "finished";
        state.data = action.payload ? action.payload : null;
      });
  },
});

export const selectPayer = ({ account }: any) => account.payer;
export const payerActions = payerSlice.actions;
export default payerSlice.reducer;
