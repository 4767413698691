import * as React from "react";
import ErrorScreen from "./ErrorScreen";
import * as Sentry from "@sentry/react-native";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(_error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error(`ErrorBoundary caught an error: ${error}, ${info}`);
    Sentry.captureException(error);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorScreen onClick={() => window.location.replace("/")} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
