import { FormControl, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Spinner from "../../../components/Spinner";
import StripeCheckout from "../../../components/StripeCheckout";
import PaymentCardList from "../../../components/PaymentCardList";
import { ITripIntent } from "../../../interfaces/Trip";
import { AppDispatch } from "../../../store";
import {
  fetchPaymentCards,
  selectPayment,
} from "../../../store/account/paymentSlice";
import { selectPatient } from "../../../store/account/userSlice";
import {
  selectEditTrip,
  selectEditTripDetails,
  updateTrip,
} from "../../../store/booking/editSlice";
import {
  createTrip,
  selectTripIntent,
  updateTripIntent,
} from "../../../store/booking/tripSlice";
import { useTranslation } from "react-i18next";

const CardSelection = () => {
  //selection
  const { data, loading } = useSelector(selectPatient);
  const patient_id = data.id; //patient id
  const payment = useSelector(selectPayment);
  const tripIntent = useSelector(selectTripIntent);
  const editTrip = useSelector(selectEditTrip);
  const editTripDetails = useSelector(selectEditTripDetails);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  //edit trip
  const routeParams = useParams();
  const { tid } = routeParams;

  useEffect(() => {
    dispatch(fetchPaymentCards(patient_id));
  }, [patient_id, dispatch]);

  //states
  const [value, setValue] = useState(""); //radio value
  const [showModal, setShowModal] = useState(false); //modal state

  //hide modal when new payment card is added
  useEffect(() => {
    setShowModal(false);
  }, [payment]);

  //set value when radio changes
  const radioChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const paymentHandler = () => {
    //update trip intent
    let updateTripIntentPayment: ITripIntent = {
      payment_method_id: value,
      id: tripIntent.data.id,
    };

    dispatch(updateTripIntent(updateTripIntentPayment))
      .unwrap()
      .then(() => {
        if (tid) {
          dispatch(
            updateTrip({
              trip_id: editTrip.data.id,
              trip_intent_id: tripIntent.data.id,
            }),
          )
            .unwrap()
            .then(() => {
              navigate(`../review-booking/success/${tid}`);
            })
            .catch((error: any) => {
              //return error message
              console.error("Error Editing Trip: " + error);
              navigate(`../review-booking/error/${tid}`);
            });
        } else {
          dispatch(createTrip(tripIntent.data.id))
            .unwrap()
            .then(() => {
              navigate("../review-booking/success");
            })
            .catch((error: any) => {
              console.error("Error Adding Trip: " + error);
              //save error info to a redux store to access and display to user?
              navigate("../review-booking/error");
            });
        }
      })
      .catch((error: any) => {
        console.error("Error Adding Payment Intent: " + error);
        navigate("../review-booking/error");
      });
  };

  if (payment.loading !== "finished" || loading !== "finished") {
    return (
      <div className="flex flex-1 items-center">
        <div className="w-12 my-6 mx-auto">
          <Spinner />
        </div>
      </div>
    );
  } else {
    return (
      <div className="pt-4">
        <b className="text-2xl text-text">{t("card")}</b>
        <p className="text-base text-secondary pt-2">
          {t("select-one-of-your-prev-card-or-create-new")}
        </p>
        <div className="pt-4">
          {payment.data && payment?.data?.length > 0 && (
            <>
              <div>
                <div className="w-full md:w-3/4 mx-auto">
                  <FormControl className="w-full">
                    <RadioGroup value={value} onChange={radioChangeHandler}>
                      <>
                        <div className="w-full mx-auto pt-4">
                          {payment.data.map((p: any) => {
                            return (
                              <div className="py-3" key={p.id}>
                                <PaymentCardList
                                  clickable
                                  card={p.card}
                                  selected={value === p.id}
                                  onClick={() => {
                                    setValue(p.id);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="pt-6">
                  <Button
                    loading={
                      payment.loading === "loading" ||
                      tripIntent.loading === "loading" ||
                      editTrip.loading === "loading" ||
                      editTripDetails.loading === "loading"
                    }
                    className="w-80"
                    color="primary"
                    variant="contained"
                    onClick={paymentHandler}
                    disabled={
                      value === "" ||
                      payment.loading === "loading" ||
                      tripIntent.loading === "loading" ||
                      editTrip.loading === "loading" ||
                      editTripDetails.loading === "loading"
                    }>
                    {t("pay")}
                  </Button>
                </div>
                <div className="pt-4">
                  <Button
                    className="w-80"
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setShowModal(true);
                    }}>
                    {t("add-new-card")}
                  </Button>
                </div>
              </div>
              {showModal && (
                <Modal onHideModal={() => setShowModal(false)}>
                  <div className="w-72 md:w-96">
                    <StripeCheckout />
                  </div>
                </Modal>
              )}
            </>
          )}

          {(!payment?.data || payment?.data?.length === 0) && (
            <>
              <p className="font-extrabold text-md text-center text-subtitle pt-8 w-full">
                {t("you-have-no-cards")}.
              </p>

              <div className="py-6">
                <Button
                  className="w-80"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setShowModal(true);
                  }}>
                  {t("add-new-card")}
                </Button>
              </div>
              {showModal && (
                <Modal onHideModal={() => setShowModal(false)}>
                  <div className="w-72 md:w-96">
                    <StripeCheckout />
                  </div>
                </Modal>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
};

export default CardSelection;
