import { createSlice } from "@reduxjs/toolkit";

const ongoingSlice = createSlice({
  name: "trips/ongoing",
  initialState: {
    list: [],
    selected: null,
    loading: false,
  },
  reducers: {},
});

export default ongoingSlice.reducer;
