import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { useTranslation } from "react-i18next";

const DashboardEmpty = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="justify-evenly flex max-w-4xl mt-20">
      <div className="w-full">
        <img
          className="w-full sm:w-2/3 lg:w-1/2 h-auto mx-auto object-contain self-center"
          src={require("../../../assets/images/trip.png")}
          alt="Empty Dashboard"
        />
        <p className="font-extrabold text-2xl text-center text-subtitle pt-8">
          {t("no-upcoming-trips")}
        </p>
      </div>

      <div className="absolute bottom-0 py-6">
        <Button
          className="w-80"
          color="primary"
          variant="contained"
          onClick={() => navigate("/book-trip")}>
          {t("book-trip-now")}
        </Button>
      </div>
    </div>
  );
};

export default DashboardEmpty;
