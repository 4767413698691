//dependencies
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { InputAdornment } from "@mui/material";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";

//components
import Button from "../../../components/Button";
import NewTripSummary from "../../../components/NewTripSummary";
import TripType from "./components/TripType";
import Input from "../../../components/Input/InputComponent";
import AdditionalNeeds from "./components/AdditionalNeeds";
import RepeatingTrip from "./components/RepeatingTrip";
import Spinner from "../../../components/Spinner";
import DateSelector from "../../../components/DateSelector";
import TimeSelector from "../../../components/TimeSelector";
import { useNotification } from "../../../contexts/Notification";


//store
import {
  selectPickupLocations,
  selectDropoffLocations,
} from "../../../store/booking/locationSlice";
import {
  updateTripIntent,
  selectTripIntent,
} from "../../../store/booking/tripSlice";
import { AppDispatch } from "../../../store";
import { selectPatient, updatePatient } from "../../../store/account/userSlice";
import { selectHospital } from "../../../store/account/hospitalSlice";
import {
  fetchTripEstimateDistance,
  selectTripEstimateDistance,
} from "../../../store/booking/estimateSlice";
import {
  selectEditTrip,
  selectEditTripDetails,
} from "../../../store/booking/editSlice";
import { notificationPush } from "../../../store/ui/notificationSlice";

//interfaces
import {
  ITripEstimateDistanceParams,
  ITripIntent,
} from "../../../interfaces/Trip";

const RideSetup = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const notification = useNotification();

  //edit trip
  const routeParams = useParams();
  const { tid } = routeParams;

  //select required data
  const pickupLocation = useSelector(selectPickupLocations);
  const dropoffLocation = useSelector(selectDropoffLocations);
  const tripEstimate = useSelector(selectTripEstimateDistance);
  const { data } = useSelector(selectPatient);
  const hospital = useSelector(selectHospital);
  const tripIntent = useSelector(selectTripIntent);
  const editTrip = useSelector(selectEditTrip);
  const editTripDetails = useSelector(selectEditTripDetails);

  //schema
  const schema = yup
    .object({
      tripType: yup.string(),
      selectedDate: yup.string().required(),
      selectedTime: yup.string().required(),
      selectedDateReturn: yup.string(),
      selectedTimeReturn: yup.string(),
      driverInstructions: yup.string(),
      weight: yup.string(),
      provideWheelchair: yup.boolean(),
      bringOwnOxygen: yup.boolean(),
      provideCarSeat: yup.boolean(),
      anyStairs:
        yup.boolean() /*used to see if switcher is on or off to display number field for amount of stairs*/,
      stairs: yup.number().nullable(),
    })
    .required();

  //form and default values for form
  const {
    control,
    getValues,
    watch,
    resetField,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      tripType: editTrip.data?.trip_type
        ? editTrip.data.trip_type
        : tripIntent.data?.trip_type
        ? tripIntent.data?.trip_type
        : "one_way",

      selectedDate: editTrip.data?.rides[0]?.start_time
        ? moment(editTrip.data.rides[0].start_time).format("YYYY-MM-DD")
        : tripIntent.data?.outbound_ride?.start_time
        ? moment(tripIntent.data.outbound_ride.start_time).format("YYYY-MM-DD")
        : "",

      selectedTime: editTrip.data?.rides[0]?.start_time
        ? moment(editTrip.data.rides[0].start_time).format("HH:mm")
        : tripIntent.data?.outbound_ride?.start_time
        ? moment(tripIntent.data?.outbound_ride.start_time).format("HH:mm")
        : "",

      selectedDateReturn: editTrip.data?.rides[1]?.start_time
        ? moment(editTrip.data.rides[1].start_time).format("YYYY-MM-DD")
        : tripIntent.data?.return_ride?.start_time
        ? moment(tripIntent.data?.return_ride.start_time).format("YYYY-MM-DD")
        : "",

      selectedTimeReturn: editTrip.data?.rides[1]?.start_time
        ? moment(editTrip.data.rides[1].start_time).format("HH:mm")
        : tripIntent.data?.return_ride?.start_time
        ? moment(tripIntent.data?.return_ride.start_time).format("HH:mm")
        : "",

      driverInstructions: editTripDetails.data?.special_instructions
        ? editTripDetails.data.special_instructions
        : tripIntent.data?.driver_instructions
        ? tripIntent.data.driver_instructions
        : "",

      weight: editTripDetails.data?.patient_weight
        ? editTripDetails.data.patient_weight
        : data.weight !== null
        ? data.weight
        : "",

      provideWheelchair:
        (editTripDetails.data?.vehicle_type ===
          "wheelchair_no_oxygen_wheelchair" ||
          editTripDetails.data?.vehicle_type ===
            "wheelchair_oxygen_wheelchair") &&
        editTripDetails.data?.vehicle_type !==
          "wheelchair_no_oxygen_no_wheelchair" &&
        editTripDetails.data?.vehicle_type !== "wheelchair_oxygen_no_wheelchair"
          ? true
          : tripIntent.data?.provide_wheelchair
          ? true
          : false,

      bringOwnOxygen:
        (editTripDetails.data?.vehicle_type ===
          "wheelchair_oxygen_no_wheelchair" ||
          editTripDetails.data?.vehicle_type ===
            "wheelchair_oxygen_wheelchair") &&
        editTripDetails.data?.vehicle_type !==
          "wheelchair_no_oxygen_no_wheelchair" &&
        editTripDetails.data?.vehicle_type !== "wheelchair_no_oxygen_wheelchair"
          ? true
          : tripIntent.data?.oxygen_provided
          ? true
          : false,

      provideCarSeat:
        editTripDetails.data?.vehicle_type === "sedan_car_seat"
          ? true
          : tripIntent.data?.provide_carseat
          ? true
          : false,

      anyStairs:
        editTripDetails.data?.stairs > 0
          ? true
          : tripIntent.data?.stairs > 0
          ? true
          : false,

      stairs:
        editTrip.data?.vehicle_need_type === "wheelchair" &&
        editTripDetails.data?.stairs > 0
          ? editTripDetails.data.stairs
          : tripIntent.data?.vehicle_need_type === "wheelchair" &&
            tripIntent.data?.stairs > 0
          ? tripIntent.data.stairs
          : null,
    },
  });

  const w = watch(); //same as getValues but re-renders!!

  //states

  //repeating trip modal state
  const [repeatingTripModal, setRepeatingTripModal] = useState(false);

  const [repeatingTripsFilled, setRepeatingTripsFilled] = useState(false);

  //repeating trip details state
  const [repeatingTrip, setRepeatingTrip] = useState({
    startDate: tripIntent.data?.schedule?.start_time
      ? tripIntent.data.schedule.start_time.split("T")[0]
      : "",
    repeatEvery: tripIntent.data?.schedule?.interval
      ? tripIntent.data?.schedule?.interval
      : 0,
    occurance: tripIntent.data?.schedule?.rule
      ? tripIntent.data?.schedule?.rule === "daily"
        ? "day(s)"
        : "week(s)"
      : "week(s)",
    selectDays: {
      selectedDate: tripIntent.data?.schedule?.days
        ? {
            Monday: tripIntent.data?.schedule?.days.includes("Monday")
              ? true
              : false,
            Tuesday: tripIntent.data?.schedule?.days.includes("Tuesday")
              ? true
              : false,
            Wednesday: tripIntent.data?.schedule?.days.includes("Wednesday")
              ? true
              : false,
            Thursday: tripIntent.data?.schedule?.days.includes("Thursday")
              ? true
              : false,
            Friday: tripIntent.data?.schedule?.days.includes("Friday")
              ? true
              : false,
            Saturday: tripIntent.data?.schedule?.days.includes("Saturday")
              ? true
              : false,
            Sunday: tripIntent.data?.schedule?.days.includes("Sunday")
              ? true
              : false,
          }
        : {},
    }, //see how to do this for dates
    endDate: tripIntent.data?.schedule?.end_time
      ? tripIntent.data.schedule.end_time.split("T")[0]
      : "",
  });

  //if anyStairs is false and stairs is greater than 0, revert
  useEffect(() => {
    if (!w.anyStairs && tripIntent.data?.vehicle_need_type === "wheelchair") {
      resetField("stairs");
    }
  }, [w.anyStairs]); //eslint-disable-line

  //booking threshold date/time
  const bookingThresholdDate = moment();

  //get current date/time too
  const nowDate = moment();

  //if advanced booking threshold found, amend booking threshold date/time to include this
  if (
    hospital.data.advanced_booking_threshold &&
    hospital.data.advanced_booking_threshold > 0
  ) {
    bookingThresholdDate.add(hospital.data.advanced_booking_threshold, "hours");
  }

  //minimum return date/time state
  const [minimumReturnDateTime, setMinimumReturnDateTime] = useState<Moment>();

  //calculates minimum return date/time based on outbound leg journey
  useEffect(() => {
    if (
      w.selectedTime !== "" &&
      w.selectedTime &&
      w.selectedDate !== "" &&
      w.selectedDate
    ) {
      //if origin time is asap do current time/date + 5 minutes
      const asapTime = moment(new Date()).add(5, "minutes").format("HH:mm");
      const startingTime =
        w.selectedTime !== "ASAP" ? w.selectedTime : asapTime;

      const distanceRequest: ITripEstimateDistanceParams = {
        start_time: moment(
          w.selectedDate + " " + startingTime,
          "YYYY-MM-DD HH:mm",
        ).toISOString(true),
        origin: pickupLocation.data,
        destination: dropoffLocation.data,
      };

      dispatch(fetchTripEstimateDistance(distanceRequest));
    }
  }, [
    dispatch,
    w.selectedTime,
    w.selectedDate,
    dropoffLocation.data,
    pickupLocation.data,
  ]);

  //when trip estimate received from api, set minimum return date/time
  useEffect(() => {
    if (tripEstimate.data) {
      const asapTime = moment(new Date()).add(5, "minutes").format("HH:mm");
      const startingTime =
        w.selectedTime !== "ASAP" ? w.selectedTime : asapTime;

      setMinimumReturnDateTime(
        moment(w.selectedDate + " " + startingTime, "YYYY-MM-DD HH:mm").add(
          tripEstimate.data.duration,
          "seconds",
        ),
      );
    }
  }, [tripEstimate]); //eslint-disable-line

  //submission handler
  const onSubmission = async () => {
    const d = getValues();

    //update trip intent
    let update_t: ITripIntent = {
      id: tripIntent.data?.id,
      trip_type: d.tripType,
      driver_instructions: d.driverInstructions,

      notifications: [
        {
          role: "patient",
          phone: data.primary_phone,
          sms_notifications: data.preferences.sms_notifications,
          email_notifications: false, //always false
          voice_notifications: data.preferences.voice_notifications,
          language: data.language_preference,
        },
      ],
    };

    //updating conditional sections of trip intent

    //non asap
    if (d.selectedTime !== "ASAP") {
      update_t.outbound_ride = {
        start_time: moment(
          new Date(d.selectedDate + " " + d.selectedTime),
        ).toISOString(true),
      };
    }

    //asap
    if (d.selectedTime === "ASAP") {
      const asapTime = new Date();
      asapTime.setMinutes(asapTime.getMinutes() + 5); //5 minute window enough?

      update_t.outbound_ride = {
        start_time: moment(asapTime).toISOString(true),
      };
    }

    //add return date/time if roundtrip
    if (d.tripType === "round_trip") {
      update_t.return_ride = {
        start_time: moment(
          new Date(d.selectedDateReturn + " " + d.selectedTimeReturn),
        ).toISOString(true),
      };
    }

    //add repeating trip if added
    if (repeatingTripsFilled && !tid) {
      const selectedDays = () =>
        Object.keys(repeatingTrip.selectDays.selectedDate)
          .filter(Boolean)
          .map((v) => v.toLowerCase()); // Backend doesn't accept capital letters ._.

      update_t.schedule = {
        rule: repeatingTrip.occurance === "day(s)" ? "daily" : "weekly",
        interval: repeatingTrip.repeatEvery,
        start_time: repeatingTrip.startDate,
        end_time: repeatingTrip.endDate,
        days:
          repeatingTrip.selectDays && repeatingTrip.occurance === "week(s)"
            ? selectedDays()
            : null,
        count: null,
        recurrence_end_type: "date",
        // count: 4,
        // recurrence_end_type: "occurrences",
      };
    }

    //switchers if wheelchair van
    if (tripIntent.data?.vehicle_need_type === "wheelchair") {
      update_t.oxygen_provided = d.bringOwnOxygen;
      update_t.provide_wheelchair = d.provideWheelchair;
      update_t.stairs = d.anyStairs && d.stairs > 0 ? d.stairs : undefined;
    }

    //switchers if medical_sedan
    if (tripIntent.data?.transportation_type === "medical_sedan") {
      update_t.provide_carseat = d.provideCarSeat;
    }

    //update weight on user profile if wheelchair & its been adjusted
    if (
      d.weight !== "" &&
      tripIntent.data.vehicle_need_type === "wheelchair" &&
      data.weight !== d.weight
    ) {
      dispatch(updatePatient({ id: data.id, weight: d.weight }));
    }

    //update trip intent
    dispatch(updateTripIntent(update_t))
      .unwrap()
      .then(() => {
        tid
          ? navigate(`../review-booking/${tid}`)
          : navigate("../review-booking");
      })
      .catch((error) => {
        console.error("Error Updated Trip Intent: " + error);
        navigate("/error");
      });
  };

  //notification for edit trip if original date/time is outside of booking threshold, alerts user
  useEffect(() => {
    if (tripIntent.data?.transportation_type && tid) {
      if (
        tripIntent.data.transportation_type !== "ride_share" &&
        (moment(
          moment(w.selectedDate).format("YYYY-MM-DD"),
          "YYYY-MM-DD",
        ).isBefore(bookingThresholdDate.format("YYYY-MM-DD")) ||
          moment(
            moment(
              w.selectedDate +
                " " +
                (w.selectedTime
                  ? w.selectedTime
                  : moment().add(1, "minute").format("HH:mm")),
              "YYYY-MM-DD HH:mm",
            ),
          ).isBefore(bookingThresholdDate))
      ) {
        const advanced_booking_threshold =
          hospital.data?.advanced_booking_threshold;

        dispatch(
          notificationPush({
            title: t("alert"),
            message: t("please-re-enter-outbound-date-time-booking-threshold", {
              advanced_booking_threshold,
            }),
          }),
        );
      } else if (
        tripIntent.data.transportation_type === "ride_share" &&
        (moment(
          moment(w.selectedDate).format("YYYY-MM-DD"),
          "YYYY-MM-DD",
        ).isBefore(nowDate.format("YYYY-MM-DD")) ||
          moment(
            moment(
              w.selectedDate +
                " " +
                (w.selectedTime
                  ? w.selectedTime
                  : moment().add(1, "minute").format("HH:mm")),
              "YYYY-MM-DD HH:mm",
            ),
          ).isBefore(nowDate))
      ) {
        dispatch(
          notificationPush({
            title: t("alert"),
            message: t("please-re-enter-outbound-date-time-not-past"),
          }),
        );
      }
    }
  }, [tripIntent.data?.transportation_type]); //eslint-disable-line

  //loading state
  if (
    tripIntent.loading !== "finished" ||
    dropoffLocation.loading !== "finished" ||
    pickupLocation.loading !== "finished"
  ) {
    return (
      <div className="flex flex-1 items-center">
        <div className="w-12 my-6 mx-auto">
          <Spinner />
        </div>
      </div>
    );
  } else if (tripIntent.error) { 
    console.warn("TripIntent was rejected")
    notification.notify({
      type: "error",
      title: "Error",
      message: tripIntent.error,
    })
    return (
      <div className="flex flex-1 items-center">
        <div className="w-12 my-6 mx-auto">
          <Spinner />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="pt-4">
          <NewTripSummary
            pickupLocation={pickupLocation}
            dropoffLocation={dropoffLocation}
          />
        </div>

        <Controller
          name="tripType"
          control={control}
          render={({ field }) => (
            <TripType tripTypeValue={w.tripType} {...field} />
          )}
        />

        {/* outbound selection */}
        <div className="pt-8 flex flex-col">
          <b className="text-text text-lg text-left">
            {t("lets-set-the-trip-date")}
          </b>

          <Controller
            name="selectedDate"
            control={control}
            render={({ field }) => (
              <DateSelector
                error={errors.selectedDate ? true : false}
                reset={moment(
                  moment(w.selectedDate).format("YYYY-MM-DD"),
                  "YYYY-MM-DD",
                ).isBefore(
                  tripIntent.data.transportation_type === "ride_share"
                    ? nowDate.format("YYYY-MM-DD")
                    : bookingThresholdDate.format("YYYY-MM-DD"),
                )}
                minDate={
                  tripIntent.data.transportation_type === "ride_share"
                    ? nowDate
                    : bookingThresholdDate
                }
                {...field}
              />
            )}
          />

          <b className="text-text text-lg pt-4 text-left">{t("select-time")}</b>

          <Controller
            name="selectedTime"
            control={control}
            render={({ field }) => (
              <TimeSelector
                rideshare={tripIntent.data.transportation_type === "ride_share"}
                error={errors.selectedTime ? true : false}
                reset={
                  w.selectedDate !== undefined &&
                  w.selectedDate !== null &&
                  w.selectedDate !== "" &&
                  moment(
                    w.selectedDate +
                      " " +
                      (w.selectedTime && w.selectedTime !== "ASAP"
                        ? w.selectedTime
                        : moment().add(1, "minute").format("HH:mm")),
                    "YYYY-MM-DD HH:mm",
                  ).isBefore(
                    tripIntent.data.transportation_type !== "ride_share"
                      ? bookingThresholdDate
                      : nowDate,
                  )
                }
                //if outbound date changes, check if time needs to be reset
                minTime={
                  tripIntent.data.transportation_type === "ride_share"
                    ? nowDate.format("YYYY-MM-DD") === w.selectedDate
                      ? nowDate
                      : undefined
                    : bookingThresholdDate.format("YYYY-MM-DD") ===
                      w.selectedDate
                    ? bookingThresholdDate
                    : undefined
                }
                {...field}
              />
            )}
          />
        </div>

        {/* inbound selection */}
        <div className="pt-4 flex flex-col w-full">
          {w.tripType === "round_trip" && (
            <>
              <b className="text-text text-lg text-left">
                {t("lets-set-the-return-trip-date")}
              </b>

              <Controller
                name="selectedDateReturn"
                control={control}
                render={({ field }) => (
                  <DateSelector
                    error={errors.selectedDateReturn ? true : false}
                    reset={moment(
                      moment(w.selectedDateReturn, "YYYY-MM-DD"),
                    ).isBefore(moment(w.selectedDate, "YYYY-MM-DD"))}
                    minDate={
                      w.selectedDate
                        ? moment(w.selectedDate)
                        : bookingThresholdDate
                    }
                    returnDate
                    {...field}
                  />
                )}
              />

              <b className="text-text text-lg pt-4 text-left">
                {t("select-the-return-time")}
              </b>

              <Controller
                name="selectedTimeReturn"
                control={control}
                render={({ field }) => (
                  <TimeSelector
                    rideshare={false}
                    error={errors.selectedTimeReturn ? true : false}
                    reset={
                      w.selectedTimeReturn !== "" &&
                      (moment(
                        moment(
                          w.selectedDateReturn + " " + w.selectedTimeReturn,
                          "YYYY-MM-DD HH:mm",
                        ),
                      ).isBefore(
                        minimumReturnDateTime !== undefined
                          ? minimumReturnDateTime
                          : moment(
                              w.selectedDate + " " + w.selectedTime,
                              "YYYY-MM-DD HH:mm",
                            ),
                      ) ||
                        (moment(w.selectedDate).format("YYYY-MM-DD") ===
                          moment(w.selectedDateReturn).format("YYYY-MM-DD") &&
                          moment(
                            moment(
                              w.selectedDateReturn + " " + w.selectedTimeReturn,
                              "YYYY-MM-DD HH:mm",
                            ),
                          ).isBefore(
                            minimumReturnDateTime !== undefined
                              ? minimumReturnDateTime
                              : moment(
                                  w.selectedDate + " " + w.selectedTime,
                                  "YYYY-MM-DD HH:mm",
                                ),
                          )))
                    }
                    minTime={
                      moment(
                        minimumReturnDateTime !== undefined
                          ? minimumReturnDateTime.format("YYYY-MM-DD")
                          : w.selectedDate,
                        "YYYY-MM-DD",
                      ).format("YYYY-MM-DD") !== w.selectedDateReturn
                        ? undefined
                        : minimumReturnDateTime !== undefined
                        ? minimumReturnDateTime
                        : bookingThresholdDate
                    }
                    returnDate
                    {...field}
                  />
                )}
              />
            </>
          )}
        </div>

        {/*render if wheelchair van or medical sedan */}
        {(tripIntent.data.transportation_type === "medical_sedan" ||
          tripIntent.data.vehicle_need_type === "wheelchair") && (
          <>
            <div className="pt-4">
              <AdditionalNeeds
                control={control}
                transportationType={
                  tripIntent.data.vehicle_need_type === "wheelchair"
                    ? "wheelchair"
                    : "medical_sedan"
                }
              />
            </div>
            {w.anyStairs &&
              tripIntent.data.vehicle_need_type === "wheelchair" && (
                <Controller
                  name="stairs"
                  control={control}
                  render={({ field }) => (
                    <Input
                      margin="normal"
                      fullWidth
                      label={t("how-many-stairs")}
                      type={"number"}
                      error={errors.stairs ? true : false}
                      {...field}
                      inputProps={{
                        min: 0,
                      }}
                      onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                    />
                  )}
                />
              )}
          </>
        )}

        {tripIntent.data.vehicle_need_type === "wheelchair" && (
          <Controller
            name="weight"
            control={control}
            render={({ field }) => (
              <Input
                margin="normal"
                fullWidth
                label={t("weight")}
                error={errors.weight ? true : false}
                type={"number"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">lbs</InputAdornment>
                  ),
                }}
                {...field}
              />
            )}
          />
        )}

        <Controller
          name="driverInstructions"
          control={control}
          render={({ field }) => (
            <Input
              margin="normal"
              fullWidth
              label={t("driver-instructions-optional")}
              error={errors.driverInstructions ? true : false}
              multiline
              {...field}
            />
          )}
        />

        {!repeatingTripsFilled && !tid && (
          <div
            className="cursor-pointer flex text-left text-primary items-center font-semibold pt-2"
            onClick={() => {
              setRepeatingTripModal(true);
            }}>
            <p className="uppercase">{t("add-repeating-trip")}</p>
            <i className="icon-chev-right text-xl" />
          </div>
        )}

        {repeatingTripsFilled && (
          <>
            <b className="text-text text-left text-lg pt-2 pb-3">
              {t("repeating-trip")}
            </b>
            <div className="flex border border-bg p-4">
              <div className="text-left text-text text-base">
                <b>{t("occurs-every")}:</b>
                <div className="flex">
                  {repeatingTrip.selectDays &&
                  repeatingTrip.occurance === "week(s)" ? (
                    <p>
                      {Object.keys(repeatingTrip.selectDays.selectedDate)
                        .filter(
                          (k) =>
                            repeatingTrip.selectDays.selectedDate[
                              k as keyof typeof repeatingTrip.selectDays.selectedDate
                            ],
                        )
                        .map((k) => {
                          return t(k);
                        })
                        .join(", ")}
                    </p>
                  ) : (
                    <p>
                      {repeatingTrip.repeatEvery} {t("day(s)")}
                    </p>
                  )}
                </div>
                <p>
                  {t("until")}:{" "}
                  {moment(repeatingTrip.endDate).format("YYYY-MM-DD")}
                </p>
              </div>
              <div
                className="ml-auto my-auto rounded-full w-10 h-10 bg-surface grid place-items-center cursor-pointer"
                onClick={() => {
                  setRepeatingTripModal(true);
                }}>
                <i className="icon-pen text-4xl" />
              </div>
            </div>
          </>
        )}

        <div className="my-4">
          <Button
            className="w-80 my-2"
            color="primary"
            variant="contained"
            onClick={onSubmission}
            disabled={
              !isValid ||
              (w.tripType === "round_trip" &&
                (w.selectedDateReturn === "" ||
                  !w.selectedDateReturn ||
                  w.selectedTimeReturn === "" ||
                  !w.selectedTimeReturn)) ||
              (w.anyStairs &&
                tripIntent.data?.vehicle_need_type === "wheelchair" &&
                w.stairs <= 0)
            }
            loading={
              data.loading === "loading" || tripIntent.loading === "loading"
            }>
            {t("continue")}
            <div className="absolute right-1.5 bottom-1.5 top-1.5  text-2xl bg-white/25 rounded">
              <i className="icon-arrow-right" />
            </div>
          </Button>
        </div>
        {repeatingTripModal && (
          <RepeatingTrip
            setModalIsShown={setRepeatingTripModal}
            setDetails={setRepeatingTrip}
            details={repeatingTrip} //only to auto-fill if user wants to edit repeating trip
            minDate={
              new Date(
                w.selectedDate
                  ? moment(w.selectedDate).toISOString(true)
                  : bookingThresholdDate.toISOString(true),
              )
            }
            setRepeatingTripsFilled={setRepeatingTripsFilled}
            autoFillStartDate={w.selectedDate ? true : false}
          />
        )}
      </>
    );
  }
};

export default RideSetup;
