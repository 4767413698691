import { useTranslation } from "react-i18next";
import ProgressBar from "../../../../../components/ProgressBar";

interface IStyledProgressBar {
  progress: number;
  step: number;
}
const StyledProgressBar = ({ progress, step }: IStyledProgressBar) => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex">
      <div className="w-full my-auto">
        <ProgressBar progress={progress} variant="determinate" />
      </div>
      <p className="pl-2 text-disabled text-sm whitespace-nowrap font-extrabold uppercase">
        {t("step-progress-bar", { step })}
      </p>
    </div>
  );
};

export default StyledProgressBar;
