import MessagePrompt from "../../../components/MessagePrompt";
import { useTranslation } from "react-i18next";

interface IEligibilityMessageProps {
  iconType: "success" | "info";
  cannotFind?: boolean;
}

const EligibilityMessage = ({
  iconType,
  cannotFind,
}: IEligibilityMessageProps) => {
  const { t } = useTranslation();
  return (
    <div className="my-auto">
      <MessagePrompt iconType={iconType}>
        <div className="flex justify-center">
          <div className="w-80 text-center">
            <p className="text-2xl text-text font-extrabold py-4">
              {t("were-sorry")}
            </p>
            {!cannotFind && (
              <p className="text-base text-subtitle">{t("ineligible")}</p>
            )}

            {cannotFind && (
              <>
                <p className="text-base text-subtitle">{t("cannot-find")}</p>
              </>
            )}
          </div>
        </div>
      </MessagePrompt>
    </div>
  );
};

export default EligibilityMessage;
