import * as Sentry from "@sentry/react-native";
import moment from "moment";
import { TripReason } from "../interfaces/TripReason";

export function getErrorMessage(error: any): string {
  return String(error?.message || error);
}

export function reportError({ message }: { message: string }) {
  Sentry.captureException(message);
}

export function captureError(error: unknown) {
  Sentry.captureException(new Error(JSON.stringify(error)));
}

export function alphabeticalSort(a: TripReason, b: TripReason) {
  if (a.name < b.name) {
    return -1;
  } else if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export const getDayName = (date: Date, type: "short" | "long" = "short") => {
  const days =
    type === "short"
      ? ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"]
      : [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
  const d = new Date(date);
  return days[d.getDay()];
};

export const formatAMPM = (date: Date) => {
  return moment(date).format("hh:mm A");
};

export const uuid = () => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

export const convertTime12to24 = (time12h: string) => {
  let [time, modifier] = time12h.split(" ");

  let [hours, minutes]: any = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

export const returnCardType = (type: string) => {
  if (type === "mastercard") {
    return "mastercard";
  }
  if (type === "mastero") {
    return "maestro";
  }
  if (type === "visa" || type === "visaelectron") {
    return "visa";
  }
  if (type === "dinersclub") {
    return "diners-club";
  }
  if (type === "diners") {
    return "diners";
  }
  if (type === "amex" || type === "american-express") {
    return "amex";
  }
  if (type === "jcb") {
    return "jcb";
  }
  if (type === "discover") {
    return "discover";
  }
  if (type === "unionpay") {
    return "unionpay";
  }
  return "generic";
};
