//import React from 'react'
import {
  Visa,
  DinersClub,
  Diners,
  Mastercard,
  Maestro,
  Amex,
  VisaElectron,
  Jcb,
  Discover,
  UnionPay,
} from "react-pay-icons";

interface IPaymentIconProps {
  type: string;
}

export default function PaymentIcon({ type }: IPaymentIconProps) {
  if (type === "mastercard") {
    return <Mastercard className="w-12" />;
  }
  if (type === "mastero") {
    return <Maestro className="w-12" />;
  }
  if (type === "visa") {
    return <Visa className="w-12" />;
  }
  if (type === "visaelectron") {
    return <VisaElectron className="w-12" />;
  }
  if (type === "dinersclub") {
    return <DinersClub className="w-12" />;
  }
  if (type === "diners") {
    return <Diners className="w-12" />;
  }
  if (type === "amex") {
    return <Amex className="w-12" />;
  }
  if (type === "jcb") {
    return <Jcb className="w-12" />;
  }
  if (type === "discover") {
    return <Discover className="w-12" />;
  }
  if (type === "unionpay") {
    return <UnionPay className="w-12" />;
  }

  return (
    <div className="w-12 bg-surface h-8">
      <i className="icon-card text-2xl text-secondary" />
    </div>
  );
}
