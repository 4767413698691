import { Routes, Route, Navigate } from "react-router-dom";
import BenefitsScreen from "./screens/BenefitsScreen";
import EditProfileScreen from "./screens/EditProfileScreen";
import HelpCenterScreen from "./screens/HelpCenterScreen";
import ProfileNavigation from "./screens/ProfileNavigation";
import SavedPaymentCardsScreen from "./screens/SavedPaymentCardsScreen";
import TripHistoryScreen from "./screens/TripHistoryScreen";
import DeleteAccountScreen from "./screens/DeleteAccountScreen";

const ProfileScreen = () => {
  return (
    <Routes>
      <Route path="/" element={<ProfileNavigation />} />

      <Route path="/edit-profile" element={<EditProfileScreen />} />

      <Route path="/trip-history" element={<TripHistoryScreen />} />

      <Route
        path="/saved-payment-cards"
        element={<SavedPaymentCardsScreen />}
      />

      <Route path="/benefits" element={<BenefitsScreen />} />

      <Route path="/help-center" element={<HelpCenterScreen />} />

      <Route path="/delete-account" element={<DeleteAccountScreen />} />

      <Route path="*" element={<Navigate to="./" />} />
    </Routes>
  );
};

export default ProfileScreen;
