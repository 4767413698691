//import dependencies
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//import components
import DashboardEmpty from "./components/DashboardEmpty";
import DashboardTrips from "./components/DashboardTrips";
import RideBenefits from "../../components/RideBenefits";
import Spinner from "../../components/Spinner";
import Button from "../../components/Button";
import Container from "../../components/Container";

//import store
import {
  fetchUpcomingTrips,
  refreshUpcomingTrips,
  selectUpcomingData,
} from "../../store/trips/upcomingSlice";
import { AppDispatch } from "../../store";
import { selectBenefits } from "../../store/account/benefitsSlice";
import { selectPayer } from "../../store/account/payerSlice";
import { selectHospital } from "../../store/account/hospitalSlice";

const DashboardScreen = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { list, loading, page, more } = useSelector(selectUpcomingData);
  const benefits = useSelector(selectBenefits);
  const payer = useSelector(selectPayer);
  const hospital = useSelector(selectHospital);
  const { t } = useTranslation();

  const fetchTripData = () => {
    try {
      // TODO: This is a stopgap solution. Must implement websockets or cache invalidation.
      // https://linear.app/roundtrip/issue/RT-1112/identify-interim-solution
      if (loading === "finished") {
        dispatch(refreshUpcomingTrips(1));
        return;
      }

      if (loading !== "loading") {
        dispatch(fetchUpcomingTrips(page));
      }
    } catch (e) {
      console.error(e);
      navigate("/error");
    }
  };

  useEffect(() => {
    fetchTripData();
  }, [dispatch]); // eslint-disable-line

  if ((loading === "loading" || loading === "idle") && page === 1) {
    return (
      <div className="flex flex-1 items-center">
        <div className="w-12 my-6 mx-auto">
          <Spinner />
        </div>
      </div>
    );
  } else {
    return (
      <Container>
        <div className="bg-background flex-1 grid">
          <div className="mx-auto container max-w-2xl pt-4">
            <RideBenefits
              payer={payer}
              benefits={benefits}
              hospital={hospital}
              dashboard
            />
            {!list.length ? (
              <DashboardEmpty />
            ) : (
              <InfiniteScroll
                dataLength={list.length} //this is important field to render the next data
                next={fetchTripData}
                hasMore={more}
                loader={
                  <div className="w-12 my-6 mx-auto">
                    <Spinner />
                  </div>
                }
                endMessage={
                  <div className="my-2 pt-4">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => navigate("/profile/trip-history")}>
                      {t("trip-history")}
                    </Button>
                  </div>
                }>
                <DashboardTrips tripsData={list} />
              </InfiniteScroll>
            )}
          </div>
        </div>
      </Container>
    );
  }
};

export default DashboardScreen;
