//import components
import Button from "../../../components/Button";
import Card from "../../../components/Card";

//import constants
import enums, { RideType } from "../../../constants/enums";

//import dependencies
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//import interfaces
import { Trip } from "../../../interfaces/Trip";
import RideItem from "../../../components/RideItem";

interface ITripItemProps {
  tripData: Trip;
}

const TripItem = ({ tripData }: ITripItemProps) => {
  const navigate = useNavigate();
  const rideItem = tripData.rides[0];
  const { t } = useTranslation();

  let returnRide;
  if (
    RideType[tripData.trip_type as keyof typeof RideType] ===
    enums.RideType.round_trip
  ) {
    returnRide = tripData.rides[1];
  }

  return (
    <>
      <Card>
        <RideItem
          ride={rideItem}
          dropoff={tripData.dropoff_location}
          pickup={tripData.pickup_location}
          trip={tripData}
        />
        <div className="w-full">
          <div className="my-2">
            <Button
              className="w-full"
              color="primary"
              variant="outlined"
              onClick={() =>
                navigate(`/trip-details/${tripData.id}/${tripData.rides[0].id}`)
              }>
              {t("view-details")}
            </Button>
          </div>
        </div>
      </Card>

      {returnRide && (
        <RideItem
          dropoff={tripData.pickup_location}
          pickup={tripData.dropoff_location}
          collapsed
          ride={returnRide}
          trip={tripData}
        />
      )}
    </>
  );
};

export default TripItem;
