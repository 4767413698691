//import components
import PaymentMethod from "./components/PaymentMethod";
import Spinner from "../../../components/Spinner";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import Input from "../../../components/Input";
import MapContainer from "../../../components/MapContainer";
import RideItem from "../../../components/RideItem";

//import dependencies
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

//import store
import { AppDispatch } from "../../../store";
import { createTrip, selectTripIntent } from "../../../store/booking/tripSlice";
import { selectPayer } from "../../../store/account/payerSlice";
import {
  selectDropoffLocations,
  selectPickupLocations,
} from "../../../store/booking/locationSlice";
import {
  fetchTripEstimateFare,
  selectTripEstimateFare,
} from "../../../store/booking/estimateSlice";
import {
  selectEditTrip,
  selectEditTripDetails,
  updateTrip,
} from "../../../store/booking/editSlice";

const ReviewBook = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  //selection
  const tripIntent = useSelector(selectTripIntent);
  const pickupLocation = useSelector(selectPickupLocations);
  const dropoffLocation = useSelector(selectDropoffLocations);
  const payer = useSelector(selectPayer);
  const estimatedFare = useSelector(selectTripEstimateFare);
  const editTrip = useSelector(selectEditTrip);
  const editTripDetails = useSelector(selectEditTripDetails);

  //if edit trip
  const routeParams = useParams();
  const { tid } = routeParams;

  //fetch estimated fare
  useEffect(() => {
    if (payer.data?.category === "patient" && tripIntent.data.id) {
      dispatch(fetchTripEstimateFare(tripIntent.data.id));
    }
  }, [tripIntent.data, payer.data, dispatch]);

  const getEstimatedValue = (fares: Array<any>) => {
    let values = fares.map((f) => {
      return f.discounted_fare;
    });
    return values.reduce((a: number, b: number) => a + b, 0).toFixed(2);
  };

  //submission handler
  const addTrip = () => {
    if (payer.data.category === "patient") {
      tid
        ? navigate(`../review-booking/select-card/${tid}`)
        : navigate("./select-card");
    } else if (payer.data.category !== "patient") {
      if (tid) {
        dispatch(
          updateTrip({
            trip_id: editTrip.data.id,
            trip_intent_id: tripIntent.data.id,
          }),
        )
          .unwrap()
          .then(() => {
            navigate(`../review-booking/success/${tid}`);
          })
          .catch((error) => {
            console.error("Error Editing Trip: " + error);
            navigate(`../review-booking/error/${tid}`); //return error
          });
      } else {
        dispatch(createTrip(tripIntent.data.id))
          .unwrap()
          .then(() => {
            navigate("./success");
          })
          .catch((error) => {
            console.error("Error Adding Trip: " + error);
            navigate("./error"); //return error
          });
      }
    }
  };

  if (tripIntent.loading !== "finished" || payer.loading !== "finished") {
    return (
      <div className="flex flex-1 items-center">
        <div className="w-12 my-6 mx-auto">
          <Spinner />
        </div>
      </div>
    );
  } else {
    const tripOverview = {
      id: 1, //id placeholder
      transportation_type: tripIntent.data.transportation_type,
      trip_type: tripIntent.data.trip_type,
      vehicle_need_type: tripIntent.data.vehicle_need_type,
      vehicle_type: tripIntent.data.transportation_type,
      patient_id: 1, //placeholder
      rides: [tripIntent.data.outbound_ride, tripIntent.data?.inbound_ride],
      pickup_location: pickupLocation.data,
      dropoff_location: dropoffLocation.data,
    };

    return (
      <div className="pt-2 w-full md:w-3/4 mx-auto">
        <div className="text-text text-lg py-2">
          <b>{t("review-trip")}</b>
        </div>

        <div className="h-60 pt-2">
          <MapContainer
            pickup={tripOverview.pickup_location}
            dropoff={tripOverview.dropoff_location}
          />
        </div>

        <Card>
          <RideItem
            trip={tripOverview}
            ride={tripIntent.data.outbound_ride}
            pickup={tripOverview.pickup_location}
            dropoff={tripOverview.dropoff_location}
          />
          {tripIntent.data.driver_instructions !== "" && (
            <Input
              defaultValue={tripIntent.data.driver_instructions}
              margin="normal"
              fullWidth
              label={t("driver-instructions-optional")}
              multiline
              disabled
            />
          )}
        </Card>

        <PaymentMethod usePaymentCard={payer.data.category === "patient"} />

        {payer.data.category === "patient" && (
          <div className="flex pb-4">
            <>
              {estimatedFare.loading !== "finished" && (
                <Skeleton variant="rounded" width={10000} height={6} />
              )}

              {estimatedFare.data.length > 0 &&
                estimatedFare.loading === "finished" && (
                  <>
                    <b className="text-text text-left">
                      {tid ? t("new-estimated-fare") : t("estimated-fare")}
                    </b>
                    <b className="text-text ml-auto">
                      ${getEstimatedValue(estimatedFare.data)}
                    </b>
                  </>
                )}

              {estimatedFare.data.length === 0 &&
                estimatedFare.loading === "finished" && (
                  <b className="text-text mx-auto">
                    {t("unable-to-retrieve-estimated-fare")}
                  </b>
                )}
            </>
          </div>
        )}

        <div className="pb-4">
          <Button
            className="w-80"
            color="primary"
            variant="contained"
            onClick={addTrip}
            loading={
              payer.data.category !== "patient" &&
              (tripIntent.loading === "loading" ||
                editTrip.loading === "loading" ||
                editTripDetails.loading === "loading")
            }
            disabled={
              payer.data.category !== "patient" &&
              (tripIntent.loading === "loading" ||
                editTrip.loading === "loading" ||
                editTripDetails.loading === "loading")
            }>
            {t("continue")}
          </Button>
        </div>
      </div>
    );
  }
};

export default ReviewBook;
