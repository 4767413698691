interface ISearchItemProps {
  suggestion: {
    formattedSuggestion: {
      mainText: string;
      secondaryText: string;
    };
  };
}

const SearchItem = ({ suggestion }: ISearchItemProps) => {
  return (
    <div className="flex flex-1 py-3 pl-3 border-b border-surface">
      <div>
        <p className="text-base font-extrabold my-1">
          {suggestion.formattedSuggestion.mainText}
        </p>
        <p className="text-sm text-subtitle">
          {suggestion.formattedSuggestion.secondaryText}
        </p>
      </div>
      <div className="justify-center self-stretch flex my-auto ml-auto">
        <i className="icon-chev-right text-2xl" />
      </div>
    </div>
  );
};

export default SearchItem;
