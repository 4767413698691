interface ISliderItemProps {
  imageUri: any;
  heading: string;
  description: string;
}

const SliderItem = ({ imageUri, heading, description }: ISliderItemProps) => {
  return (
    <div>
      <img
        className="block mx-auto my-2 w-full sm:w-2/3 lg:w-1/2 h-full object-cover"
        alt={heading}
        src={imageUri}
      />
      <div className="pt-4">
        <h2 className="font-extrabold text-xl text-text">{heading}</h2>
        <p className="p-1 text-secondary">{description}</p>
      </div>
    </div>
  );
};

export default SliderItem;
