//import dependencies
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import { AuthProvider } from "./contexts/Auth";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react-native";
import { I18nextProvider } from "react-i18next";

//import services
import configService from "./services/config";

//import store
import store from "./store";

//import main app
import App from "./App";
import i18n from "./utilities/localisation/i18n";

//import css
import "./index.css";
import "swiper/css/bundle";

//import notification
import { NotificationProvider } from "./contexts/Notification";
import Notification from "./components/Notification";
import ErrorBoundary from "./screens/general/ErrorBoundary";
import { LogProvider } from "./contexts/Logger";

configService.init(process.env);

const AUTH0_DOMAIN = configService.get("AUTH0_DOMAIN");
const AUTH0_CLIENT_ID = configService.get("AUTH0_CLIENT_ID");
const AUTH0_AUDIENCE = configService.get("AUTH0_AUDIENCE");
const PENDO_API_KEY = configService.get("PENDO_API_KEY");
const FULL_STORY_ID = configService.get("FULL_STORY_ID");

const container = document.getElementById("root")!;
const root = createRoot(container);

Sentry.init({
  dsn: configService.get("SENTRY_URL"),
});

if (FULL_STORY_ID) {
  const script = document.createElement("script");
  script.innerHTML = `window['_fs_debug'] = false;
    window['_fs_host'] = 'fullstory.com';
    window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
    window['_fs_org'] = '${FULL_STORY_ID}';
    window['_fs_namespace'] = 'FS';
    (function(m,n,e,t,l,o,g,y){
      if (e in m) {if(m.console && m.console.log) {
       m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
      } return;}
      g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
      o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
      y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
      g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};
      g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
      g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
      g.log = function(a,b) { g("log", [a,b]) };
      g.consent=function(a){g("consent",!arguments.length||a)};
      g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
      g.clearUserCookie=function(){};
    })(window,document,window['_fs_namespace'],'script','user');`;
  document.head.appendChild(script);
}

if (PENDO_API_KEY) {
  const script = document.createElement("script");
  script.innerHTML = `(function(apiKey){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    })('${PENDO_API_KEY}');`;
  document.head.appendChild(script);
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <ErrorBoundary>
          <Provider store={store}>
            <LogProvider>
              <NotificationProvider>
                <Auth0Provider
                  domain={AUTH0_DOMAIN}
                  clientId={AUTH0_CLIENT_ID}
                  audience={AUTH0_AUDIENCE}
                  redirectUri={window.location.origin}
                  cacheLocation="localstorage"
                  useRefreshTokens>
                  <AuthProvider audience={AUTH0_AUDIENCE}>
                    <App />
                    <Notification />
                  </AuthProvider>
                </Auth0Provider>
              </NotificationProvider>
            </LogProvider>
          </Provider>
        </ErrorBoundary>
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
