//import components
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Container from "../../../components/Container";

//import screens
import EligibilityMessage from "./EligibilityMessage";

//import dependencies
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

//import stores
import { AppDispatch } from "../../../store";
import {
  registerPatient,
  selectPatient,
} from "../../../store/account/userSlice";
import { useAuth } from "../../../contexts/Auth";

const schema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    memberId: yup.string().required(),
  })
  .required();

const MembershipLookup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const patientData = useSelector(selectPatient);
  const { loading } = useAuth();
  const { t } = useTranslation();

  const [modalIsShown, setModalIsShown] = useState(false);

  const {
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      memberId: "",
    },
  });

  const onSubmit = async () => {
    const values = getValues();
    dispatch(
      registerPatient({
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        memberId: values.memberId.trim(),
      }),
    )
      .unwrap()
      .then((response) => {
        if (!response.user) {
          setModalIsShown(true);
        } else {
          navigate("./success");
        }
      })
      //if error is caught, member id is not found, show info modal
      .catch(() => {
        setModalIsShown(true);
      });
  };

  return (
    <>
      <Container>
        <div className="w-full md:w-80 mx-auto pt-6">
          <form>
            <b className="text-lg text-text">{t("membership-lookup")}</b>
            <p className="text-base pt-3 text-subtitle">
              {t("verify-eligibility")}
            </p>
            <div className="my-6">
              <div className="my-6">
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      error={errors.firstName ? true : false}
                      label={t("first-name")}
                      size="small"
                      {...field}
                    />
                  )}
                />
              </div>

              <div className="my-6">
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      error={errors.lastName ? true : false}
                      label={t("last-name")}
                      size="small"
                      {...field}
                    />
                  )}
                />
              </div>

              <div className="my-6">
                <Controller
                  name="memberId"
                  control={control}
                  render={({ field }) => (
                    <Input
                      error={errors.memberId ? true : false}
                      label={t("member-id")}
                      size="small"
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </form>
        </div>
      </Container>

      <div className="bg-gradient-to-t from-background via-background to-background/50 bottom-0 left-0 right-0 p-6">
        <Button
          className="w-full md:w-80"
          color="primary"
          disabled={!isValid || loading || patientData.loading === "loading"}
          variant="contained"
          onClick={onSubmit}
          loading={loading || patientData.loading === "loading"}
          icon={<i className="icon-arrow-right" />}>
          {t("continue")}
        </Button>
      </div>

      <div>
        {modalIsShown && (
          <Modal
            onHideModal={() => {
              setModalIsShown(false);
            }}>
            <EligibilityMessage iconType="info" cannotFind={true} />
            <div className="mt-6">
              <Button
                className="w-80"
                color="primary"
                variant="contained"
                onClick={() => setModalIsShown(false)}>
                {t("back")}
              </Button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default MembershipLookup;
