import moment from "moment";
import { useEffect, useState } from "react";
import ClickableCard from "./Clickable";
import NonClickableCard from "./NonClickable/NonClickableCard";

export interface IPaymentItemProps {
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
}

interface IPaymentCardList {
  card: IPaymentItemProps;
  clickable?: boolean;
  selected?: boolean;
  onClick?: any;
}

const PaymentCardList = ({
  card,
  clickable,
  selected,
  onClick,
}: IPaymentCardList) => {
  const [expired, setExpired] = useState(false);
  const expiry_date = moment()
    .set({ year: card.exp_year, month: card.exp_month - 1 })
    .format("YYYY-MM-DD");

  useEffect(() => {
    if (
      moment(expiry_date, "YYYY-MM-DD").isBefore(moment().format("YYYY-MM-DD"))
    ) {
      setExpired(true);
    }
  }, [expiry_date, card.exp_year, card.exp_month]);

  //return correct card
  return clickable ? (
    <ClickableCard
      card={card}
      expired={expired}
      selected={selected ? selected : false}
      onClick={onClick}
    />
  ) : (
    <NonClickableCard card={card} expired={expired} />
  );
};

export default PaymentCardList;
