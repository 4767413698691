import clsx from "clsx";
import { Moment } from "moment";

interface IDateItemProps {
  onClick: any;
  selected: boolean;
  label: Moment;
  error?: boolean;
}

const DateItem = ({ onClick, selected, label, error }: IDateItemProps) => {
  const highlightedCard = () => {
    if (selected) {
      return "border-primary";
    }
    return;
  };

  const outlineCard = () => {
    if (error) {
      return "border-error";
    } else if (!selected) {
      return "border-surface";
    }
    return;
  };

  return (
    <div
      className={clsx([
        `${highlightedCard()} ${outlineCard()} cursor-pointer h-full border-2 bg-background py-2 px-2 md:py-4 md:px-4 rounded transition-all`,
      ])}
      onClick={onClick}>
      <div className="h-full grid place-items-center">
        <p className="font-extrabold uppercase text-text font-lg">
          {label.date()}
        </p>
        <p className="font-extrabold uppercase text-secondary font-base">
          {label.format("dddd").substring(0, 3)}
        </p>
      </div>
    </div>
  );
};

export default DateItem;
