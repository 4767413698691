//import dependencies
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//import components
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import Container from "../../../components/Container";

//import stores
import { useUpdateUserMutation } from "../../../store/account/userSlice";

//import interfaces
import { selectHospital } from "../../../store/account/hospitalSlice";
import { useAuth } from "../../../contexts/Auth";

const TermsOfService = () => {
  const { currentUser } = useAuth();

  const hospital = useSelector(selectHospital);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [updateUser, { error, isLoading }] = useUpdateUserMutation();

  //checkbox handler
  const checkboxHandler = () => {
    setIsChecked((prevState) => {
      return !prevState;
    });
  };

  const onSubmit = () => {
    if (isChecked) {
      updateUser({
        id: currentUser.id,
        tos_accepted: isChecked,
      })
        .unwrap()
        .then(() => navigate("/"));
    }
  };

  useEffect(() => {
    if (error) {
      // notification.notify({
      //   type: "error",
      //   title: `${t("error")}`,
      //   message: `${t("error-please-try-again-later")}`,
      // });
      console.error(error);
      navigate("/error");
    }
  }, [error, navigate]);

  return (
    <>
      <Container>
        <div className="flex flex-1 mb-36 pt-2">
          <iframe
            title="tos"
            src={`/terms-of-use/${hospital?.data?.name}`}
            className="flex-1 flex"
          />
        </div>
      </Container>
      <div className="bg-gradient-to-t from-background via-background to-background/50 fixed bottom-0 left-0 right-0 p-6">
        <div className="w-full md:w-80  mx-auto my-3">
          <Checkbox label={t("read-accept-tc")} onChange={checkboxHandler} />
        </div>
        <div>
          <Button
            disabled={!isChecked || isLoading}
            className="w-full md:w-80"
            color="primary"
            variant="contained"
            onClick={onSubmit}
            loading={isLoading}>
            {t("create-account")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
