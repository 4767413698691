import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../services/api";
import { IUserRegistration } from "../../interfaces/User";
import { IPatientUpdate } from "../../interfaces/Patient";

interface SliceState {
  user: {
    loading: "idle" | "loading" | "finished";
    data: any;
    error: String | undefined | null;
  };
  patient: {
    loading: "idle" | "loading" | "finished";
    data: any;
    error: String | undefined | null;
  };
}

export const fetchUser = createAsyncThunk(
  "account/fetchUser",
  async (idp_id: string, { rejectWithValue }) => {
    try {
      return await apiService.getUser(idp_id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteUser = createAsyncThunk(
  "account/deleteUser",
  async (u: string, { rejectWithValue }) => {
    try {
      return await apiService.deleteUser(u);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const fetchPatient = createAsyncThunk(
  "account/fetchPatient",
  async (user_id: string, { rejectWithValue }) => {
    try {
      return await apiService.getPatient(user_id);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updatePatient = createAsyncThunk(
  "account/updatePatient",
  async (p: IPatientUpdate, { rejectWithValue }) => {
    try {
      return await apiService.updatePatient(p);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const registerPatient = createAsyncThunk(
  "account/registerPatient",
  async (u: IUserRegistration, { rejectWithValue }) => {
    try {
      return await apiService.registerUser(u);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const initialState: SliceState = {
  user: {
    data: null,
    loading: "idle",
    error: null,
  },
  patient: {
    data: null,
    loading: "idle",
    error: null,
  },
};

const userSlice = createSlice({
  name: "account/user",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.user.loading = "loading";
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.user.loading = "finished";
        state.user.error = action.error.message;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user.loading = "finished";
        state.user.data = action.payload ? action.payload : null;
      })
      .addCase(deleteUser.pending, (state) => {
        state.user.loading = "loading";
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.user.loading = "finished";
        state.user.error = action.error.message;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.user.loading = "finished";
        state.user.data = action.payload ? action.payload : null;
      })

      .addCase(fetchPatient.pending, (state) => {
        state.patient.loading = "loading";
      })
      .addCase(fetchPatient.rejected, (state, action) => {
        state.patient.loading = "finished";
        state.patient.error = action.error.message;
      })
      .addCase(fetchPatient.fulfilled, (state, action) => {
        state.patient.loading = "finished";
        state.patient.data = action.payload ? action.payload : null;
      })
      .addCase(updatePatient.pending, (state) => {
        state.patient.loading = "loading";
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.patient.loading = "finished";
        state.patient.error = action.error.message;
      })
      .addCase(updatePatient.fulfilled, (state, action) => {
        state.patient.loading = "finished";
        state.patient.data = action.payload ? action.payload : null;
      })
      .addCase(registerPatient.pending, (state) => {
        state.patient.loading = "loading";
      })
      .addCase(registerPatient.rejected, (state, action) => {
        state.patient.loading = "finished";
        state.patient.error = action.error.message;
      })
      .addCase(registerPatient.fulfilled, (state, action) => {
        state.patient.loading = "finished";
        let p = action.payload.patient;
        let u = action.payload.user;
        if (p?.id) {
          state.patient.data = p;
        }
        if (u?.id) {
          state.user.data = u;
        }
      });
  },
});
export const selectUser = ({ account }: any) => account.user.user;
export const selectPatient = ({ account }: any) => account.user.patient;
export const userActions = userSlice.actions;
export default userSlice.reducer;

/////
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "..";
import { API_URL } from "../../os/env";

export interface User {
  id: number;
  tos_accepted: boolean;
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  preferences: {
    email_notifications: boolean;
    sms_notifications: boolean;
    voice_notifications: boolean;
  };
}

// Define a service using a base URL and expected endpoints
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      if (!headers.has("Content-Type")) {
        headers.set("Content-Type", "application/json");
        headers.set("Accept", "application/json");
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUser: builder.query<User, string>({
      query: (idpId: string) => `/users?idp_id=${idpId}`,
      transformResponse: (raw: { users: User[] }) => {
        return raw.users[0];
      },
      providesTags: ["User"],
    }),
    updateUser: builder.mutation<User, Partial<User> & Pick<User, "id">>({
      query: ({ id, ...patch }) => ({
        url: `users/${id}`,
        method: "PATCH",
        body: { user: patch },
      }),
      invalidatesTags: ["User"],
    }),
  }),
  tagTypes: ["User"],
});

export const { useGetUserQuery, useUpdateUserMutation } = userApi;
