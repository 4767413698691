//import components
import Button from "../../../components/Button";
import Container from "../../../components/Container";

//import dependencies
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MuiTelInput } from "mui-tel-input";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/es";

//import stores
import { AppDispatch } from "../../../store";
import { selectPatient, updatePatient } from "../../../store/account/userSlice";

//import theme
import theme from "../../../theme";

import { useUpdateUserMutation } from "../../../store/account/userSlice";
import { useEffect } from "react";

const schema = yup
  .object({
    phone: yup.string().phone("US").required(), //only VALID US numbers are accepted
    preferences: yup.string().required(),
    language_preference: yup.string().required(),
  })
  .required();

const PersonalInformation = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const patient = useSelector(selectPatient);
  const [updateUser, { error: updateUserError, isLoading: updateUserLoading }] =
    useUpdateUserMutation();
  const { t, i18n } = useTranslation();
  const LANGUAGES: Record<string, "en" | "es"> = {
    "en-US": "en",
    "es-PR": "es",
    "es-US": "es",
  };

  const {
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      phone: patient.data.primary_phone ? patient.data.primary_phone : "",
      preferences: "",
      language_preference:
        LANGUAGES[i18n.language as keyof Record<string, "en" | "es">] || "en",
    },
  });

  const onSubmit = async () => {
    const d = getValues();

    if (isValid) {
      if (i18n.language !== d.language_preference) {
        i18n.changeLanguage(d.language_preference);
        moment.locale(i18n.language);
      }

      const promises = [];

      promises.push(
        updateUser({
          id: patient.data.user_id, // Current User doesn't exist yet `currentUser.id` would fail
          phone: d.phone.replace("+1", ""),
          preferences: {
            email_notifications: d.preferences === "email_notifications",
            sms_notifications: d.preferences === "sms_notifications",
            voice_notifications: d.preferences === "voice_notifications",
          },
        }).unwrap(),
      );

      promises.push(
        dispatch(
          updatePatient({
            id: patient.data.id,
            primary_phone: d.phone.replace("+1", ""),
            preferences: {
              sms_notifications: d.preferences === "sms_notifications",
              voice_notifications: d.preferences === "voice_notifications",
            },
            language_preference: d.language_preference,
          }),
        )
          .unwrap()
          .catch((err: unknown) => {
            console.error("Error Updating Patient: " + err);
            navigate("/error");
          }),
      );

      await Promise.all(promises).then(() => navigate("/tos"));
    }
  };

  useEffect(() => {
    if (updateUserError) {
      console.error("Error Updating User: " + updateUserError);
      navigate("/error");
    }
  }, [navigate, updateUserError]);

  return (
    <>
      <Container>
        <form className="w-full md:w-80 mx-auto pt-6">
          <b className="text-lg text-text">{t("personal-information")}</b>
          <p className="text-base pt-3 text-subtitle">
            {t("collect-final-info")}
          </p>
          <div className="my-8">
            <div className="my-6">
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <MuiTelInput
                    className="w-full"
                    label={t("phone-number")}
                    onlyCountries={["US"]}
                    error={errors.phone ? true : false}
                    size="small"
                    defaultCountry="US"
                    sx={{ backgroundColor: "#FFFFFF" }}
                    {...field}
                  />
                )}
              />
            </div>

            <div className="my-6 text-left">
              <Controller
                name="preferences"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <ThemeProvider theme={theme}>
                    <FormControl>
                      <b className="text-base text-text">
                        {t("i-prefer-to-be-contacted-by")}:
                      </b>
                      <RadioGroup
                        {...field}
                        onChange={(value) => field.onChange(value)}
                        value={field.value}>
                        <FormControlLabel
                          value="sms_notifications"
                          control={<Radio />}
                          label={
                            <p className="font-base">{t("text-messages")}</p>
                          }
                        />
                        <FormControlLabel
                          value="voice_notifications"
                          control={<Radio />}
                          label={<p className="font-base">{t("voice-call")}</p>}
                        />
                      </RadioGroup>
                    </FormControl>
                  </ThemeProvider>
                )}
              />
            </div>

            <div className="my-6 text-left">
              <b className="text-base text-text">{t("language-preferences")}</b>
              <Controller
                name="language_preference"
                control={control}
                render={({ field }) => (
                  <div className="pt-2">
                    <Select fullWidth size={"small"} {...field}>
                      {Object.keys(i18n.services.resourceStore.data).map(
                        (option) => (
                          <MenuItem key={option} value={option}>
                            {t(option)}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </div>
                )}
              />
              <p className="text-sm text-text pt-2">
                {t("select-your-preferred-language")}
              </p>
            </div>
            <div className="h-24" />
          </div>
        </form>
      </Container>

      <div className="bg-gradient-to-t from-background via-background to-background/50 fixed bottom-0 left-0 right-0 p-6">
        <Button
          className="w-full md:w-80"
          color="primary"
          onClick={onSubmit}
          variant="contained"
          disabled={
            !isValid || patient.loading === "loading" || updateUserLoading
          }
          loading={patient.loading === "loading" || updateUserLoading}
          icon={<i className="icon-arrow-right" />}>
          {t("continue")}
        </Button>
      </div>
    </>
  );
};

export default PersonalInformation;
