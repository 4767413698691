//dependencies
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//components
import Container from "../../../components/Container";
import RideBenefits from "../../../components/RideBenefits";
import Spinner from "../../../components/Spinner";
import ContactUsFooter from "./components/ContactUsFooter";
import Card from "../../../components/Card";

//services
import benefitService from "../../../services/benefit";

//store
import { selectBenefits } from "../../../store/account/benefitsSlice";
import { selectHospital } from "../../../store/account/hospitalSlice";
import { selectPayer } from "../../../store/account/payerSlice";

//constants
import enums from "../../../constants/enums";

const BenefitsScreen = () => {
  const hospital = useSelector(selectHospital);
  const benefits = useSelector(selectBenefits);
  const payer = useSelector(selectPayer);
  const benefitConfigs = benefitService.get(hospital?.data?.name);
  const { t } = useTranslation();

  const isUnlimited = () => {
    return (
      (!benefits.data ||
        (!benefits.data.budget_limit && !benefits.data?.rides_limit)) &&
      payer.data.category === enums.PayerTypes.facility
    );
  };

  const isDiscounted = (p: any) => {
    return Object.values(enums.discount).includes(p);
  };

  const displayName = benefitConfigs?.displayName;
  const contactNumber = benefitConfigs?.contactNumber;

  return (
    <Container>
      {hospital.data && payer.data ? (
        <div className="w-full text-base pb-12">
          <div className="my-6">
            <RideBenefits
              payer={payer}
              benefits={benefits}
              hospital={hospital}
            />
          </div>

          <Card>
            {isDiscounted(displayName) && (
              <div className="text-left">
                <p className="py-3">
                  {t("benefits-discount-1", { displayName })}
                </p>
                <p className="py-3">
                  {t("benefits-discount-2", { displayName })}
                </p>
                <p className="py-3">{t("benefits-discount-3")}</p>
                <ul className="list-disc list-inside">
                  <li>{t("benefits-discount-4")}</li>
                  <li>{t("benefits-discount-5")}</li>
                  <li>{t("benefits-discount-6")}</li>
                  <li>{t("benefits-discount-7")}</li>
                  <li>{t("benefits-discount-8")}</li>
                  <li>{t("benefits-discount-9")}</li>
                </ul>
                <p className="py-3 italic">{t("benefits-discount-10")}</p>
                <p className="py-3">
                  {t("benefits-discount-11", { displayName })}
                </p>
              </div>
            )}

            {isUnlimited() && !isDiscounted(displayName) && (
              <div className="text-left">
                <p className="py-3">
                  {t("benefits-unlimited-1", { displayName })}
                </p>
                <p className="py-3">
                  {t("benefits-unlimited-2", { displayName })}
                </p>
                <p className="py-3">{t("benefits-unlimited-3")}</p>
                <p className="py-3">
                  {t("benefits-unlimited-4", { displayName })}
                </p>
                <p className="py-3">
                  {t("benefits-unlimited-5", { displayName })}
                </p>
              </div>
            )}

            {payer.data.category === enums.PayerTypes.facility &&
              !isUnlimited() &&
              !isDiscounted(displayName) && (
                <div className="text-left">
                  <p className="py-3">
                    {t("benefits-fixed-and-flex-1", { displayName })}
                  </p>
                  <p className="py-3">
                    {t("benefits-fixed-1", { displayName })}
                  </p>
                  <p className="py-3">{t("benefits-fixed-2")}</p>
                  <p className="py-3">{t("benefits-fixed-3")}</p>
                  <p className="py-3">{t("benefits-fixed-4")}</p>
                  <p className="py-3">{t("benefits-fixed-and-flex-2")}</p>
                  <ul className="list-disc list-inside">
                    <li>{t("benefits-fixed-and-flex-3")}</li>
                    <li>{t("benefits-fixed-and-flex-4")}</li>
                    <li>{t("benefits-fixed-and-flex-5")}</li>
                    <li>{t("benefits-fixed-and-flex-6")}</li>
                    <li>{t("benefits-fixed-and-flex-7")}</li>
                    <li>{t("benefits-fixed-and-flex-8")}</li>
                    <li>{t("benefits-fixed-and-flex-9")}</li>
                  </ul>
                  <p className="py-3">
                    {t("benefits-fixed-and-flex-10", {
                      contactNumber,
                      displayName,
                    })}
                  </p>
                </div>
              )}

            {payer.data.category === enums.PayerTypes.patient &&
              !isUnlimited() &&
              !isDiscounted(displayName) && (
                <div className="text-left">
                  <p className="py-3">
                    {t("benefits-fixed-and-flex-1", { displayName })}
                  </p>
                  <p className="py-3">
                    {t("benefits-flex-1", { displayName })}
                  </p>
                  <p className="py-3">{t("benefits-flex-2")}</p>
                  <p className="py-3">{t("benefits-flex-3")}</p>
                  <p className="py-3">{t("benefits-fixed-and-flex-2")}</p>
                  <ul className="list-disc list-inside">
                    <li>{t("benefits-fixed-and-flex-3")}</li>
                    <li>{t("benefits-fixed-and-flex-4")}</li>
                    <li>{t("benefits-fixed-and-flex-5")}</li>
                    <li>{t("benefits-fixed-and-flex-6")}</li>
                    <li>{t("benefits-fixed-and-flex-7")}</li>
                    <li>{t("benefits-fixed-and-flex-8")}</li>
                    <li>{t("benefits-fixed-and-flex-9")}</li>
                  </ul>
                  <p className="py-3">
                    {t("benefits-fixed-and-flex-10", {
                      contactNumber,
                      displayName,
                    })}
                  </p>
                </div>
              )}
          </Card>

          <div className="my-6">
            <ContactUsFooter hospital={hospital.data} />
          </div>
        </div>
      ) : (
        <div className="flex flex-1 items-center">
          <div className="w-12 my-6 mx-auto">
            <Spinner />
          </div>
        </div>
      )}
    </Container>
  );
};

export default BenefitsScreen;
