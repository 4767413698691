import React, { SetStateAction } from "react";
import { useSelector } from "react-redux";
import Card from "../../../../../components/Card";
import { ReactComponent as RideShare } from "../../../../../assets/svg/rideshare.svg";
import { ReactComponent as WheelchairVanIcon } from "../../../../../assets/svg/wheelchairvan.svg";
import { ReactComponent as MedicalSedanIcon } from "../../../../../assets/svg/medicalsedan.svg";
import { ReactComponent as Taxi } from "../../../../../assets/svg/taxi.svg";
import { selectHospital } from "../../../../../store/account/hospitalSlice";
import { useTranslation } from "react-i18next";

interface IRideTypeProps {
  type:
    | "standard_vehicle"
    | "medical_sedan"
    | "wheelchair_van"
    | "taxi"
    | "ride_share";
  modelHandler: React.Dispatch<SetStateAction<boolean>>;
}

const RideType = ({ type, modelHandler }: IRideTypeProps) => {
  const hospital = useSelector(selectHospital);
  const { t } = useTranslation();

  const rideIcon = () => {
    if (type === "ride_share") {
      return <RideShare className="w-16 mx-auto" />;
    }
    if (type === "taxi") {
      return <Taxi className="w-16 mx-auto my-auto" />;
    }
    if (type === "medical_sedan") {
      return <MedicalSedanIcon className="w-16" />;
    }
    if (type === "wheelchair_van") {
      return <WheelchairVanIcon className="w-16" />;
    }
  };

  const rideDescription = () => {
    const booking_threshold = hospital.data?.advanced_booking_threshold;
    if (type === "ride_share") {
      return (
        <p>{t("immediate-or-advance-scheduled-ride-without-assistance")}.</p>
      );
    }

    if (type === "taxi") {
      if (hospital.data?.advanced_booking_threshold) {
        return (
          <p>
            {t(
              "schedule-booking-threshold-hours-in-advance-without-assistance",
              { booking_threshold },
            )}
            .
          </p>
        );
      } else {
        return <p>{t("advance-scheduled-ride-without-assistance")}.</p>;
      }
    }
    if (type === "medical_sedan") {
      return <p>{t("a-credentialed-driver-that-can-provide-assistance")}.</p>;
    }
    if (type === "wheelchair_van") {
      return (
        <p>
          {t(
            "an-ada-accredited-vehicle-and-driver-that-can-accommodate-most-wheelchair-types",
          )}
          .
        </p>
      );
    }
  };

  return (
    <Card>
      <div className="flex cursor-pointer" onClick={() => modelHandler(true)}>
        <div className="flex flex-col border-r-2 pr-3 border-r-background">
          {rideIcon()}
        </div>

        <div className="pl-3 flex flex-col text-left my-auto">
          <p className="text-base text-text font-extrabold">{t(type)}</p>
          <div className="text-sm text-subtitle">{rideDescription()}</div>
        </div>

        <div className="justify-center self-stretch flex my-auto ml-auto">
          <i className="icon-chev-right text-disabled text-4xl" />
        </div>
      </div>
    </Card>
  );
};

export default RideType;
