import clsx from "clsx";

interface ITripTypeItemProps {
  onClick?: any;
  selected: boolean;
  label: string;
}

const TripTypeItem = ({ onClick, selected, label }: ITripTypeItemProps) => {
  const highlightedCard = () => {
    if (selected) {
      return "bg-primary text-white shadow-[0px_0px_32px_rgba(0,0,66,0.15)] border-transparent";
    }
    return;
  };

  const outlineCard = () => {
    if (!selected) {
      return "text-disabled";
    }
    return;
  };

  return (
    <div
      className={clsx([
        `${highlightedCard()} ${outlineCard()} cursor-pointer bg-white py-4 px-4 rounded-xl transition-all`,
      ])}
      onClick={onClick}>
      <div className="grid place-items-center">
        <p className="font-extrabold uppercase font-base">{label}</p>
      </div>
    </div>
  );
};

export default TripTypeItem;
