import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import configService from "../../services/config";
import { AppDispatch } from "../../store";
import StripePaymentForm from "./StripePaymentForm";
import { createSetupIntent } from "../../store/booking/setupIntentSlice";
import { selectTripRides } from "../../store/trip/tripSlice";
import { useTranslation } from "react-i18next";
import { languages } from "../../utilities/localisation/i18n";

configService.init(process.env);
const STRIPE_PUBLISHABLE_KEY = configService.get("STRIPE_PUBLISHABLE_KEY");

const StripeCheckout = () => {
  const { i18n } = useTranslation();

  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY, {
    locale: i18n.language as languages,
  });

  const [clientSecret, setClientSecret] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const rides = useSelector(selectTripRides);

  useEffect(() => {
    dispatch(createSetupIntent(1)) // TODO: Remove param
      .unwrap()
      .then(({ intent_client_secret }: any) => {
        setClientSecret(intent_client_secret);
      });
  }, [dispatch, rides]);

  if (!clientSecret) {
    return null;
  }

  const options = { clientSecret };

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripePaymentForm />
    </Elements>
  );
};

export default StripeCheckout;
