//import dependencies
import { FormControl, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import React from "react";
import { Moment } from "moment";

//import components
import TimeSelectorItem from "./TimeSelectorItem";
import TimeItem from "./TimeItem";
import { useTranslation } from "react-i18next";

interface ITimeSelectorProps {
  value: string;
  onChange: (e?: any) => void;
  reset?: boolean;
  minTime?: Moment | undefined;
  rideshare?: boolean;
  error?: boolean;
  returnDate?: boolean;
}

const TimeSelector = React.forwardRef(
  (
    {
      value,
      onChange,
      reset,
      minTime,
      rideshare,
      error,
      returnDate,
    }: ITimeSelectorProps,
    ref, // eslint-disable-line @typescript-eslint/no-unused-vars
  ) => {
    const { t } = useTranslation();

    const [inputtedValue, setInputtedValue] = useState(
      value !== "" ? value : "",
    ); //radio inputtedValue
    const [resetError, setResetError] = useState(false); //error state for resetting

    //asap is disabled and the inputted value is disabled, revert
    useEffect(() => {
      if ((!minTime && inputtedValue === "ASAP") || reset) {
        setInputtedValue("");
        onChange("");
        setResetError(true);
      }
    }, [minTime, inputtedValue, reset]); //eslint-disable-line

    useEffect(() => {
      if (inputtedValue !== "" && resetError) {
        setResetError(false);
      }
    }, [inputtedValue]); //eslint-disable-line

    return (
      <FormControl>
        <RadioGroup className="pt-4" value={inputtedValue}>
          <div className="flex justify-between rounded">
            {rideshare && (
              <>
                <div className="p-1 md:grow">
                  <TimeItem
                    selected={inputtedValue === "ASAP"}
                    disabled={minTime ? false : true}
                    error={error || resetError}
                    onClick={() => {
                      setInputtedValue("ASAP");
                      onChange("ASAP");
                    }}
                  />
                </div>

                <div className="mx-auto my-auto items-center text-center px-3">
                  <p className="text-secondary text-base">{t("or")}</p>
                </div>
              </>
            )}

            <div className="p-1 grow">
              <TimeSelectorItem
                small={rideshare}
                error={error || resetError}
                selected={
                  rideshare &&
                  inputtedValue !== "ASAP" &&
                  inputtedValue !== "" &&
                  inputtedValue !== undefined
                }
                onChange={(e: any) => {
                  if (e) {
                    setInputtedValue(e);
                    onChange(e);
                  } else if (reset) {
                    setInputtedValue("");
                    onChange("");
                  }
                }}
                value={inputtedValue}
                minTime={minTime}
                reset={reset || inputtedValue === "ASAP"} //if outbound date changes, reset time to avoid conflict with booking threshold
                returnDate={returnDate}
              />
            </div>
          </div>
        </RadioGroup>
      </FormControl>
    );
  },
);

export default TimeSelector;
