//import components
import Spinner from "../../../components/Spinner";
import Input from "../../../components/Input";
import RecentAddressItem from "../../../components/RecentAddressItem";
import SearchItem from "../../../components/SearchItem";
import SearchNotFound from "../../../components/SearchNotFound";
import Button from "../../../components/Button";
import MapContainer from "../../../components/MapContainer";
import Modal from "../../../components/Modal";

//import dependencies
import { useDispatch, useSelector } from "react-redux";
import { Card } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//interfaces
import { ILocation } from "../../../interfaces/Location";

//store
import {
  fetchRecentLocations,
  createDropoffLocation,
  createPickupLocation,
  selectRecentLocations,
  selectPickupLocations,
  selectDropoffLocations,
  setDropoffLocation,
  selectPickupLocation,
  fetchTripDistance,
  selectLocationsDistance,
} from "../../../store/booking/locationSlice";
import { selectPatient } from "../../../store/account/userSlice";
import { AppDispatch } from "../../../store";
import { notificationPush } from "../../../store/ui/notificationSlice";
import { selectHospital } from "../../../store/account/hospitalSlice";
import {
  fetchTrip,
  fetchTripDetails,
  selectEditTrip,
  selectEditTripDetails,
  resetEditTrip,
} from "../../../store/booking/editSlice";

//import constants
import enums from "../../../constants/enums";
import { useNotification } from "../../../contexts/Notification";

interface IDropoffPickupLocationProps {
  searchType: "Pickup" | "Dropoff";
}

//interface for suggestion retrieve from google places api
interface ISuggestionAddress {
  active: boolean;
  description: string;
  formattedSuggestion: any;
  id: any;
  index: number;
  placeId: string;
  terms: any;
  types: any;
}

const DropoffPickupLocation = ({ searchType }: IDropoffPickupLocationProps) => {
  const inputRef = useRef();
  const navigate = useNavigate();
  const notification = useNotification();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  //if edit trip
  const routeParams = useParams();
  const { tid } = routeParams;

  //selecting
  const recentLocationsData = useSelector(selectRecentLocations);
  const pickupLocations = useSelector(selectPickupLocations);
  const dropoffLocations = useSelector(selectDropoffLocations);
  const hospital = useSelector(selectHospital);
  const locationsDistance = useSelector(selectLocationsDistance);
  const { data } = useSelector(selectPatient);
  const patient_id = data.id; //patient id
  //edit trip selecting
  const editTrip = useSelector(selectEditTrip);
  const editTripDetails = useSelector(selectEditTripDetails);

  //reset state when user starts booking process in order to clear residue from previous booking
  useEffect(() => {
    if (searchType === "Dropoff") {
      dispatch({ type: "booking/reset" });
    }
  }, [dispatch, searchType]);

  //FETCHING
  //fetch trip based on trip id
  useEffect(() => {
    if (tid) {
      dispatch(fetchTrip(tid)).then((d: any) => {
        if (d.error) {
          navigate("../not-found");
        }
      });

      dispatch(fetchTripDetails(tid)).then((d: any) => {
        if (d.error) {
          navigate("../not-found");
        }
      });
    } else {
      dispatch(resetEditTrip());
    }
  }, [dispatch, tid, navigate]);

  //check if user is eligible to edit trip
  useEffect(() => {
    if (editTrip.data && editTrip.loading === "finished") {
      if (
        ![
          enums.RideStatus.queued.toString(),
          enums.RideStatus.claimed.toString(),
        ].includes(editTrip.data?.rides[0]?.current_status)
      ) {
        navigate("../not-found");
      }
    }
  }, [editTrip, navigate]);

  //fetch recent addresses
  useEffect(() => {
    dispatch(fetchRecentLocations(patient_id));
  }, []); // eslint-disable-line

  //STATES

  //states for google places api
  const [suggestionAddress, setSuggestionAddress] =
    useState<ISuggestionAddress>(); //returned by google places
  const [place_id, setPlace_id] = useState(""); //place id for geometry/address details
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 }); //coordinates of location

  //formatted address for api state
  const [formattedAddress, setFormattedAddress] = useState({
    street_address: "",
    city: "",
    state: "",
    zipcode: "",
    location_type: "",
  });

  //modal state
  const [longDistanceModal, setLongDistanceModal] = useState(false);
  const [duplicateAddressModal, setDuplicateAddressModal] = useState(false);

  //error state
  const [error, setError] = useState<boolean>(false); //error in search

  //user inputs states
  const [searchAddress, setSearchAddress] = useState(""); //search input
  const [nickname, setNickname] = useState(""); //address nickname input
  const [continueButtonLoading, setContinueButtonLoading] = useState(false);

  //USER INPUT HANDLERS

  //nickname handler
  const nicknameHandler = (e: any) => {
    setNickname(e.target.value);
  };

  //error search handler
  useEffect(() => {
    //if search changes and error, set error to false
    if (error) {
      setError(false);
    }
  }, [searchAddress]); // eslint-disable-line

  //RECEIVE TRIP FOR EDIT TRIP
  useEffect(() => {
    if (editTrip.data && searchType && tid) {
      if (searchType === "Dropoff") {
        setSearchAddress(editTrip.data.dropoff_location.street_address);
        setFormattedAddress({
          street_address: editTrip.data.dropoff_location.street_address,
          city: editTrip.data.dropoff_location.city,
          state: editTrip.data.dropoff_location.state,
          zipcode: editTrip.data.dropoff_location.zipcode,
          location_type: editTrip.data.dropoff_location.location_type,
        });
        //coordinates
        setCoordinates({
          lat: editTrip.data.dropoff_location.latitude,
          lng: editTrip.data.dropoff_location.longitude,
        });
        // Address Nick name
        setNickname(editTrip.data.dropoff_location.name);
      } else if (searchType === "Pickup") {
        setSearchAddress(editTrip.data.pickup_location.street_address);
        setFormattedAddress({
          street_address: editTrip.data.pickup_location.street_address,
          city: editTrip.data.pickup_location.city,
          state: editTrip.data.pickup_location.state,
          zipcode: editTrip.data.pickup_location.zipcode,
          location_type: editTrip.data.pickup_location.location_type,
        });

        //coordinates
        setCoordinates({
          lat: editTrip.data.pickup_location.latitude,
          lng: editTrip.data.pickup_location.longitude,
        });
        // Address Nick name
        setNickname(editTrip.data.pickup_location.name);
      }
    }
  }, [editTrip, searchType, tid]);

  //ERRORING
  //error handler for search results
  const handleError = (status: string, clearSuggestions: () => void) => {
    clearSuggestions();
    if (
      status === "ZERO_RESULTS" ||
      status === "NOT_FOUND" ||
      status === "INVALID_REQUEST"
    ) {
      setError(true);
    } else if (
      status === "OVER_QUERY_LIMIT" ||
      status === "INVALID_REQUEST" ||
      status === "NOT_FOUND" ||
      status === "REQUEST_DENIED" ||
      status === "UNKNOWN_ERROR"
    ) {
      console.error("Google Maps API returned error with status: ", status);
    }
  };

  //every time place_id changes, obtain new location information
  useEffect(() => {
    if (place_id !== "") {
      let x = new window.google.maps.places.PlacesService(inputRef.current!);

      x.getDetails(
        {
          placeId: place_id,
          fields: ["geometry", "address_components"],
        },
        //here we format received address components
        (place: any) => {
          //STREET ADDRESS
          const streetNumber = place.address_components.filter(
            (places: { types: any[] }) =>
              places.types.includes("street_number"),
          );
          const route = place.address_components.filter(
            (places: { types: any[] }) => places.types.includes("route"),
          );

          //if street address doesn't exist, use establishment name instead
          let street_address;
          if (streetNumber[0] === undefined || route[0] === undefined) {
            street_address = suggestionAddress?.formattedSuggestion.mainText;
          } else {
            street_address =
              streetNumber[0].long_name + " " + route[0].long_name;
          }

          //CITY
          let city;

          city = place.address_components.filter((places: { types: any[] }) =>
            places.types.includes("locality"),
          );

          //if locality was not found, then try looking for sublocality
          if (city[0] === undefined) {
            city = place.address_components.filter((places: { types: any[] }) =>
              places.types.includes("sublocality"),
            );
          }

          //if locality or sublocality was not found, try neighbourhood as last resort
          if (city[0] === undefined) {
            city = place.address_components.filter((places: { types: any[] }) =>
              places.types.includes("neighborhood"),
            );
          }

          //STATE
          const state = place.address_components.filter(
            (places: { types: any[] }) =>
              places.types.includes("administrative_area_level_1"),
          );

          //POST CODE
          const postalCode = place.address_components.filter(
            (places: { types: any[] }) => places.types.includes("postal_code"),
          );

          //LOCATION TYPE
          let location_type;
          if (
            suggestionAddress?.types.includes("residence") ||
            suggestionAddress?.types.includes("street_address")
          ) {
            location_type = "residence";
          } else {
            location_type = "facility";
          }

          //if location is not supported (ie missing city, state or postal code)
          if (
            city[0] === undefined ||
            state[0] === undefined ||
            postalCode[0] === undefined
          ) {
            dispatch(
              notificationPush({
                title: t("invalid-address"),
                message: t("please-select-a-valid-address"),
              }),
            );
          } else {
            setFormattedAddress({
              street_address: street_address,
              city: city[0].long_name,
              state: state[0].long_name,
              zipcode: postalCode[0].long_name,
              location_type: location_type,
            });

            setCoordinates({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            });
          }
        },
      );
    }
  }, [place_id]); //eslint-disable-line

  //SUBMISSION
  const onSubmit = async () => {
    let p: ILocation = {
      patient_id: patient_id,
      latitude: coordinates.lat,
      longitude: coordinates.lng,
      street_address: formattedAddress?.street_address,
      city: formattedAddress?.city,
      state: formattedAddress?.state,
      zipcode: formattedAddress?.zipcode,
      name: nickname,
      location_type: formattedAddress.location_type,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    await updateLocation(p);
  };

  const updateLocation = async (p: ILocation) => {
    const geofenceError = "Address is outside your approved service area.";
    setContinueButtonLoading(true);

    if (searchType === "Pickup") {
      dispatch(createPickupLocation(p))
        .unwrap()
        .then((response) => {
          // Successfully Updated Pickup
          handleLocationStepComplete(dropoffLocations.data, response);
        })
        .then(() => {
          tid ? navigate(`../pick-up/${tid}`) : navigate("../pick-up");
        })
        .catch((err: any) => {
          notification.notify({
            type: "error",
            title: `${t("error")}`,
            message:
              err?.errors[0] === geofenceError
                ? `${t("outside-approved-service-area")}`
                : `${t("error-please-try-again-later")}`,
          });
        })
        .finally(() => {
          setContinueButtonLoading(false);
        });
    } else if (searchType === "Dropoff") {
      dispatch(createDropoffLocation(p))
        .unwrap()
        .then((response) => {
          recentLocationUpdate(response)
        })
        .catch((err: any) => {
          notification.notify({
            type: "error",
            title: `${t("error")}`,
            message:
              err?.errors[0] === geofenceError
                ? `${t("outside-approved-service-area")}`
                : `${t("error-please-try-again-later")}`,
          });
        })
        .finally(() => {
          setContinueButtonLoading(false);
        });
    } else {
      setContinueButtonLoading(false);
    }
  };

  //CHECK IF DISTANCE IS BELOW THRESHOLD (EG > 25 MILES)

  //if drop off location and pickup location, fetch trip distance
  useEffect(() => {
    if (dropoffLocations.data && pickupLocations.data) {
      dispatch(
        fetchTripDistance({
          dropoff: dropoffLocations.data,
          pickup: pickupLocations.data,
        }),
      );
    }
  }, [dropoffLocations.data, pickupLocations.data, dispatch]);

  //called upon location selection of both pickup and drop off to check before user proceeds
  const handleLocationStepComplete = (d: ILocation, p: ILocation) => {
    if (d.latitude === p.latitude && d.longitude === p.longitude) {
      setDuplicateAddressModal(true);
    } else {
      dispatch(
        fetchTripDistance({
          dropoff: d,
          pickup: p,
        }),
      )
        .unwrap()
        .then((res) => {
          if (hospital.data?.long_ride_threshold_in_miles) {
            // Check if distance below the threshold
            hospital.data.long_ride_threshold_in_miles >= res.distance / 5280
              ? tid
                ? navigate(`../choose-ride/${tid}`)
                : navigate("../choose-ride")
              : setLongDistanceModal(true);
          } else {
            tid
              ? navigate(`../choose-ride/${tid}`)
              : navigate("../choose-ride");
          }
        });
    }
  };

  //RECENT LOCATIONS
  //dispatches update location of correct type
  const recentLocationUpdate = async (p: ILocation) => {
    if (searchType === "Pickup") {
      dispatch(selectPickupLocation(p));
      handleLocationStepComplete(dropoffLocations.data, p);
    } else if (searchType === "Dropoff") {
      dispatch(setDropoffLocation(p));
      navigate("../pick-up");
    }
  };

  //obtain recent addresses, map into array and filter out nulled inputs, reverse to display in order of most frequent
  const recentLocationsList = recentLocationsData.data
    .map((location: ILocation, i: number, arr: any) => {
      if (location.id === dropoffLocations?.data?.id) {
        return null;
      }
      return (
        <div
          key={location.id}
          className={
            arr.length - arr.length !== i ? "border-b border-surface" : ""
          }
          onClick={() => recentLocationUpdate(location)}>
          <RecentAddressItem recentAddress={location} />
        </div>
      );
    })
    .filter((location: ILocation) => {
      return location !== null;
    })
    .reverse();

  //OUTPUT JSX CODE
  if (
    recentLocationsData.loading !== "finished" ||
    (searchType === "Pickup" && dropoffLocations.loading !== "finished") ||
    (tid &&
      (editTrip.loading !== "finished" ||
        editTripDetails.loading !== "finished"))
  ) {
    return (
      <div className="flex flex-1 items-center">
        <div className="w-12 my-6 mx-auto">
          <Spinner />
        </div>
      </div>
    );
  } else {
    if (dropoffLocations.loading === "idle" && searchType === "Pickup") {
      return <Navigate to="../drop-off" replace />;
    }
    const long_ride_threshold_in_miles = Math.round(
      hospital.data.long_ride_threshold_in_miles,
    );
    return (
      <PlacesAutocomplete
        value={searchAddress}
        onChange={(e: string) => {
          setSearchAddress(e);
        }}
        onSelect={(address: string, placeId: string, suggestion: any) => {
          setSuggestionAddress(suggestion);
          setPlace_id(placeId);
          setSearchAddress(address);
        }}
        onError={handleError}
        searchOptions={{ componentRestrictions: { country: ["us"] } }}>
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading,
        }: any) => (
          <>
            <Card>
              <div className="flex">
                <div className="w-14 bg-surface/50 rounded flex justify-center">
                  <i className="icon-search text-4xl pt-5" />
                </div>
                <div className="py-3 px-3 text-left justify-around grow">
                  <div className="pl-3">
                    <div className="text-text text-base">
                      {searchType === "Dropoff" && (
                        <p>{t("where-would-you-like-to-go")}</p>
                      )}
                      {searchType === "Pickup" && (
                        <p>{t("where-should-we-pick-you-up")}</p>
                      )}
                    </div>
                    <Input
                      {...getInputProps({
                        placeholder: `${t("enter-address")}`,
                      })}
                      ref={inputRef}
                      variant="standard"
                      fullWidth
                      name={searchType}
                      autoFocus
                    />
                    <div>
                      {loading ? (
                        <div className="w-10 mx-auto">
                          <Spinner />
                        </div>
                      ) : null}

                      {!loading &&
                        !suggestions.null &&
                        suggestions.map((suggestion: ISuggestionAddress) => {
                          return (
                            <div
                              className="cursor-pointer hover:text-primary"
                              {...getSuggestionItemProps(suggestion)}
                              key={suggestion.placeId}>
                              <SearchItem suggestion={suggestion} />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            {/* if no search result, display search not found */}
            {error && <SearchNotFound searchTerm={searchAddress} />}

            {/* recent addresses if no selected address and empty search input and recent locations exist*/}

            {(formattedAddress.street_address.length === 0 ||
              formattedAddress.city.length === 0 ||
              formattedAddress.state.length === 0 ||
              formattedAddress.zipcode.length === 0) &&
              searchAddress === "" &&
              recentLocationsData.data.length > 0 &&
              recentLocationsList.length > 0 &&
              !editTrip.data && (
                <div className="text-left py-6">
                  <div className="flex items-center mb-3">
                    <i className="icon-time text-2xl text-text" />
                    <b className="text-base text-text">
                      {t("recent-addresses")}
                    </b>
                  </div>
                  <div className="rounded-md bg-white border border-surface -mb-px">
                    {recentLocationsList}
                  </div>
                </div>
              )}

            {/* display map, nickname and continue button when location from search is selected */}
            {formattedAddress.street_address.length > 0 &&
              formattedAddress.city.length > 0 &&
              formattedAddress.state.length > 0 &&
              formattedAddress.zipcode.length > 0 &&
              coordinates.lat !== 0 &&
              coordinates.lng !== 0 && (
                <>
                  <div className="py-6 text-left">
                    {searchType === "Pickup" && (
                      <b className="font-lg">
                        {t("were-going-to-pick-you-up-at")}:
                      </b>
                    )}

                    {searchType === "Dropoff" && (
                      <b className="font-lg">{t("you-re-going-to")}:</b>
                    )}
                  </div>

                  <div className="bg-white shadow-lg rounded-md">
                    {searchType === "Pickup" && (
                      <MapContainer
                        pickup={{
                          longitude: coordinates.lng,
                          latitude: coordinates.lat,
                        }}
                      />
                    )}
                    {searchType === "Dropoff" && (
                      <MapContainer
                        dropoff={{
                          longitude: coordinates.lng,
                          latitude: coordinates.lat,
                        }}
                      />
                    )}

                    <div className="text-left p-3">
                      <p className="font-extrabold text-text">
                        {formattedAddress.street_address}
                      </p>
                      <p className="text-secondary text-base">
                        {formattedAddress.city +
                          ", " +
                          formattedAddress.state +
                          " " +
                          formattedAddress.zipcode}
                      </p>
                    </div>
                  </div>

                  <Input
                    value={nickname}
                    margin="normal"
                    fullWidth
                    label={t("address-nickname")}
                    name="address-nickname"
                    onChange={nicknameHandler}
                  />

                  <div className="my-4">
                    <Button
                      className="w-80 my-2"
                      color="primary"
                      variant="contained"
                      disabled={
                        continueButtonLoading ||
                        locationsDistance.loading === "loading" ||
                        pickupLocations.loading === "loading" ||
                        dropoffLocations.loading === "loading" ||
                        searchAddress.length <= 0 ||
                        error ||
                        nickname.length <= 0 ||
                        (formattedAddress.street_address.length <= 0 &&
                          formattedAddress.city.length <= 0 &&
                          formattedAddress.state.length <= 0 &&
                          formattedAddress.zipcode.length <= 0) ||
                        (coordinates.lat === 0 && coordinates.lng === 0)
                      }
                      loading={
                        locationsDistance.loading === "loading" ||
                        pickupLocations.loading === "loading" ||
                        dropoffLocations.loading === "loading" ||
                        continueButtonLoading
                      }
                      onClick={onSubmit}>
                      {t("continue")}
                      <div className="absolute right-1.5 bottom-1.5 top-1.5 text-2xl bg-white/25 rounded">
                        <i className="icon-arrow-right" />
                      </div>
                    </Button>
                  </div>
                </>
              )}

            {longDistanceModal && (
              <Modal
                onHideModal={() => {
                  setLongDistanceModal(false);
                }}>
                <i
                  className={"icon-warning flex justify-center text-8xl -my-4"}
                />
                <p className="text-center text-xl font-extrabold pt-2">
                  {t("your-address-is-far")}
                </p>
                <p className="text-center text-base text-secondary p-2">
                  {t("you-need-to-book-ride-within-miles", {
                    long_ride_threshold_in_miles,
                  })}
                </p>

                <div className="my-2 flex justify-center">
                  <Button
                    className="w-72"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setLongDistanceModal(false);
                      navigate("../book-trip/drop-off");
                    }}>
                    {t("change-addresses")}
                  </Button>
                </div>
              </Modal>
            )}

            {duplicateAddressModal && (
              <Modal
                onHideModal={() => {
                  setDuplicateAddressModal(false);
                }}>
                <i
                  className={"icon-warning flex justify-center text-8xl -my-4"}
                />
                <p className="text-center text-xl font-extrabold pt-2">
                  {t("duplicate-address")}
                </p>
                <p className="text-center text-base text-secondary p-2">
                  {t("please-specify-a-unique-pick-up-location")}
                </p>

                <div className="my-2 flex justify-center">
                  <Button
                    className="w-72"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setDuplicateAddressModal(false);
                    }}>
                    {t("change-address")}
                  </Button>
                </div>
              </Modal>
            )}
          </>
        )}
      </PlacesAutocomplete>
    );
  }
};

export default DropoffPickupLocation;
