import { GoogleMap, MarkerF, PolylineF } from "@react-google-maps/api";
import colors from "../../constants/colors";
//import configService from '../../services/config';
import mapsStyling from "../../utilities/mapsStyling";

//import svgs
import SquareIcon from "../../assets/svg/square-filled.svg";
import CircleIcon from "../../assets/svg/circle-filled.svg";
import { useEffect, useRef, useState } from "react";

interface ICoordinates {
  latitude: number;
  longitude: number;
}
interface IMapContainerProps {
  pickup?: ICoordinates;
  dropoff?: ICoordinates;
}

const GoogleMapComponent = ({ pickup, dropoff }: IMapContainerProps) => {
  const [map, setMap] = useState();
  const mapRef = useRef(null);

  useEffect(() => {
    return () => {
      if (map && pickup && !dropoff) {
        const m: any = map;
        const latlngbounds = new google.maps.LatLngBounds();
        latlngbounds.extend(
          new google.maps.LatLng(pickup.latitude, pickup.longitude),
        );
        m.fitBounds(latlngbounds);
        m.setZoom(16);
      }
    };
  }, [pickup, dropoff, map]);

  useEffect(() => {
    return () => {
      if (map && dropoff && !pickup) {
        const m: any = map;
        const latlngbounds = new google.maps.LatLngBounds();
        latlngbounds.extend(
          new google.maps.LatLng(dropoff.latitude, dropoff.longitude),
        );
        m.fitBounds(latlngbounds);
        m.setZoom(16);
      }
    };
  }, [dropoff, pickup, map]);

  //dashed polyline
  var lineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 4,
  };

  return (
    <GoogleMap
      ref={mapRef}
      zoom={18} //default zoom
      mapContainerClassName="h-60 w-full"
      //here we adjust zoom based on pickup/dropoff points
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onLoad={async (map: any) => {
        const latlng = [];

        if (pickup) {
          latlng.push(
            new google.maps.LatLng(pickup.latitude, pickup.longitude),
          );
        }
        if (dropoff) {
          latlng.push(
            new google.maps.LatLng(dropoff.latitude, dropoff.longitude),
          );
        }

        const latlngbounds = new google.maps.LatLngBounds();
        for (var i = 0; i < latlng.length; i++) {
          latlngbounds.extend(latlng[i]);
        }
        map.fitBounds(latlngbounds);

        if (latlng.length <= 1) {
          setTimeout(() => {
            map.setZoom(16);
          }, 200);
        }
        setMap(map);
      }}
      options={{
        styles: mapsStyling,
        disableDefaultUI: true,
      }}>
      {pickup && (
        <MarkerF
          key="pickup"
          position={{ lat: pickup.latitude, lng: pickup.longitude }}
          icon={{
            anchor: new google.maps.Point(10, 10),
            url: CircleIcon,
            scaledSize: new google.maps.Size(20, 20),
          }}
        />
      )}

      {dropoff && (
        <MarkerF
          key="dropoff"
          position={{ lat: dropoff.latitude, lng: dropoff.longitude }}
          icon={{
            anchor: new google.maps.Point(10, 10),
            url: SquareIcon,
            scaledSize: new google.maps.Size(20, 20),
          }}
        />
      )}

      {dropoff && pickup && (
        <PolylineF
          path={[
            new google.maps.LatLng(pickup.latitude, pickup.longitude),
            //here we create a few points (2+) for the line to curve
            // new google.maps.LatLng(pickup.latitude-0.025, pickup.longitude-0.05),
            new google.maps.LatLng(dropoff.latitude, dropoff.longitude),
          ]}
          options={{
            geodesic: true,
            strokeColor: colors.primary,
            strokeOpacity: 0,
            icons: [
              {
                icon: lineSymbol,
                offset: "0",
                repeat: "20px",
              },
            ],
          }}
        />
      )}
    </GoogleMap>
  );
};

export default GoogleMapComponent;
