//import dependencies
import { FormControl, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import React from "react";
import moment, { Moment } from "moment";

//import components
import DateSelectorItem from "./DateSelectorItem";
import DateItem from "./DateItem";

interface IDateSelectorProps {
  onChange: (e?: any) => void;
  value: string;
  minDate: Moment; //impose minimum date
  reset?: boolean; //only for use in time
  error?: boolean;
  returnDate?: boolean;
}

const DateSelector = React.forwardRef(
  (
    { value, onChange, minDate, reset, error, returnDate }: IDateSelectorProps,
    ref, // eslint-disable-line @typescript-eslint/no-unused-vars
  ) => {
    const [inputtedValue, setInputtedValue] = useState(
      value !== "" ? value : "",
    ); //radio inputtedValue
    const [resetError, setResetError] = useState(false); //error state for resetting

    //if reset is true, reset values
    useEffect(() => {
      if (reset) {
        setInputtedValue("");
        onChange("");
        setResetError(true);
      }
    }, [reset]); //eslint-disable-line

    useEffect(() => {
      if (inputtedValue !== "" && resetError) {
        setResetError(false);
      }
    }, [inputtedValue]); //eslint-disable-line

    return (
      <FormControl>
        <RadioGroup className="pt-4" value={inputtedValue}>
          <div className="flex justify-between rounded">
            {/* map out next 3 days */}
            {!returnDate &&
              [
                minDate,
                moment(minDate).add(1, "day"),
                moment(minDate).add(2, "day"),
              ].map((date: Moment) => {
                return (
                  <div key={date.date()} className="p-1 grow">
                    <DateItem
                      selected={inputtedValue === date.format("YYYY-MM-DD")}
                      label={date}
                      onClick={() => {
                        setInputtedValue(date.format("YYYY-MM-DD"));
                        onChange(date.format("YYYY-MM-DD"));
                      }}
                      error={error || resetError}
                    />
                  </div>
                );
              })}

            <div className="p-1 grow">
              <DateSelectorItem
                selected={
                  returnDate
                    ? false
                    : inputtedValue !== minDate.format("YYYY-MM-DD") &&
                      inputtedValue !==
                        moment(minDate).add(1, "day").format("YYYY-MM-DD") &&
                      inputtedValue !==
                        moment(minDate).add(2, "day").format("YYYY-MM-DD") &&
                      inputtedValue !== ""
                }
                onChange={(e: any) => {
                  if (e) {
                    setInputtedValue(e);
                    onChange(e);
                  }
                }}
                value={inputtedValue}
                reset={
                  returnDate
                    ? reset
                    : inputtedValue === minDate.format("YYYY-MM-DD") ||
                      inputtedValue ===
                        moment(minDate).add(1, "day").format("YYYY-MM-DD") ||
                      inputtedValue ===
                        moment(minDate).add(2, "day").format("YYYY-MM-DD") ||
                      reset
                }
                minDate={returnDate ? minDate : moment(minDate).add(3, "days")}
                returnDate={returnDate}
                error={error || resetError}
              />
            </div>
          </div>
        </RadioGroup>
      </FormControl>
    );
  },
);

export default DateSelector;
